import React from 'react';
import * as R from 'ramda';

import { profileRoute, loginRoute } from 'routes';
import { useAuth } from 'hooks/auth';
import NavLink from 'components/common/NavLink';
import LogoutButton from './LogoutButton';
import { useI18n } from 'hooks/i18n';
import Translate from 'components/common/Translate';

function CurrentUser() {
  const { t } = useI18n();
  const { isAuthenticated, localAuthority } = useAuth();
  const name = R.propOr(null, 'name', localAuthority);

  if (!isAuthenticated) {
    return (
      <li className="nav-item">
        <NavLink
          to={loginRoute()}
          className="nav-link"
          data-testid="navbar-currentUser"
        >
          {t('navbar.logInLink')}
        </NavLink>
      </li>
    );
  }

  return (
    <li className="nav-item dropdown">
      <a
        href="#user-menu"
        id="currentUserDropdown"
        className="nav-link dropdown-toggle"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-testid="navbar-currentUser"
      >
        <Translate text="navbar.loggedInText" variables={{ name }} />
      </a>
      <div className="dropdown-menu" id="currentUserDropdown">
        <NavLink to={profileRoute(localAuthority.id)} className="dropdown-item">
          {t('navbar.myProfileLink')}
        </NavLink>
        <LogoutButton />
      </div>
    </li>
  );
}

export default CurrentUser;
