import React from 'react';
import * as R from 'ramda';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import * as types from 'types';
import { useModalState } from 'hooks/modal';
import Translate from 'components/common/Translate';
import { Formik, Form, Field } from 'formik';
import FormikField from 'components/common/FormikField';
import { useI18n } from 'hooks/i18n';
import { useMutation } from '@apollo/react-hooks';
import { NEW_SUBTENANT_USER_ADMIN_CREATE } from 'apollo/operations/subTenant';
import { toast } from 'react-toastify';
import { setValidationErrors } from 'utils/forms';
import ErrorBox from 'components/common/ErrorBox';

function GrantSubTenantAdminModal({
  tenantId,
  subTenantId,
  subTenantName,
  admins,
  users,
  refetchQueries,
  children,
}) {
  const { t } = useI18n();
  const { show, showModal, hideModal } = useModalState();

  const [
    grantSubTenantAdmin,
    { loading, error },
  ] = useMutation(NEW_SUBTENANT_USER_ADMIN_CREATE, { refetchQueries });

  async function handleSubmit(values, formikBag) {
    try {
      await grantSubTenantAdmin({
        variables: {
          subTenantId,
          user: {
            name: values.name,
            email: values.email,
          },
        },
      });
      toast.success(t('user.grantAreaAdminSuccessToast', { subTenantName }));
      hideModal();
    } catch (err) {
      console.log('Error granting subTenant admin', err);
      toast.error(t('user.grantAreaAdminErrorToast', { subTenantName }));
      setValidationErrors(err, formikBag);
    }
  }
  console.log(admins)
  const currentAdminIds = R.pluck('id', admins);

  // todo: get user list, filter by healthcare, filter by not in currentAdminIds, sort by name, map options
  const userOptions = R.pipe(
    R.filter(R.propEq('type', 'healthcare')),
    R.filter(
      R.pipe(
        R.prop('id'),
        R.complement(R.contains(R.__, currentAdminIds)),
        //
      ),
    ),
    R.sortBy(R.pipe(R.prop('name'), R.toLower)),
    R.map(user => ({
      value: user.id,
      label: `${user.name} (${user.email})`,
    })),
  )(users);

  return (
    <>
      {children(showModal)}

      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate text="subTenant.addAdminLabel" />
          </Modal.Title>
        </Modal.Header>

        <Formik onSubmit={handleSubmit} initialValues={{ email: '', name: '' }}>
          <Form>
            <Modal.Body>

            <Field
              name="email"
              label={t('user.properties.email')}
              component={FormikField}
              required
            />
            <Field
              name="name"
              label={t('user.properties.name')}
              component={FormikField}
              required
            />

              <ErrorBox graphQLError={error} />
            </Modal.Body>

            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                <Translate text="save" />
              </button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}

GrantSubTenantAdminModal.propTypes = {
  tenantId: types.tenantIdType.isRequired,
  subTenantId: types.subTenantIdType.isRequired,
  subTenantName: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(types.userType).isRequired,
  admins: PropTypes.arrayOf(types.userType).isRequired,
  refetchQueries: types.refetchQueriesType.isRequired,
  children: PropTypes.func.isRequired,
};

export default GrantSubTenantAdminModal;
