import * as R from 'ramda';
import { camelize, decamelize } from 'humps';

export const ucwords = string =>
  R.pipe(
    camelize,
    str => decamelize(str, { separator: ' ' }),
    str => str.replace(/\b\w/g, m => m.toUpperCase()),
  )(string);

export const stripTags = str => str.replace(/<(?:.|\n)*?>/gm, '');

export const truncate = length => text => {
  if (!text) {
    return '';
  }

  let newString = text.substring(0, length);
  if (text.length > length) {
    newString += '...';
  }

  return newString;
};
