import React from 'react';
import { Button } from 'react-bootstrap';
import { useMutation } from '@apollo/react-hooks';

import { patientIdType, refetchQueriesType } from 'types';
import Translate from 'components/common/Translate';
import { NEW_SUBTENANT_SOFT_DELETE } from 'apollo/operations/subTenant';
import { useI18n } from 'hooks/i18n';
import { toast } from 'react-toastify';

function ToggleDeleteSubTenantButton({ subTenantId, deleted, refetchQueries }) {
  const { t } = useI18n();
  const [softDeleteSubTenant, { loading }] = useMutation(NEW_SUBTENANT_SOFT_DELETE, {
    variables: { id: subTenantId },
    refetchQueries,
  });

  async function handleDelete() {
    const confirmText = deleted ? t('subTenant.confirmDelete'): t('subTenant.confirmRestore');
    if (!window.confirm(confirmText)) return;

    try {
      await softDeleteSubTenant();
      toast.success(t('subTenant.deleteSuccessToast'));
    } catch (err) {
      if (err.message == 'GraphQL error: sub_tenant_has_users') {
        toast.error(t('subTenant.deleteErrorUsersToast'));
        return
      }
      console.log('Error deleting subtenant:', err);
      toast.error(t('subTenant.deleteErrorToast'));
    }
  }

  return (
    <Button onClick={handleDelete} disabled={loading} className="float-right">
      {!deleted ? <Translate text="delete" /> : <Translate text="restore" />}
    </Button>
  );
}

ToggleDeleteSubTenantButton.propTypes = {
  subTenantId: patientIdType.isRequired,
  refetchQueries: refetchQueriesType.isRequired,
};

export default ToggleDeleteSubTenantButton;
