import gql from 'graphql-tag';

import { UserTokenParts, UserParts, TenantParts } from 'apollo/fragments';

export const AuthParts = gql`
  fragment AuthParts on UserToken {
    ...UserTokenParts
    user {
      ...UserParts
      tenant {
        ...TenantParts
      }
    }
  }

  ${UserTokenParts}
  ${UserParts}
  ${TenantParts}
`;

export const NewAuthorityMetadataParts = gql`
  fragment NewAuthorityMetadataParts on AuthorityMetadata {
      name
      email
  }
`;

// TODO: add isTenantAdmin and subTenantAdminIds
export const NewAuthParts = gql`
  fragment NewAuthParts on Authority {
    userId
    anonymous
    isAdmin
    metadata {
      ...NewAuthorityMetadataParts
    }
  }

  ${NewAuthorityMetadataParts}
`;

export const CREATE_AUTHORITY = gql`
  mutation CreateAuthority($credentials: CredentialsInput!) {
    createAuthority(credentials: $credentials) {
      ...AuthParts
    }
  }

  ${AuthParts}
`;

export const REFRESH_AUTHORITY = gql`
  mutation RefreshAuthority {
    refreshAuthority {
      ...AuthParts
    }
  }

  ${AuthParts}
`;

export const DELETE_AUTHORITY = gql`
  mutation DeleteAuthority($deviceType: String!) {
    deleteAuthority(deviceType: $deviceType) {
      success
      message
    }
  }
`;

export const NEW_CREATE_AUTHORITY = gql`
  query LoginUrl($redirectTo: String, $redirectToFull: String) {
    loginUrl(redirectTo: $redirectTo, redirectToFull: $redirectToFull) {
      value
    }
  }
`;

export const NEW_REFRESH_AUTHORITY = gql`
  query AuthorityRefresh {
    authorityRefresh {
      ...NewAuthParts
    }
  }

  ${NewAuthParts}
`

export const NEW_DELETE_AUTHORITY = gql`
  query LogoutUrl($redirectTo: String) {
    logoutUrl(redirectTo: $redirectTo) {
      value
    }
  }
`