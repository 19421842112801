import React from 'react';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { useMutation } from '@apollo/react-hooks';

import { CREATE_USER } from 'apollo/operations/user';
import { yup } from 'utils/validation';
import { loginRoute, verifyEmailRoute } from 'routes';
import { setValidationErrors } from 'utils/forms';
import FormikField from 'components/common/FormikField';
import FormikErrors from 'components/common/FormikErrors';
import Translate from 'components/common/Translate';
import { useI18n } from 'hooks/i18n';

const defaultValues = () => ({
  email: '',
  name: '',
  password: '',
  confirmPassword: '',
});

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
  name: yup.string().required(),
  password: yup
    .string()
    .min(8)
    .required(),
  confirmPassword: yup
    .string()
    .required()
    // eslint-disable-next-line no-template-curly-in-string
    .test('passwords-match', '${path} must match password', function(value) {
      return value === this.parent.password;
    }),
});

function RegistrationForm() {
  const { t } = useI18n();
  const [createUser, { loading, data }] = useMutation(CREATE_USER);

  async function handleSubmit(values, formikBag) {
    const user = R.pick(['email', 'name', 'password'], values);
    const verifyPath = verifyEmailRoute();

    try {
      await createUser({ variables: { user, verifyPath } });
    } catch (err) {
      console.log('Error caught:', JSON.parse(JSON.stringify(err)));
      setValidationErrors(err, formikBag);
    }
  }

  if (!R.isNil(data)) {
    const email = R.path(['createUser', 'email'], data);

    return (
      <>
        <h1>
          <Translate text="register.registerSuccessHeading" />
        </h1>
        <p>
          <Translate
            text="register.registerSuccessText"
            variables={{ email }}
          />
        </p>
      </>
    );
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={defaultValues()}
      validationSchema={validationSchema}
    >
      <Form>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">
              <Translate text="register.label" />
            </h5>

            <Field
              name="email"
              label={t('user.properties.email')}
              component={FormikField}
            />
            <Field
              name="name"
              label={t('user.properties.name')}
              component={FormikField}
            />

            <div className="row">
              <div className="col">
                <Field
                  name="password"
                  label={t('user.properties.password')}
                  component={FormikField}
                  type="password"
                />
              </div>

              <div className="col">
                <Field
                  name="confirmPassword"
                  label={t('user.properties.passwordConfirm')}
                  component={FormikField}
                  type="password"
                />
              </div>
            </div>

            <FormikErrors />
          </div>

          <div className="card-footer text-right">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              <Translate text="register.label" />
            </button>
          </div>
        </div>

        <div className="text-center mt-2">
          <Translate text="register.alreadyRegistered" />{' '}
          <Link to={loginRoute()}>
            <Translate text="auth.loginButton" />
          </Link>
        </div>
      </Form>
    </Formik>
  );
}

export default RegistrationForm;
