import React from 'react';

import { nextOfKinPatientsListRoute } from 'routes';
import StandardLayout from 'components/layouts/StandardLayout';
import TenantNextOfKinPatientList from 'components/modules/user/TenantNextOfKinPatientList';

function NextOfKinPatientsListPage() {
  return (
    <StandardLayout
      breadcrumb={[[nextOfKinPatientsListRoute(), 'next-of-kin']]}
    >
      <TenantNextOfKinPatientList />
    </StandardLayout>
  );
}

export default NextOfKinPatientsListPage;
