export const lang = {
  //
  /////////// English

  EN: {
    id: 'ID',
    name: 'name',
    email: 'email',
    save: 'Save',
    delete: 'Delete',
    restore: 'Restore',
    edit: 'Edit',
    filter: 'Filter',
    error: 'Error',
    submit: 'Submit',
    close: 'Close',
    cancel: 'Cancel',
    grant: 'Grant',
    revoke: 'Revoke',
    remove: 'Remove',
    lock: 'Lock',
    unlock: 'Unlock',
    footerText: 'All rights reserved.',
    blankOptionLabel: '- Select -',
    selectMultipleInstructions: 'Hold [ctrl] or ⌘ to select multiple items.',
    requiredFieldTooltip: 'This field is required',
    noItemsToDisplay: 'No items to display.',
    users: 'users',
    active: 'Active',
    unauthorized: 'Unauthorized',
    unauthorizedMessage: 'You do not have access to this resource',

    notFoundHeading: 'Not Found',
    notFoundMessage: 'The resource you are looking for could not be found.',

    graphQLBadRequest:
      'One or more fields is missing data or is incomplete. Please check the form and try again.',
    graphQLGenericError:
      'There was a problem processing your request, please try again.',

    logoutConfirm: 'Are you sure you want to log out?',
    logoutSuccessToast: 'You are now logged out.',
    logoutFromIdleWarning:
      'Your session will expire in {{time}} seconds due to inactivity.',

    contactsLabel: 'Contacts',
    callHistoryLabel: 'Call History',

    'language.label': 'Language',
    'language.english': 'English',
    'language.norwegian': 'Norsk',

    'navbar.tenantListLink': 'Tenants',
    'navbar.partnerListLink': 'Partners',
    'navbar.adminDevicesLink': 'Devices',
    'navbar.loggedInText': 'Logged in as: {{name}}',
    'navbar.myProfileLink': 'My profile',
    'navbar.logInLink': 'Log in',
    'navbar.logOutLink': 'Log out',

    'confirmPasswordReset.successToast':
      'Your password was successfully updated. You may now log in.',
    'confirmPasswordReset.tokenInvalidMessage':
      'This URL has already been used or is invalid.',
    'confirmPasswordReset.instructionsTitle': 'Set a new password',
    'confirmPasswordReset.instructionsText':
      'Enter a new password for your account.',

    'route.home': 'Home',
    'route.tenantList': 'Tenant List',
    'route.createTenant': 'Create Tenant',
    'route.createPatient': 'Create Patient',
    'route.tenantUsers': 'Tenant Users',
    'route.deviceList': 'Device List',
    'route.updateDevice': 'Update Device',
    'route.tenantPage': 'Tenant',
    'route.tenantUpdate': 'Update Tenant',
    'route.partnerList': 'Partner List',
    'route.createPartner': 'Create Partner',
    'route.partnerDetail': 'Partner Detail',
    'route.patientList': 'Patient List',
    'route.patientDetail': 'Patient Detail',
    'route.verifyTenantUserEmail': 'Account Setup',
    'route.profile': 'My Profile',
    'route.forgotPassword': 'Forgot Password',
    'route.verifyEmail': 'Email Verified',
    'route.updateUser': 'Update User',

    'auth.rememberMe': 'Remember me',
    'auth.usernamePasswordMismatchError':
      'A user with that email/password combination could not found.',
    'auth.emailNotVerifiedError':
      'Your email address has not yet been verified. Please check your email and click on the link to verify.',
    'auth.genericLoginError':
      'Sorry, there was a problem logging you in. Please try again.',
    'auth.loginHeading': 'Log In',
    'auth.loginInstructions': 'Please enter your credentials to log in.',
    'auth.loginButton': 'Log In',
    'auth.authenticating': 'Authenticating...',
    'auth.forgotPasswordLink': 'Forgot Password',
    'auth.forgotPasswordInstructions': `If you've forgotten your password, enter your username below. You will be sent an email with instructions to set a new password.`,
    'auth.requestPasswordResetErrorToast':
      'There was a problem submitting your request, please try again.',
    'auth.requestPasswordResetSuccessHeading': 'Request Sent',
    'auth.requestPasswordResetSuccessInstructions':
      'Your password reset request has been submitted. If the email address entered matches our records, you will receive an email with instructions to set a new password.',
    'auth.verifyEmailSuccessText':
      'Thank you for verifying your email address. You may now log in and use the application.',

    'register.label': 'Register',
    'register.registerSuccessHeading': 'Registration Successful',
    'register.registerSuccessText':
      'Your registration is being processed. Please check your email ({{email}}) and click the verification link to complete your account setup.',
    'register.alreadyRegistered': 'Already registered?',

    'user.label': 'User',
    'user.properties.id': 'ID',
    'user.properties.name': 'Name',
    'user.properties.displayName': 'Display Name',
    'user.properties.email': 'Email',
    'user.properties.type': 'Type',
    'user.properties.defaultLocale': 'Default Locale',
    'user.properties.isEmailVerified': 'Email Verified',
    'user.properties.isEnabled': 'Enabled',
    'user.properties.isAdmin': 'Admin',
    'user.properties.isLocked': 'Locked',
    'user.properties.delete': 'Delete',
    'user.properties.password': 'Password',
    'user.properties.newPassword': 'New Password',
    'user.properties.passwordConfirm': 'Confirm Password',
    'user.updateProfileLabel': 'Update Profile',
    'user.properties.nextOfKinAdmin': 'Next of kin admin',
    'user.properties.nextOfKin': 'Next of kin',
    'user.updateProfileSuccessToast':
      'Your profile has been updated successfully. If your email address was updated, you will need to confirm it before the new address may be used to log in.',
    'user.updateProfileErrorToast':
      'There was a problem updating your profile. Please try again.',
    'user.updateUserLockedToast':
      'There was a problem locking this user. Please try again.',
    'user.showLockedButton': 'Show Locked',
    'user.sendEmailErrorToast': 'There was a problem sending the email to the user',
    'user.confirmSendEmail': 'Do you want to resend an invitation to email to {{email}}?',
    'user.sendEmailSuccessToast': 'Email sent to the user',
    'user.resendEmail': 'Resend invitation',
    'user.alertDeleted': 'User has been deleted',
    'user.createButtonText': 'Send invitation',
    'user.createUserButtonText': 'Create User',
    'user.createAdminButtonText': 'Create Admin',
    'user.createTenantUserSuccessToast': 'User created successfully.',
    'user.createTenantUserErrorToast': 'There was a problem creating the user.',
    'user.createTenantUserSuccessHeading': 'User Created',
    'user.createTenantUserSuccessText':
      'The user has been created successfully. They will receive an email at the following address with instructions for activating their account and setting a password:',
    'user.updateTenantUserErrorToast': 'There was a problem updating the user.',
    'user.invalidTokenError': 'This URL has already been used or is invalid.',
    'user.toggleEnabledTooltip': 'Click to enable user',
    'user.toggleDisabledTooltip': 'Click to disable user',
    'user.confirmAdmin':
      'Are you sure you would like to make this user into an admin?',
    'user.confirmRemoveAdmin':
      'Are you sure you would like to remove admin access from this user?',
    'user.toggleAdminEnabledTooltip': 'Click to make user into an admin',
    'user.toggleAdminDisabledTooltip': 'Click to remove admin access from user',
    'user.confirmEnable': 'Are you sure you would like to enable this user?',
    'user.confirmDisable': 'Are you sure you would like to disable this user?',
    'user.confirmLock': 'Are you sure you would like to lock this user?',
    'user.confirmUnlock': 'Are you sure you would like to unlock this user?',
    'user.manageGroupsLabel': 'Manage Groups',
    'user.availableGroupsLabel': 'Available Groups',
    'user.userGroupsLabel': "User's Groups",
    'user.minGroupsError': 'A user must be a member of at least one group.',
    'user.addToGroupTooltip': 'Add user to group',
    'user.removeFromGroupTooltip': 'Remove user from group',
    'user.assignedToGroupSuccessToast': 'User assigned to group {{groupName}}.',
    'user.removedFromGroupSuccessToast':
      'User removed from group {{groupName}}.',
    'user.areaAdminLabel': 'Area Admin',
    'user.createAreaAdminLabel': 'Create Area Admin',
    'user.grantAreaAdminTooltip': 'Grant area admin',
    'user.revokeAreaAdminTooltip': 'Revoke area admin',
    'user.confirmRemoveSubTenantAdmin': 'Are you sure you would like to remove sub-tenant admin access from this user?',
    'user.grantAreaAdminSuccessToast':
      'User is now an admin of {{subTenantName}}.',
    'user.grantAreaAdminErrorToast':
      'There was a problem granting the user admin.',
    'user.revokeAreaAdminSuccessToast':
      'User is no longer an admin of {{subTenantName}}',
    'user.revokeAreaAdminErrorToast':
      'There was a problem revoking the user admin.',
    'user.createPartnerUserSuccessToast': 'User created successfully.',
    'user.createPartnerUserErrorToast': 'There was a problem creating the user.',
    'user.createPartnerUserSuccessHeading': 'User Created',
    'user.createPartnerUserSuccessText':
      'The user has been created successfully. They will receive an email at the following address with instructions for activating their account and setting a password:',
    'user.confirmPartnerDelete': 'Are you sure you would like to remove this user?',
    'user.removedUserFailedToast':
      'There was a problem removing this user. Please try again.',
    'user.removedUserSuccessToast':
      'User removed from subtenant',
    'user.removedUserFromSubTenantFailedToast':
      'There was a problem removing this user. Please try again.',
    'user.confirmRemoveUserFromSubTenant':
      'Do you want to remove this user from this area?',
    'user.alertInvalidPermissions': "You do not have the permissions for this user",

    'tenant.label': 'Tenant',
    'tenant.updateLocaleLabel': 'Update Tenant Locale',
    'tenant.properties.id': 'ID',
    'tenant.properties.name': 'Name',
    'tenant.properties.locale': 'Locale',
    'tenant.properties.enforceApplicationSecurity':
      'Enforce Application Security',
    'tenant.admins.label': 'Admins',
    'tenant.createHeadingText': 'Create Tenant',
    'tenant.createButtonText': 'Create Tenant',
    'tenant.updateButtonText': 'Update Tenant',
    'tenant.createSuccessToast': 'Tenant created successfully.',
    'tenant.createErrorToast': 'There was a problem creating the tenant.',
    'tenant.updateSuccessToast': 'Tenant Updated Successfully.',
    'tenant.updateErrorToast': 'There was a problem updating the tenant.',
    'tenant.removeSuccessToast': 'Tenant Removed Successfully.',
    'tenant.removeErrorToast': 'There was a problem removing the tenant.',
    'tenant.confirmPartnerRemove': 'Are you sure you would like to remove this tenant from this partner?',
    'tenant.noSubTenantWarning': 'Please add an Area before trying to add a user',

    'subTenant.label': 'Area',
    'subTenant.properties.id': 'ID',
    'subTenant.properties.name': 'Name',
    'subTenant.createHeadingText': 'Create Area',
    'subTenant.createLabel': 'Create Area',
    'subTenant.createButtonText': 'Create Area',
    'subTenant.updateButtonText': 'Update Area',
    'subTenant.updateSubTenantLabel': 'Update Area',
    'subTenant.createSuccessToast': 'Area created successfully.',
    'subTenant.createErrorToast': 'There was a problem creating the area.',
    'subTenant.updateSuccessToast': 'Area updated successfully.',
    'subTenant.updateErrorToast': 'There was a problem updating the area.',
    'subTenant.addAdminLabel': 'Add Area Administrator',
    'subTenant.removeContactSubtenant': 'Remove Area Contact',
    'subTenant.confirmDelete': 'Are you sure you would like to remove this area?',
    'subTenant.confirmRestore': 'Are you sure you would like to restore this area?',
    'subTenant.deleteSuccessToast': 'Sucessfully deleted',
    'subTenant.deleteErrorToast': 'There was a problem deleting this area.',
    'subTenant.deleteErrorUsersToast': 'Please remove users before deleting this area.',
    'subTenant.showDeleted': 'Show Deleted Areas',

    'partner.label': 'Partner',
    'partner.updateLocaleLabel': 'Update Partner Locale',
    'partner.properties.id': 'ID',
    'partner.properties.name': 'Name',
    'partner.properties.locale': 'Locale',
    'partner.properties.enforceApplicationSecurity':
      'Enforce Application Security',
    'partner.admins.label': 'Admins',
    'partner.createHeadingText': 'Create Partner',
    'partner.createButtonText': 'Create Partner',
    'partner.updateButtonText': 'Update Partner',
    'partner.createSuccessToast': 'Partner created successfully.',
    'partner.createErrorToast': 'There was a problem creating the partner.',
    'partner.updateSuccessToast': 'Partner Updated Successfully.',
    'partner.updateErrorToast': 'There was a problem updating the tenant.',
    'partner.users': 'Users',
    'partner.tenants': 'Tenants',
    'partner.devices': 'Devices',

    'call.callStart': 'Call Start',
    'call.callEnd': 'Call End',
    'call.callDuration': 'Call Duration',
    'call.minutes': 'Minutes',
    'call.sessionId': 'Call Session ID',
    'call.alertReceivedAt': 'Call Alert Received At',
    'call.missedCall': 'Missed call',

    'group.label': 'Group',
    'group.properties.id': 'ID',
    'group.properties.name': 'Name',
    'group.createHeadingText': 'Create Group',
    'group.createLabel': 'Create Group',
    'group.deleteLabel': 'Delete Group',
    'group.createButtonText': 'Create Group',
    'group.updateButtonText': 'Update Group',
    'group.updateGroupLabel': 'Update Group',
    'group.createSuccessToast': 'Group created successfully.',
    'group.createErrorToast': 'There was a problem creating the Group.',
    'group.updateSuccessToast': 'Group created successfully.',
    'group.updateErrorToast': 'There was a problem creating the Group.',
    'group.missingWarning': 'No groups, patients cannot be added.',
    'group.confirmDeleteGroup': 'Are you sure you would like to delete this group?',
    'group.deleteGroupErrorToast': 'Cannot delete group with patients',
    'group.deleteGroupSuccessToast': 'Group deleted sucessfully',

    'device.label': 'Device',
    'device.properties.deviceId': 'Device ID',
    'device.properties.newDeviceId': 'New Device ID',
    'device.properties.tenantId': 'Tenant ID',
    'device.properties.subTenantId': 'Sub Tenant ID',
    'device.properties.secretKey': 'Secret Key',
    'device.properties.tokenLastUpdatedAt': 'Token Last Updated',
    'device.properties.hasPatient': 'Has Patient',
    'device.properties.patient': 'Patient',
    'device.properties.lastSeen': 'Last Seen',
    'device.properties.locale': 'Locale',
    'device.properties.currentVersion': 'Current Version',
    'device.properties.provisioningStatus': 'Tiny MDM Status',
    'device.properties.deviceEasyId': 'Easy ID',
    'device.properties.mdmProvisioningStatus': 'Tiny MDM Status',
    'device.properties.kioskMode': 'Kiosk Mode',
    'device.properties.mdmQrCode': 'QR Code',
    'device.properties.mdmProvisoningStatus': 'Tiny MDM Status',
    'device.mdmQrCode.empty': 'Device has not gone through provisioning. Please notify the device owner.',
    'device.createButtonText': 'Create Device',
    'device.createSuccessToast': 'Device created successfully.',
    'device.createErrorToast': 'There was a problem creating the device.',
    'device.updateHeadingText': 'Update Device',
    'device.updateButtonText': 'Save',
    'device.updateSuccessToast': 'Device updated successfully.',
    'device.updateErrorToast': 'There was a problem updating the device.',
    'device.unassigned': 'unassigned',
    'device.deviceBlankOptionLabel': 'No device',
    'device.deviceBlankOptionLabelUnassign': 'Unassign device',
    'device.noTokenWarning':
      'This device is not fully registered. It may not work correctly if it is assigned to a patient.',
    'device.assignedWarning': 'Device is already assigned. Please unassign first before editing',
    'device.restartAppPushSuccessful': 'A restart notification was sent to the device',
    'device.restartAppPushError': 'Something went wrong in sending the restart notification',
    'device.restartApp': 'Restart App',
    'device.battery': 'Battery',
    'device.noArea': 'No Area',

    'patient.label': 'Patient',
    'patient.properties.name': 'Name',
    'patient.properties.deviceId': 'Device ID',
    'patient.properties.deleted': 'Deleted',
    'patient.properties.locale': 'Locale',
    'patient.createLabel': 'Create Patient',
    'patient.createSuccessToast': 'Patient created successfully',
    'patient.createErrorToast': 'There was a problem creating the patient.',
    'patient.updateHeadingText': 'Update Patient',
    'patient.updateSuccessToast': 'Patient updated successfully.',
    'patient.updateErrorToast': 'There was a problem updating the patient.',
    'patient.updateDeviceConfirmText':
      "Warning: You are changing a patient's device. Are you sure you would like to proceed?",
    'patient.showDeletedButton': 'Show Deleted',
    'patient.deleteConfirmText':
      'Warning: The patient will be deleted. If a device was assigned to the patient, it will be unassigned.',
    'patient.deleteSuccessToast': 'The patient was successfully deleted.',
    'patient.deleteErrorToast': 'There was a problem deleting the patient.',
    'patient.contactSource': 'Source',
    'patient.addContact': 'Add Contact',
    'patient.removeContact': 'Remove Contact',
    'patient.addContactSuccessToast': 'Contact added successfully.',
    'patient.addContactErrorToast': 'There was a problem adding the contact.',
    'patient.removeContactConfirm':
      "Contact '{{userName}}' will be removed from the patient.",
    'patient.removeContactSuccessToast': 'Contact removed successfully.',
    'patient.removeContactErrorToast':
      'There was a problem removing the contact.',
    'patientContact.directLabel': 'Direct Contact',
    'patientContact.nextOfKinAdmin': 'Next of Kin Admin',

    'patientSubTenantLink.label': 'Area Links',
    'patientSubTenantLink.properties.createdBy': 'Created By',
    'patientSubTenantLink.createLabel': 'Create Link',
    'patientSubTenantLink.createSuccessToast': 'Link created successfully.',
    'patientSubTenantLink.createErrorToast':
      'There was a problem creating the link.',
    'patientSubTenantLink.deleteConfirmText':
      'The link will be removed. The patient will no longer be able to make and receive calls from this area.',
    'patientSubTenantLink.deleteSuccessToast': 'Link removed successfully.',
    'patientSubTenantLink.deleteErrorToast':
      'There was a problem removing the link.',

    'tenantNavTabs.setupLink': 'Admin',
    'tenantNavTabs.patientsLink': 'Patients',
    'tenantNavTabs.usersLink': 'Users',
    'tenantNavTabs.nextOfKinLink': 'Next of Kin',
    'tenantNavTabs.devicesLink': 'Devices',

    'tenantDeviceList.noPatientText': 'No Patient',
  },

  //
  /////////// Norwegian

  NB: {
    id: 'ID',
    name: 'navn',
    email: 'epost',
    save: 'Lagre',
    delete: 'Slett',
    restore: 'Gjenopprett',
    edit: 'Endre',
    submit: 'Submit',
    close: 'Lukk',
    cancel: 'Avbryt',
    grant: 'Utdel',
    revoke: 'Trekk tilbake',
    remove: 'Slett',
    footerText: 'Alle rettigheter beskyttet.',
    error: 'Feil',
    filter: 'Filter',
    lock: 'Lock',
    unlock: 'Unlock',
    blankOptionLabel: '- Velg -',
    selectMultipleInstructions: 'Hold [ctrl] eller ⌘ for å velge flere.',
    requiredFieldTooltip: 'Påkrevd felt',
    notFoundHeading: 'Ikke funnet',
    notFoundMessage: 'Adressen du prøver å besøke finnes ikke.',
    noItemsToDisplay: 'Ikke noe innhold å vise.',
    graphQLBadRequest:
      'Ett eller flere felt mangler data eller er feil fyllt ut. Vennligst se over skjemaet og prøv igjen.',
    graphQLGenericError: 'Et problem oppstod, vennligst prøv igjen.',
    logoutConfirm: 'Er du sikker på du vil logge ut?',
    logoutSuccessToast: 'Du er nå logget ut.',
    logoutFromIdleWarning:
      'Du vil bli logget ut om {{time}} sekunder på grunn av inaktivitet.',
    users: 'users',
    active: 'Aktiv',
    unauthorized: 'unauthorized',
    unauthorizedMessage: 'Du har ikke tilgang til denne siden.',


    contactsLabel: 'Kontakter',
    callHistoryLabel: 'Ringehistorikk',

    'language.label': 'Språk',
    'language.english': 'English',
    'language.norwegian': 'Norsk',

    'navbar.tenantListLink': 'Kunder',
    'navbar.partnerListLink': 'Partners',
    'navbar.adminDevicesLink': 'Enheter',
    'navbar.loggedInText': 'Logget inn som: {{name}}',
    'navbar.myProfileLink': 'Min profil',
    'navbar.logInLink': 'Logg inn',
    'navbar.logOutLink': 'Logg ut',

    'confirmPasswordReset.successToast':
      'Passordet ditt ble oppdatert, du kan nå logge inn.',
    'confirmPasswordReset.tokenInvalidMessage':
      'Denne koden har allerede blitt brukt, eller er ugyldig.',
    'confirmPasswordReset.instructionsTitle': 'Nytt passord',
    'confirmPasswordReset.instructionsText':
      'Skriv inn et nytt passord for din konto.',

    'route.home': 'Hjem',
    'route.profile': 'Profile',
    'route.tenantList': 'Kundeliste',
    'route.createTenant': 'Ny kunde',
    'route.createPatient': 'Ny pasient',
    'route.tenantUsers': 'Ansattbrukere',
    'route.deviceList': 'Enheter',
    'route.updateDevice': 'Oppdater enhet',
    'route.tenantPage': 'Kunde',
    'route.tenantUpdate': 'Oppdater kunde',
    'route.partnerList': 'Partners',
    'route.createPartner': 'Opprett Partner',
    'route.partnerDetail': 'Partner',
    'route.patientList': 'Pasientliste',
    'route.patientDetail': 'Pasient',
    'route.verifyTenantUserEmail': 'Oppsett av konto',
    'route.updateUser': 'Oppdater ansatt',

    'auth.rememberMe': 'Husk meg',
    'auth.usernamePasswordMismatchError':
      'A user with that email/password combination could not found.',
    'auth.emailNotVerifiedError':
      'Your email address has not yet been verified. Please check your email and click on the link to verify.',
    'auth.genericLoginError':
      'Sorry, there was a problem logging you in. Please try again.',
    'auth.loginHeading': 'Log In',
    'auth.loginInstructions': 'Please enter your credentials to log in.',
    'auth.loginButton': 'Log In',
    'auth.authenticating': 'Authenticating...',
    'auth.forgotPasswordLink': 'Forgot Password',
    'auth.forgotPasswordInstructions': `If you've forgotten your password, enter your username below. You will be sent an email with instructions to set a new password.`,
    'auth.requestPasswordResetErrorToast':
      'There was a problem submitting your request, please try again.',
    'auth.requestPasswordResetSuccessHeading': 'Request Sent',
    'auth.requestPasswordResetSuccessInstructions':
      'Your password reset request has been submitted. If the email address entered matches our records, you will receive an email with instructions to set a new password.',
    'auth.verifyEmailSuccessText':
      'Thank you for verifying your email address. You may now log in and use the application.',

    'user.label': 'Ansatt',
    'user.properties.id': 'ID',
    'user.properties.name': 'Navn',
    'user.properties.displayName': 'Visningsnavn',
    'user.properties.email': 'Epost',
    'user.properties.type': 'Type',
    'user.properties.defaultLocale': 'Språk',
    'user.properties.isEmailVerified': 'Epost bekreftet',
    'user.properties.isEnabled': 'Aktivert',
    'user.properties.isAdmin': 'Admin',
    'user.properties.isLocked': 'Locked',
    'user.properties.delete': 'Delete',
    'user.properties.password': 'Passord',
    'user.properties.passwordConfirm': 'Bekreft Passord',
    'user.properties.nextOfKinAdmin': 'Pårørende administrator',
    'user.showLockedButton': 'Vis låste',
    'user.showLockedButton': 'Lås bruker',
    'user.alertDeleted': 'User has been deleted',
    'user.sendEmailErrorToast': 'Et problem oppstod ved utsending av epost til brukeren.',
    'user.resendEmail': 'Send ny invitasjon',
    'user.confirmSendEmail': 'Vil du sende en ny invitasjon til epost {{email}}?',
    'user.sendEmailSuccessToast': 'Eposten ble sendt til brukeren.',
    'user.createButtonText': 'Send invitasjon',
    'user.createUserButtonText': 'Ny ansatt',
    'user.createAdminButtonText': 'Ny administrator',
    'user.createTenantUserSuccessToast': 'Ansattbruker ble opprettet.',
    'user.createTenantUserErrorToast':
      'Det oppstod et problem ved å opprette ansattbrukeren.',
    'user.createTenantUserSuccessHeading': 'Ansatt opprettet',
    'user.createTenantUserSuccessText':
      'Ansattbrukeren ble opprettet. De vil motta en epost på denne adressen med instrukser for å aktivere konto og stille inn passord.',
    'user.updateTenantUserErrorToast':
      'Det oppstod et problem ved oppdatering av ansattbrukeren.',
    'user.invalidTokenError': 'Denne koden er allerede bruk eller er ugyldig.',
    'user.toggleEnabledTooltip': 'Trykk for å aktivere ansattbruker',
    'user.toggleDisabledTooltip': 'Trykk for å deaktivere ansattbruker',
    'user.confirmEnable':
      'Er du sikker på at du vil aktivere denne ansattbrukeren?',
    'user.confirmDisable':
      'Er du sikker på at du vil deaktivere denne ansattbrukeren?',
    'user.confirmAdmin':
      'Er du sikker på at du vil gjøre denne brukeren til administrator?',
    'user.confirmRemoveAdmin':
      'Er du sikker på at du vil fjerne administratorrettighetene til denne brukeren?',
    'user.confirmLock': 'Er du sikker på at du vil låse denne brukeren?',
    'user.confirmUnlock': 'Er du sikker på at du vil låse opp denne brukeren?',
    'user.updateUserLockedToast':
      'Et problem oppstod ved låsing av brukeren.',
    'user.toggleAdminEnabledTooltip':
      'Trykk for å gjøre brukeren til administrator',
    'user.toggleAdminDisabledTooltip':
      'Trykk for å fjerne administratorrettighetene',
    'user.manageGroupsLabel': 'Administrer grupper',
    'user.availableGroupsLabel': 'Tilgjengelige grupper',
    'user.userGroupsLabel': 'Ansattes grupper',
    'user.minGroupsError': 'En ansatt må være medlem av minst en gruppe.',
    'user.addToGroupTooltip': 'Legg til ansatt i gruppen',
    'user.removeFromGroupTooltip': 'Fjern ansatt fra gruppen',
    'user.assignedToGroupSuccessToast':
      'Ansatt lagt til i gruppen {{groupName}}.',
    'user.removedFromGroupSuccessToast':
      'Ansatt fjernet fra gruppen {{groupName}}.',
    'user.areaAdminLabel': 'Virksomhetsadministrator',
    'user.createAreaAdminLabel': 'Ny virksomhetsadministrator',
    'user.grantAreaAdminTooltip':
      'Gi rettigheter til å administrere virksomhet',
    'user.revokeAreaAdminTooltip':
      'Fjern rettigheter til å administrere virksomhet',
    'user.confirmRemoveSubTenantAdmin': 'Er du sikker på at du vil fjerne rettigheter til å administrere denne virksomheten?',
    'user.grantAreaAdminSuccessToast':
      'Ansatt er nå administrator i {{subTenantName}}.',
    'user.grantAreaAdminErrorToast':
      'Det oppstod et problem ved opprettelse av administrasjonsrettigheter.',
    'user.revokeAreaAdminSuccessToast':
      'Ansatt er ikke lengre administrator i {{subTenantName}}',
    'user.revokeAreaAdminErrorToast':
      'Det oppstod et problem ved fjerning av administrasjonsrettigheter.',
    'user.createPartnerUserSuccessToast': 'Ansattbruker ble opprettet.',
    'user.createPartnerUserErrorToast':
      'Det oppstod et problem ved å opprette ansattbrukeren.',
    'user.createPartnerUserSuccessHeading': 'Ansatt opprettet',
    'user.createPartnerUserSuccessText':
      'Ansattbrukeren ble opprettet. De vil motta en epost på denne adressen med instrukser for å aktivere konto og stille inn passord.',
    'user.updatePartnerUserErrorToast':
      'Det oppstod et problem ved oppdatering av ansattbrukeren.',
    'user.confirmPartnerDelete': 'Er du sikker på du vil fjerne denne brukeren?',
    'user.removedUserFailedToast':
      'Det oppstod et problem ved fjerning av brukeren.',
    'user.removedUserSuccessToast':
      'Bruker fjernet fra område',
    'user.removedUserFromSubTenantFailedToast':
      'Det oppstod et problem med å fjerne denne brukeren.',
    'user.confirmRemoveUserFromSubTenant':
      'Vil du fjerne denne brukeren fra området?',
    'user.alertInvalidPermissions': "Du har ikke tilgang til denne brukeren",
    'user.updateProfileLabel': 'Oppdater profil',



    'tenant.label': 'Kunde',
    'tenant.properties.id': 'ID',
    'tenant.properties.name': 'Navn',
    'tenant.admins.label': 'Administratorer',
    'tenant.updateLocaleLabel': 'Oppdater kundespråk',
    'tenant.properties.locale': 'Språk',
    'tenant.properties.enforceApplicationSecurity': 'Skru på tvungen utlogging',
    'tenant.createHeadingText': 'Ny kunde',
    'tenant.createButtonText': 'Ny kunde',
    'tenant.updateButtonText': 'Oppdater kunde',
    'tenant.createSuccessToast': 'Ny kunde opprettet.',
    'tenant.createErrorToast': 'En feil oppstod ved opprettelse av kunde.',
    'tenant.updateSuccessToast': 'Kunden ble oppdatert.',
    'tenant.updateErrorToast':
      'Det oppstod et problem ved oppdatering av kunden.',
    'tenant.removeSuccessToast': 'Kunden ble fjernet.',
    'tenant.removeErrorToast': 'Det oppstod et problem ved fjerning av kunden.',
    'tenant.confirmPartnerRemove': 'Er du sikker på at du vil fjerne denne kunden fra denne partneren?',

    'partner.label': 'Partner',
    'partner.updateLocaleLabel': 'Oppdater partnerspråk',
    'partner.properties.id': 'ID',
    'partner.properties.name': 'Navn',
    'partner.properties.locale': 'Språk',
    'partner.properties.enforceApplicationSecurity':
      'Påtving automatisk utlogging',
    'partner.admins.label': 'Administratorer',
    'partner.createHeadingText': 'Opprett Partner',
    'partner.createButtonText': 'Opprett Partner',
    'partner.updateButtonText': 'Oppdater Partner',
    'partner.createSuccessToast': 'Partner ble opprettet.',
    'partner.createErrorToast': 'Det oppstod et problem under oppretting av partneren.',
    'partner.updateSuccessToast': 'Partneren ble oppdatert.',
    'partner.updateErrorToast': 'Det oppstod et problem ved oppdatering av parteren.',
    'partner.users': 'Brukere',
    'partner.tenants': 'Kunder',
    'partner.devices': 'Enheter',

    'subTenant.label': 'Virksomhet',
    'subTenant.properties.id': 'ID',
    'subTenant.properties.name': 'Navn',
    'subTenant.createHeadingText': 'Opprett virksomhet',
    'subTenant.createLabel': 'Opprett virksomhet',
    'subTenant.createButtonText': 'Opprett virksomhet',
    'subTenant.updateSubTenantLabel': 'Oppdater virksomhet',
    'subTenant.updateButtonText': 'Oppdater virksomhet',
    'subTenant.createSuccessToast': 'Virksomhet ble opprettet.',
    'subTenant.createErrorToast':
      'Det oppstod et problem ved opprettelsen av virksomhet.',
    'subTenant.updateSuccessToast': 'Virksomheten ble oppdatert.',
    'subTenant.updateErrorToast':
      'Det skjedde en feil ved oppdatering av virksomheten.',
    'subTenant.addAdminLabel': 'Legg til virksomhetsadministrator',
    'subTenant.removeContactSubtenant': 'Remove contact area',
    'subTenant.confirmDelete': 'Er du sikker på at du vil fjerne denne virksomheten?',
    'subTenant.confirmRestore': 'Er du sikker på at du vil gjenopprette denne virksomheten?',
    'subTenant.deleteSuccessToast': 'Sucessfully deleted',
    'subTenant.deleteErrorToast': 'There was a problem deleting this area.',
    'subTenant.deleteErrorUsersToast': 'Please remove users before deleting this area.',
    'subTenant.showDeleted': 'Show deleted areas',
    
    'call.callStart': 'Samtalestart',
    'call.callEnd': 'Samtaleslutt',
    'call.callDuration': 'Lengde',
    'call.minutes': 'Minutter',
    'call.sessionId': 'Call Session ID',
    'call.alertReceivedAt': 'Samtaletidspunkt',
    'call.missedCall': 'Ubesvart anrop',

    'group.label': 'Gruppe',
    'group.properties.id': 'ID',
    'group.properties.name': 'Navn',
    'group.createHeadingText': 'Opprett gruppe',
    'group.createLabel': 'Opprett gruppe',
    'group.deleteLabel': 'Delete Group',
    'group.createButtonText': 'Opprett gruppe',
    'group.updateButtonText': 'Oppdater gruppe',
    'group.updateGroupLabel': 'Oppdater gruppe',
    'group.createSuccessToast': 'Gruppen ble opprettet.',
    'group.createErrorToast':
      'Det oppstod et problem ved opprettelse av gruppen.',
    'group.updateSuccessToast': 'Gruppen ble oppdatert.',
    'group.updateErrorToast':
      'Det oppstod et problem ved oppdateringen av gruppen.',
    'group.missingWarning': 'No groups, patients cannot be added.',
    'group.confirmDeleteGroup': 'Are you sure you would like to delete this group?',
    'group.deleteGroupErrorToast': 'Cannot delete group with patients',
    'group.deleteGroupSuccessToast': 'Group deleted sucessfully',

    'device.label': 'Enhet',
    'device.properties.deviceId': 'Enhets-ID',
    'device.properties.newDeviceId': 'Ny enhets-ID',
    'device.properties.tenantId': 'Kunde-ID',
    'device.properties.subTenantId': 'Virksomhets-ID',
    'device.properties.secretKey': 'Hemmelig nøkkel',
    'device.properties.tokenLastUpdatedAt': 'Sist pålogget',
    'device.properties.hasPatient': 'Har pasient',
    'device.properties.patient': 'Pasient',
    'device.properties.locale': 'Språk',
    'device.properties.lastSeen': 'Sist sett',
    'device.properties.currentVersion': 'Versjon',
    'device.properties.provisioningStatus': 'Tiny MDM Status',
    'device.properties.deviceEasyId': 'Easy ID',
    'device.properties.mdmProvisioningStatus': 'Tiny MDM Status',
    'device.properties.kioskMode': 'Kioskmodus',
    'device.properties.mdmQrCode': 'QR kode',
    'device.properties.mdmProvisoningStatus': 'Tiny MDM Status',
    'device.mdmQrCode.empty': 'Enheten er ikke ferdig provisjonert, gi beskjed til systemadministrator.',
    'device.updateProfileLabel': 'Oppdater profil',
    'device.updateProfileSuccessToast':
      'Profilen din er oppdatert. Om du har skiftet epost så må du bruke link tilsendt på ny epost for å bekrefte adressen før du kan logge inn med ny epost-adresse.',
    'device.updateProfileErrorToast':
      'Det oppstod en feil ved oppdatering, vennligst prøv igjen.',
    'device.createButtonText': 'Ny enhet',
    'device.createSuccessToast': 'Enhet opprettet',
    'device.createErrorToast': 'En feil oppstod ved opprettelse av enhet.',
    'device.updateHeadingText': 'Oppdater enhet',
    'device.updateButtonText': 'Lagre',
    'device.updateSuccessToast': 'Enhet ble oppdatert.',
    'device.updateErrorToast': 'En feil oppstod ved oppdatering av enhet.',
    'device.unassigned': 'ledig',
    'device.deviceBlankOptionLabel': 'Ingen enhet',
    'device.deviceBlankOptionLabelUnassign': 'Fjern enhet',
    'device.noTokenWarning':
      'Denne enheten er ikke ferdig oppsatt. Den vil ikke kunne brukes til å ringe til pasienter.',
    'device.assignedWarning': "Denne enheten er knyttet til en pasient. Du må fjerne brettet fra pasienten før du kan gjøre endringer.",
    'device.restartAppPushSuccessful': 'Enheten ble bedt om å starte på nytt',
    'device.restartAppPushError': 'En feil skjedde når enheten ble bedt om å starte på nytt.',
    'device.restartApp': 'Start enhet på nytt',
    'device.battery': 'Batteri',
    'device.noArea': 'Ingen virksomhet',

    'patient.label': 'Pasient',
    'patient.properties.name': 'Navn',
    'patient.properties.deviceId': 'Enhets-ID',
    'patient.properties.deleted': 'Slettede',
    'patient.properties.locale': 'Språk',
    'patient.createLabel': 'Ny pasient',
    'patient.createSuccessToast': 'Pasient opprettet',
    'patient.createErrorToast':
      'Det oppstod et problem ved opprettelse av pasient.',
    'patient.updateHeadingText': 'Oppdater pasient',
    'patient.updateSuccessToast': 'Pasient ble oppdatert.',
    'patient.updateErrorToast':
      'Det oppstod et problem ved oppdatering av pasient.',
    'patient.updateDeviceConfirmText':
      'OBS! Du prøver å oppdatere en pasients enhet, er du sikker?',
    'patient.showDeletedButton': 'Vis slettede',
    'patient.deleteConfirmText':
      'advarsel: Pasienten vil bli slettet. Om pasient er tildelt en enhet i systemet så vil enheten fristilles.',
    'patient.deleteSuccessToast': 'Pasienten ble slettet.',
    'patient.deleteErrorToast':
      'Det oppstod et problem ved sletting av pasienten.',
    'patient.contactSource': 'Kilde',
    'patient.addContact': 'Legg til kontakt',
    'patient.removeContact': 'Fjern kontakt',
    'patient.addContactSuccessToast': 'Kontakten ble lagt til.',
    'patient.addContactErrorToast':
      'Det oppstod et problem ved opprettelsen av kontakten.',
    'patient.removeContactSuccessToast': 'Kontakten ble fjernet.',
    'patient.removeContactErrorToast':
      'Det oppstod et problem ved fjerning av kontakten.',
    'patientContact.directLabel': 'Direkte kontakt',
    'patientContact.nextOfKinAdmin': 'Pårørendeadministrator',

    'patientSubTenantLink.label': 'Tilgang fra andre virksomheter',
    'patientSubTenantLink.properties.createdBy': 'Lagt til av',
    'patientSubTenantLink.createLabel': 'Legg til virksomhet',
    'patientSubTenantLink.createSuccessToast': 'Virksomhet ble lagt til.',
    'patientSubTenantLink.createErrorToast':
      'Det oppstod et problem i å legge til virksomheten..',
    'patientSubTenantLink.deleteConfirmText':
      'Virksomheten fjernes, da vil ikke ansatte fra denne virksomheten kunne ringe til pasienten lengre.',
    'patientSubTenantLink.deleteSuccessToast': 'Virksomheten fjernet.',
    'patientSubTenantLink.deleteErrorToast':
      'Det oppstod et problem ved fjerning av virksomheten.',

    'tenantNavTabs.setupLink': 'Admin',
    'tenantNavTabs.patientsLink': 'Pasienter',
    'tenantNavTabs.usersLink': 'Ansatte',
    'tenantNavTabs.nextOfKinLink': 'Pårørendebrukere',
    'tenantNavTabs.devicesLink': 'Enheter',

    'tenantDeviceList.noPatientText': 'Ingen pasient',
  },
};
