import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 14pt;
  cursor: pointer;
`;

function HelpIcon(props) {
  return <StyledIcon icon="question-circle" className="text-info" {...props} />;
}

export default HelpIcon;
