import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { ucwords } from 'utils/text';
import { useI18n } from 'hooks/i18n';
import Translate from '../Translate';

/**
 * Recursively walk the errors object until an array of messages is found. The last key will be used for the field name.
 * @param {Object} errors Errors object to be parsed
 * @param {Array<String>} parentFields Not set by initial caller - array containing the accumulated path of field names
 * @returns {JSX} Renderable markup
 */
export const formatErrors = errors => {
  return Object.keys(errors).map(field => {
    const fieldName = R.pipe(R.split('.'), R.last)(field);
    const message = R.prop(field, errors);

    return (
      <li key={field}>
        <b>{ucwords(fieldName)}:</b> {message}
      </li>
    );
  });
};

function ErrorBox({ graphQLError, errors }) {
  const { t } = useI18n();

  const isEmptyOrNil = R.anyPass([R.isEmpty, R.isNil]);
  if (isEmptyOrNil(graphQLError) && isEmptyOrNil(errors)) {
    return null;
  }
  const is400 = R.pipe(
    R.pathOr(null, ['networkError', 'statusCode']),
    R.equals(R.__, 400),
  )(graphQLError);

  const errorMessage = is400
    ? t('graphQLBadRequest')
    : t('graphQLGenericError');

  return (
    <div>
      <div className="alert alert-danger text-left" role="alert">
        <h3 style={{ marginTop: 0 }}>
          <Translate text="error" />
        </h3>
        {errorMessage}

        {!isEmptyOrNil(errors) && <ul>{formatErrors(errors)}</ul>}
      </div>
    </div>
  );
}

ErrorBox.propTypes = {
  message: PropTypes.node,
  errors: PropTypes.object,
};

ErrorBox.defaultProps = {
  message: null,
  errors: null,
};

export default ErrorBox;
