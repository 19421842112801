import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import * as types from 'types';
import { useI18n } from 'hooks/i18n';
import { NEW_REMOVE_PARTNER_TENANT } from 'apollo/operations/tenant';
import { toast } from 'react-toastify';

function RemovePartnerTenant({ tenantId, partnerId, refetchQueries }) {
  const { t } = useI18n();
  const [removePartnerTenant, { loading }] = useMutation(NEW_REMOVE_PARTNER_TENANT, {
    refetchQueries
  });

  async function handleDelete(event) {
    event.preventDefault();

    const confirmText = t('tenant.confirmPartnerRemove');

    if (!window.confirm(confirmText)) {
      return;
    }


    try {
      let data = await removePartnerTenant({ variables: { tenantId, partnerId } });
      if (data.data.success) {
        window.alert(t('tenant.removeSuccessToast'))
      }
    } catch (err) {
      console.log(err)
      toast.error(t('tenant.removeErrorToast'));
    }
  }

  return (
        <button
        type="button"
        disabled={ loading }
        onClick={handleDelete}
        >
          ❌
        </button>
  );
}

RemovePartnerTenant.propTypes = {
  partnerId: types.partnerIdType.isRequired,
  tenantId: types.tenantIdType.isRequired,
};

export default RemovePartnerTenant;
