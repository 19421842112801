import React from 'react';
import * as R from 'ramda';

import { deviceListRoute } from 'routes';
import StandardLayout from 'components/layouts/StandardLayout';
import UpdateDevice from 'components/modules/device/UpdateDevice';
import { useRouteMatch } from 'react-router-dom';
import { useI18n } from 'hooks/i18n';
import { useQuery } from '@apollo/react-hooks';
import { NEW_GET_DEVICE } from 'apollo/operations/device';
import Spinner from 'components/common/Spinner';
import Translate from 'components/common/Translate';
import KioskSelect from 'components/modules/device/KioskSelect';
import DateTime from 'components/common/DateTime';
import LastSeen from 'components/common/LastSeen';

import { useAuth } from 'hooks/auth';


function DeviceInfo({device}) {
  const { hasAnyRole } = useAuth();
  const { locale } = useI18n();

  const canKiosk = hasAnyRole(['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN']);

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <div className="card">
      <div className="card-header">
        <Translate text="device.label" />
      </div>
      <div className="card-body">
        <div className="row mb-2">
          <div className="col-sm-6"><Translate text="device.properties.deviceEasyId" /></div>
          <div className="col-sm-2"></div>
          <div className="col-sm-4 text-right">
            {device.deviceEasyId}
          </div>
        </div>

        { canKiosk &&
          <>
        <div className="row mb-2">
          <div className="col-sm-6"><Translate text="device.properties.mdmProvisioningStatus" /></div>
          <div className="col-sm-2"></div>
          <div className="col-sm-4 text-right">
            {device.mdmProvisioningStatus}
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-6"><Translate text="device.properties.kioskMode" /></div>
          <div className="col-sm-2"></div>
          <div className="col-sm-4 text-right">
            {device.kioskMode}
          </div>
        </div>
        </>
        }

        <div className="row mb-2">
          <div className="col-sm-6"><Translate text="device.properties.tokenLastUpdatedAt" /></div>
          <div className="col-sm-2"></div>
          <div className="col-sm-4 text-right">
          {device.googleTokenLastUpdated && <DateTime date={device.googleTokenLastUpdated} timezone={tz}/>}
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-6"><Translate text="device.properties.lastSeen" /></div>
          <div className="col-sm-2"></div>
          <div className="col-sm-4 text-right">
            {device.lastSeen && <LastSeen date={Date.parse(device.lastSeen)} locale={locale}/>}
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-6"><Translate text="device.battery" /></div>
          <div className="col-sm-2"></div>
          <div className="col-sm-4 text-right">
            {device.lastSeenBattery}
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-6"><Translate text="device.properties.currentVersion" /></div>
          <div className="col-sm-2"></div>
          <div className="col-sm-4 text-right">
            {device.currentVersion}
          </div>
        </div>
        
        { canKiosk &&
        <div className="row mb-2">
          <div className="col-sm-4"><Translate text="device.properties.mdmQrCode" /></div>
            <div className="col-sm-8 text-right">{
              device.mdmQrCode ? <img src={`data:image/png;base64,${device.mdmQrCode}`} alt="mdmQrCode" style={{maxWidth: '100%'}}/>
              : <Translate text="device.mdmQrCode.empty" />
            }
            </div>
        </div>
        }
    </div>


    <div className="card-footer text-right">

    </div>
  </div>
  );
}

export default DeviceInfo;
