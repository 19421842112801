import React from 'react';
import * as R from 'ramda';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as types from 'types';
import { setValidationErrors } from 'utils/forms';
import { NEW_CREATE_PATIENT, GET_TENANT_PATIENTS } from 'apollo/operations/patient';
import { patientRoute } from 'routes';
import PatientFormFields from './PatientFormFields';
import { useI18n } from 'hooks/i18n';
import Translate from 'components/common/Translate';
import { Modal } from 'react-bootstrap';
import { useModalState } from 'hooks/modal';

function CreatePatient({ tenantId, subTenantId, groupId, refetchQueries, children }) {
  const { push: routerPush } = useHistory();
  const { t } = useI18n();
  const { show, showModal, hideModal } = useModalState();
  const [createPatient, { loading }] = useMutation(NEW_CREATE_PATIENT, {
    refetchQueries
  });

  async function handleSubmit(values, formikBag) {
    try {
      const result = await createPatient({
        variables: {
          groupId,
          patient: { name: values.patient.name, locale: values.patient.locale },
        },
      });
      toast.success(t('patient.createSuccessToast'));
      hideModal();

      const patientId = R.path(['data', 'patientCreate', 'id'])(result);
      routerPush(patientRoute(tenantId, patientId));
    } catch (err) {
      console.log('error creating patient:', err);
      setValidationErrors(err, formikBag);
      toast.error(t('patient.createErrorToast'));
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate text="patient.createLabel" />
          </Modal.Title>
        </Modal.Header>

        <Formik
          onSubmit={handleSubmit}
          initialValues={{ patient: { name: '' } }}
        >
          <Form>
            <Modal.Body>
              <PatientFormFields
                tenantId={tenantId}
                subTenantId={subTenantId}
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                <Translate text="patient.createLabel" />
              </button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}

CreatePatient.propTypes = {
  tenantId: types.tenantIdType.isRequired,
  subTenantId: types.subTenantIdType.isRequired,
  groupId: types.groupIdType.isRequired,
  children: PropTypes.func.isRequired,
};

export default CreatePatient;
