import React from 'react';
import { FastField } from 'formik';
import PropTypes from 'prop-types';

import { defaultLocales } from 'utils/modules/user';
import FormikField from 'components/common/FormikField';

function LocaleSelect(props) {
  return (
    <FastField
      component={FormikField}
      type="select"
      options={defaultLocales}
      {...props}
    />
  );
}

LocaleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
};

LocaleSelect.defaultProps = {
  label: 'Locale',
  required: false,
};

export default LocaleSelect;
