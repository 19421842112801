import React from 'react';
import PropTypes from 'prop-types';
import { Route, Link } from 'react-router-dom';
import classnames from 'classnames';

const NavLink = ({
  'aria-current': ariaCurrent,
  activeClassName,
  activeStyle,
  className: classNameProp,
  exact,
  isActive: isActiveProp,
  location,
  strict,
  style: styleProp,
  to,
  children,
  disabled,
  ...rest
}) => {
  if (disabled) {
    return (
      <span
        className={classNameProp}
        style={{
          color: '#cccccc',
          cursor: 'not-allowed',
          ...styleProp,
        }}
      >
        {children}
      </span>
    );
  }

  return (
    <Route
      path={typeof to === 'object' ? to.pathname : to}
      exact={exact}
      strict={strict}
      location={location}
    >
      {({ location, match }) => {
        const isActive = !!(isActiveProp
          ? isActiveProp(match, location)
          : match);

        const className = classnames(classNameProp, {
          [activeClassName]: isActive,
        });
        const style = isActive ? { ...styleProp, ...activeStyle } : styleProp;

        return (
          <Link
            aria-current={(isActive && ariaCurrent) || null}
            className={className}
            style={style}
            to={to}
            {...rest}
          >
            {children}
          </Link>
        );
      }}
    </Route>
  );
};

const ariaCurrentType = PropTypes.oneOf([
  'page',
  'step',
  'location',
  'date',
  'time',
  'true',
]);

NavLink.propTypes = {
  'aria-current': ariaCurrentType,
  activeClassName: PropTypes.string,
  activeStyle: PropTypes.object,
  className: PropTypes.string,
  exact: PropTypes.bool,
  isActive: PropTypes.func,
  location: PropTypes.object,
  strict: PropTypes.bool,
  style: PropTypes.object,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

NavLink.defaultProps = {
  'aria-current': 'page',
  activeClassName: 'active',
  disabled: false,
};

export default NavLink;
