import React, { useEffect } from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import { VERIFY_EMAIL } from 'apollo/operations/user';
import StandardLayout from 'components/layouts/StandardLayout';
import Spinner from 'components/common/Spinner';
import { loginRoute } from 'routes';
import { useI18n } from 'hooks/i18n';
import Translate from 'components/common/Translate';

function VerifyEmailPage() {
  const { t } = useI18n();
  const { path, params } = useRouteMatch();
  const { token } = params;

  const [verifyEmail, { data, loading, error }] = useMutation(VERIFY_EMAIL, {
    variables: { token },
  });

  useEffect(() => {
    verifyEmail();
  }, [token, verifyEmail]);

  // TODO: Maybe log user in immediately after verifying. Would need a token created in the backend.
  // For now, skip this and just show a link to the login page
  // useEffect(() => {
  //   if (data) {
  //     const user = R.path(['verifyEmail'], data);
  //     if (user) {
  //       setLocalAuthority(user);
  //     } else {
  //       console.log(
  //         'Unexpected error setting local authority from verification response',
  //         user,
  //       );
  //     }
  //   }
  // }, [data, setLocalAuthority]);

  return (
    <StandardLayout breadcrumb={[[path, t('route.verifyEmail')]]}>
      <Spinner show={loading} />
      {data && (
        <>
          <h1>
            <Translate text="route.verifyEmail" />
          </h1>
          <p>
            <Translate text="auth.verifyEmailSuccessText" />
          </p>
          <p>
            <Link to={loginRoute()}>
              <Translate text="auth.loginButton" />
            </Link>
          </p>
        </>
      )}
      {error && (
        <p>
          <Translate text="user.invalidTokenError" />
        </p>
      )}
    </StandardLayout>
  );
}

export default VerifyEmailPage;
