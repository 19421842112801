import React from 'react';
import * as R from 'ramda';
import { useQuery } from '@apollo/react-hooks';
import { useRouteMatch } from 'react-router-dom';

import { Table as BSTable } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PartnerRemoveTenant from 'components/modules/tenant/RemovePartnerTenant';
import {tenantRoute} from 'routes';

import Translate from 'components/common/Translate';

function PartnerTenants({tenants, partner, refetchQueries}) {

  return (
    <BSTable striped bordered hover>
    <thead>
      <tr>
        <th>
          <Translate text="name" />
        </th>
        <th>
          <Translate text="id" />
        </th>
        <th>
          <Translate text="remove" />
        </th>
        <th>
          <Translate text="edit" />
        </th>
      </tr>
    </thead>
    <tbody>
      {tenants.map(tenant => {
        return (
          <tr key={tenant.id}>
            <td><Link to={tenantRoute(tenant.id)}>{tenant.name}</Link></td>
            <td>{tenant.id}</td>
            <td>
              <PartnerRemoveTenant tenantId={tenant.id} partnerId={partner.id} refetchQueries={refetchQueries}/>
            </td>
            <td>
              <Link to={'/tenants/' + tenant.id + '/update'}><Translate text="edit" /></Link>
            </td>
          </tr>
        )

      } )}
    </tbody>
  </BSTable>
  );
}

export default PartnerTenants;