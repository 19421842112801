import React from 'react';
import * as R from 'ramda';
import { Formik, Form, Field } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import * as yup from 'yup';

import { useAuth } from 'hooks/auth';
import { NEW_UPDATE_USER } from 'apollo/operations/user';
import FormikField from 'components/common/FormikField';
import { setValidationErrors } from 'utils/forms';
import ErrorBox from 'components/common/ErrorBox';
import { toast } from 'react-toastify';
import Translate from 'components/common/Translate';
import { useI18n } from 'hooks/i18n';

const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

const initialValues = user => ({
  name: user.name,
  email: user.email,
  displayName: user.displayName,
  password: '',
  confirmPassword: '',
  defaultLocale: user.defaultLocale,
  subtenantId: user.subtenantId,
});

function UpdateUser() {
  const { t } = useI18n();
  const { localAuthority, setLocalAuthority } = useAuth();
  const [updateUser, { loading, error }] = useMutation(NEW_UPDATE_USER);

  async function handleSubmit(values, formikBag) {
    const id = localAuthority.id;
    const userProps = ['name', 'displayName', 'email', 'defaultLocale'];

    // Only send password if the field has input
    const hasNewPassword = R.pipe(
      R.path(['password']),
      R.complement(isNilOrEmpty),
    )(values);
    if (hasNewPassword) userProps.push('password');

    const user = R.pick(userProps, values);
    const variables = {
      id,
      user,
    };

    try {
      const res = await updateUser({ variables });
      const user = R.path(['data', 'userUpdate'], res);
      const newLocalAuthority = {...localAuthority}
      newLocalAuthority.email = user.email;
      newLocalAuthority.name = user.name;
      newLocalAuthority.displayName = user.displayName;
      newLocalAuthority.defaultLocale = user.defaultLocale;
      setLocalAuthority(newLocalAuthority);

      toast.success(t('user.updateProfileSuccessToast'));
    } catch (err) {
      console.log('error updating user:', err);
      setValidationErrors(err, formikBag);
      toast.error(t('user.updateProfileErrorToast'));
    } finally {
      formikBag.setSubmitting(false);
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues(localAuthority)}
      validationSchema={yup.object({
        name: yup.string().required(),
        email: yup
          .string()
          .email()
          .required(),
        password: yup.string(),
        confirmPassword: yup
          .string()
          .test('matches-password', 'Must match password', function(value) {
            if (R.isEmpty(this.parent.password)) return true;
            return value === this.parent.password;
          }),
      })}
    >
      <Form>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">
              <Translate text="user.updateProfileLabel" />
            </h5>

            <Field
              name="email"
              label={t('user.properties.email')}
              component={FormikField}
              disabled
            />
            <Field
              name="name"
              label={t('user.properties.name')}
              component={FormikField}
              required
            />
            <Field
              name="displayName"
              label={t('user.properties.displayName')}
              component={FormikField}
              required
            />

            <Field
              name="defaultLocale"
              label={t('user.properties.defaultLocale')}
              component={FormikField}
              type="select"
              options={[
                { value: 'EN', label: t('language.english') },
                { value: 'NB', label: t('language.norwegian') },
              ]}
              required
            />

            {/* <div className="row">
              <div className="col">
                <Field
                  name="password"
                  label={t('user.properties.newPassword')}
                  component={FormikField}
                  type="password"
                />
              </div>
              <div className="col">
                <Field
                  name="confirmPassword"
                  label={t('user.properties.passwordConfirm')}
                  component={FormikField}
                  type="password"
                />
              </div>
            </div> */}

            <ErrorBox graphQLError={error} />
          </div>

          <div className="card-footer text-right">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              <Translate text="save" />
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}

export default UpdateUser;
