import React from 'react';
import * as R from 'ramda';
import { Formik, Form } from 'formik';

import * as types from 'types';
import PatientFormFields from './PatientFormFields';
import { useMutation } from '@apollo/react-hooks';
import { NEW_UPDATE_PATIENT, NEW_PATIENT_DETAIL_PAGE } from 'apollo/operations/patient';
import { NEW_UPDATE_DEVICE, NEW_GET_TENANT_DEVICES } from 'apollo/operations/device';
import { toast } from 'react-toastify';
import { setValidationErrors } from 'utils/forms';
import ErrorBox from 'components/common/ErrorBox';
import { useI18n } from 'hooks/i18n';
import Translate from 'components/common/Translate';

function UpdatePatient({ patient, tenantId }) {
  const { t } = useI18n();

  const refetchQueries = [
    // The device assignments may have changed after updating the patient
    { query: NEW_PATIENT_DETAIL_PAGE, variables: { id: patient.id } },
  ];

  const [updatePatient, { loading, error }] = useMutation(NEW_UPDATE_PATIENT, {
    variables: { id: patient.id },
    refetchQueries,
  });

  const [updateDevice, { deviceLoading, deviceError }] = useMutation(NEW_UPDATE_DEVICE, {
    refetchQueries: [{query: NEW_PATIENT_DETAIL_PAGE, variables: {id: patient.id}}],
  });

  const subTenant = R.path(['group',  'subTenant'], patient);

  async function handleSubmit(values, formikBag) {
    const defaultToNull = value => value || null;

    const name = R.path(['patient', 'name'], values);
    const locale = R.path(['patient', 'locale'], values);
    const deviceId = R.pipe(
      R.pathOr(null, ['patient', 'device', 'id']),
      defaultToNull,
    )(values);
    const oldDeviceId = patient.device?.id

    const patientInput = { name, locale };

    if (patientInput.deviceId !== patient.device?.id) {
      if (!window.confirm(t('patient.updateDeviceConfirmText'))) {
        return;
      }
    }

    try {
      await updatePatient({
        variables: {
          patient: patientInput,
        },
      });
      if (deviceId) {
        await updateDevice({
          variables: {
            id: deviceId,
            device: {
              patientId: patient.id
            }
          }
        })
      }
      if (!deviceId && oldDeviceId) {
        await updateDevice({
          variables: {
            id: oldDeviceId,
            device: {
              patientId: null
            }
          }
        })
      }

      toast.success(t('patient.updateSuccessToast'));
      // formikBag.resetForm();
    } catch (err) {
      console.log('Error updating patient:', err);
      toast.error(t('patient.updateErrorToast'));
      setValidationErrors(err, formikBag);
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ patient }}
      validationSchema={null}
    >
      <Form>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">
              <Translate text="patient.updateHeadingText" />
            </h5>

            <PatientFormFields
              tenantId={tenantId}
              subTenantId={subTenant.id}
              patientId={patient.id}
            />

            <ErrorBox graphQLError={error || deviceError} />
          </div>

          <div className="card-footer text-right">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading || deviceLoading}
            >
              <Translate text="save" />
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}

UpdatePatient.propTypes = {
  tenantId: types.tenantIdType.isRequired,
  patient: types.patientType.isRequired,
};

export default UpdatePatient;
