import React, {useState} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import * as R from 'ramda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { Form } from 'react-bootstrap';

import { useI18n } from 'hooks/i18n';

import { NEW_TENANT_SETUP_PAGE } from 'apollo/operations/tenant';
import Spinner from 'components/common/Spinner';
import RoleSecured from 'components/common/RoleSecured';
import CreateSubtenant from 'components/modules/subTenant/CreateSubTenant';
import UpdateTenantLocale from 'components/modules/tenant/UpdateTenantLocale';
import Translate from 'components/common/Translate';
import SubTenantAdminList from 'components/modules/subTenant/SubTenantAdminList';
import TenantAdminList from 'components/modules/tenant/TenantAdminList';
import UpdateSubTenantModal from 'components/modules/subTenant/UpdateSubTenantModal';
import CreateGroup from 'components/modules/group/CreateGroup';
import SubTenantGroupList from 'components/modules/subTenant/SubTenantGroupList';
import GrantSubTenantAdminModal from 'components/modules/subTenant/GrantSubTenantAdminModal';
import TenantCreateAdmin from 'components/modules/user/TenantCreateAdmin';
import ToggleDeleteSubTenantButton from 'components/modules/subTenant/ToggleDeleteSubTenantButton';

function TenantSetupTab() {
  const match = useRouteMatch();
  const tenantId = match.params.tenantId;
  const [showDeletedSubTenant, setShowDeletedSubTenant] = useState(false);
  const { t } = useI18n();


  const { data, loading } = useQuery(NEW_TENANT_SETUP_PAGE, {
    variables: { id: tenantId },
  });

  const refetchQueries = [
    { query: NEW_TENANT_SETUP_PAGE, variables: { id: tenantId } },
  ];

  if (loading) return <Spinner />;

  const toggleShowDelete = () => {
    setShowDeletedSubTenant(!showDeletedSubTenant)
  }

  const tenant = R.prop('tenant')(data);

  const subTenants = R.pipe(R.path(['subTenants']), R.defaultTo([]))(tenant);


  return (
    <>
      <RoleSecured roles={['ROLE_ADMIN', 'ROLE_TENANT_ADMIN', 'ROLE_PARTNER_ADMIN']}>
        <div className="mt-2 mb-2">
          <CreateSubtenant tenantId={tenantId} refetchQueries={refetchQueries}>
            {showModal => (
              <button
                type="button"
                className="btn btn-sm btn-primary float-right"
                onClick={showModal}
              >
                <Translate text="subTenant.createLabel" />
              </button>
            )}
          </CreateSubtenant>
          <Form.Switch id="switch" label={t('subTenant.showDeleted')} className='float-right mr-2' onChange={toggleShowDelete}/>
          <UpdateTenantLocale tenant={tenant} tenantId={tenantId} tenantLocale={tenant.locale} refetchQueries={refetchQueries}>
            {showModal => (
              <button
                type="button"
                className="btn btn-sm btn-primary float-left"
                onClick={showModal}
              >
                <Translate text="tenant.updateLocaleLabel" />
              </button>
            )}
          </UpdateTenantLocale>
        </div>
        <div className="clearfix" />
      </RoleSecured>

      <div className="mt-3" />
        <div className="card mb-3">
            <div className="card-body">
              <h3 className="card-title">
                <Translate text="tenant.admins.label" />
                <RoleSecured roles={['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN', 'ROLE_TENANT_ADMIN']}>
                <div className="mt-2 text-right float-right">
                <TenantCreateAdmin
                        tenantId={tenantId}
                        refetchQueries={refetchQueries}
                      >
                        {showModal => (
                          <button
                            type="button"
                            className="btn btn-sm btn-primary float-right"
                            onClick={showModal}
                          >
                            <Translate text="user.createAdminButtonText" />
                          </button>
                        )}
                </TenantCreateAdmin>

                </div>
            </RoleSecured>
              </h3>
            <div className="card">
              <TenantAdminList
                tenantId={tenantId}
                users={tenant.admins}
                refetchQueries={refetchQueries}
              />
          </div>
        </div>
      </div>

      {subTenants.map(subTenant => (
        (!subTenant.deletedAt || showDeletedSubTenant) && <div key={subTenant.id} className="card mb-3">
          <div className="card-body">
            <h3 className="card-title">{subTenant.name}
            <UpdateSubTenantModal subTenantId={subTenant.id} subTenantName={subTenant.name} refetchQueries={refetchQueries}>
              {showModal => (
                <small>
                  <FontAwesomeIcon icon={faPen} size="xs" onClick={showModal} className={"ml-3 cursor-pointer"} />
                </small>
              )}
          </UpdateSubTenantModal>
          <ToggleDeleteSubTenantButton subTenantId={subTenant.id} deleted={!!subTenant.deletedAt} refetchQueries={refetchQueries} />

             </h3>
            <div className="card">
              <div className="card-header">
                <GrantSubTenantAdminModal
                  tenantId={tenantId}
                  subTenantId={subTenant.id}
                  subTenantName={subTenant.name}
                  admins={subTenant.admins}
                  users={subTenant.users}
                  refetchQueries={refetchQueries}
                >
                  {showModal => (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm float-right"
                      onClick={showModal}
                    >
                      <Translate text="subTenant.addAdminLabel" />
                    </button>
                  )}
                </GrantSubTenantAdminModal>

                <Translate text="user.areaAdminLabel" />
              </div>

              <SubTenantAdminList
                tenantId={tenantId}
                subTenantId={subTenant.id}
                admins={subTenant.admins}
                refetchQueries={refetchQueries}
              />
            </div>

            <div className="mt-3" />

            <div className="card">
              <div className="card-header">
                <CreateGroup
                  subTenantId={subTenant.id}
                  refetchQueries={refetchQueries}
                >
                  {showModal => (
                    <button
                      type="button"
                      className="btn btn-sm btn-primary float-right"
                      onClick={showModal}
                    >
                      <Translate text="group.createLabel" />
                    </button>
                  )}
                </CreateGroup>

                <Translate text="group.label" />
              </div>

              <SubTenantGroupList
                tenantId={tenantId}
                subTenantId={subTenant.id}
                groups={subTenant.groups}
                refetchQueries={refetchQueries}
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default TenantSetupTab;
