import { useState, useEffect } from 'react';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';

const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

const ascendFn = R.curry((fn, a, b) => {
  var aa = fn(a);
  var bb = fn(b);
  if (isNilOrEmpty(aa) && !isNilOrEmpty(bb)) return 1;
  if (!isNilOrEmpty(aa) && isNilOrEmpty(bb)) return -1;
  return aa < bb ? -1 : aa > bb ? 1 : 0;
});

const descendFn = R.curry((fn, a, b) => {
  var aa = fn(a);
  var bb = fn(b);
  if (isNilOrEmpty(aa) && !isNilOrEmpty(bb)) return -1;
  if (!isNilOrEmpty(aa) && isNilOrEmpty(bb)) return 1;
  return aa > bb ? -1 : aa < bb ? 1 : 0;
});

const dirFn = dirStr => {
  if (dirStr === 'asc') return ascendFn;
  if (dirStr === 'desc') return descendFn;
};

const dirStr = dirFn => {
  if (dirFn === ascendFn) return 'asc';
  if (dirFn === descendFn) return 'desc';
};

const flipDirection = sortDirection => {
  if (sortDirection === ascendFn) return descendFn;
  return ascendFn;
};

export function useSortFilter(
  unsortedItems,
  sortProperty,
  searchProperty,
  initialReverse = false,
) {
  const history = useHistory();

  const initialSortFn = initialReverse ? descendFn : ascendFn;

  const [sortHistory, setSortHistory] = useState([
    [sortProperty, initialSortFn],
  ]);
  const [includesFilter, setIncludesFilter] = useState('');
  const [startsWithFilter, setStartsWithFilter] = useState('');

  const [currentSort, sortDirection] = R.head(sortHistory);

  function parseSortQuery(queryString) {
    if (!queryString) return null;
    return R.pipe(
      R.split(';'),
      R.map(str => {
        const [prop, direction] = R.split(',', str);
        return [prop, dirFn(direction)];
      }),
    )(queryString);
  }

  function buildSortQuery(sortHistory) {
    return R.pipe(
      R.map(([prop, fn]) => `${prop},${dirStr(fn)}`),
      R.join(';'),
    )(sortHistory);
  }

  // Set initial search query if one exists on mount
  useEffect(() => {
    const queryHistory = R.pipe(
      R.path(['location', 'search']),
      searchStr => new URLSearchParams(searchStr),
      params => params.get('sort'),
      parseSortQuery,
    )(history);

    if (queryHistory) {
      setSortHistory(queryHistory);
    }
  }, [history]);

  // Push the given property to the top of the sort history
  // The final sort list is filtered so any given property can only exist once
  function handleSortChange(property) {
    const nextSortHistory = R.pipe(
      R.prepend([
        property,
        currentSort === property ? flipDirection(sortDirection) : ascendFn,
      ]),
      R.uniqBy(R.head),
    )(sortHistory);

    setSortHistory(nextSortHistory);

    // Replace sort query in URL with stringified sort history
    const searchQuery = R.pipe(
      R.path(['location', 'search']),
      str => new URLSearchParams(str),
      params => {
        params.set('sort', buildSortQuery(nextSortHistory));
        return params;
      },
    )(history);

    const nextLocation = R.pipe(
      R.prop('location'),
      R.assoc('search', searchQuery.toString()),
    )(history);

    history.replace(nextLocation);
  }

  function handleStartsWithChange(value) {
    const nextVal = typeof value === 'string' ? value.toLowerCase() : value;
    setStartsWithFilter(startsWithFilter === nextVal ? '' : nextVal);
  }

  function handleIncludesChange(value) {
    setIncludesFilter(value);
  }

  const filteredItems = R.isEmpty(searchProperty)
    ? // Skip filtering if the search property is not set
      unsortedItems
    : unsortedItems.filter(item => {
        const propPath = R.split('.', searchProperty);
        const value = R.pipe(
          R.path(propPath),
          R.defaultTo(''),
          R.toLower,
          R.trim,
        )(item);
        if (!R.isEmpty(startsWithFilter) && !value.startsWith(startsWithFilter))
          return false;
        if (!R.isEmpty(includesFilter) && !value.includes(includesFilter))
          return false;
        return true;
      });

  // Returns the property specified by dot.path in an object, lowercased
  const valueProp = property =>
    R.pipe(
      R.path(R.split('.', property)),
      R.defaultTo(''),
      val => (typeof val === 'string' ? R.toLower(val) : val),
      val => (typeof val === 'string' ? R.trim(val) : val),
    );
  // Create a sort function for the given property and direction
  const sortFn = property => sortDirection(valueProp(property));
  // Takes a given array of sort-tuples [property, reverse] and returns a list of sort functions
  const mapSortFns = R.map(R.apply(sortFn));

  const sortedItems = R.sortWith(mapSortFns(sortHistory))(filteredItems);

  return {
    items: sortedItems,
    onSortChange: handleSortChange,
    filterSearchProps: {
      includesValue: includesFilter,
      startsWithValue: startsWithFilter,
      onIncludesChange: handleIncludesChange,
      onStartsWithChange: handleStartsWithChange,
    },
    sortIndicatorProps: {
      sort: currentSort,
      sortReverse: sortDirection === ascendFn,
      onSortChange: handleSortChange,
    },
  };
}
