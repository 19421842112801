import PropTypes from 'prop-types';

import { useAuth } from 'hooks/auth';

function RoleSecured({ children, roles, guest }) {
  const { isAuthenticated, hasAnyRole } = useAuth();

  // Only render when anonymous if guest prop is set
  if (guest) {
    return isAuthenticated ? null : children;
  }

  // Render nothing if the user is not authenticated or does not have one of the provided roles
  // Maybe add a fallback render prop for content to render instead of nothing
  if (!isAuthenticated || !hasAnyRole(roles)) {
    return null;
  }

  return children;
}

RoleSecured.propTypes = {
  /** Secured content */
  children: PropTypes.node.isRequired,
  /** List of roles that have access to content */
  roles: PropTypes.arrayOf(PropTypes.string),
  /** Only allow anonymous users */
  guest: PropTypes.bool,
};

RoleSecured.defaultProps = {
  // If no roles provided is set, require 'user' role
  roles: ['user'],
  guest: false,
};

export default RoleSecured;
