import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import * as types from 'types';
import { useI18n } from 'hooks/i18n';
import { NEW_DELETE_PARTNER_USER } from 'apollo/operations/user';
import { toast } from 'react-toastify';

function PartnerRemoveUser({ userId, partnerId, refetchQueries }) {
  const { t } = useI18n();
  const [deletePartnerUser, { loading }] = useMutation(NEW_DELETE_PARTNER_USER, {
    refetchQueries
  });

  async function handleDelete(event) {
    event.preventDefault();

    const confirmText = t('user.confirmPartnerDelete');

    if (!window.confirm(confirmText)) {
      return;
    }


    try {
      let data = await deletePartnerUser({ variables: { userId, partnerId } });
      if (data.data.success) {
        window.alert(t('user.alertDeleted'))
      }
    } catch (err) {
      console.log(err)
      toast.error(t('user.removedUserFailedToast'));
    }
  }

  return (
        <button
        type="button"
        disabled={ loading }
        onClick={handleDelete}
        >
          ❌
        </button>
  );
}

PartnerRemoveUser.propTypes = {
  partnerId: types.partnerIdType.isRequired,
  userId: types.userUuidType.isRequired,
  refreshQuery: types.refetchQueriesType,
};

export default PartnerRemoveUser;
