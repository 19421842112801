import React from 'react';
import * as R from 'ramda';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { Table as BSTable, Button as BSButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { NEW_CREATE_DEVICE, NEW_DEVICE_LIST } from 'apollo/operations/device';
import { deviceRoute, tenantRoute, patientRoute } from 'routes';
import Spinner from 'components/common/Spinner';
import { toast } from 'react-toastify';
import DateTime from 'components/common/DateTime';
import Translate from 'components/common/Translate';
import { useI18n } from 'hooks/i18n';

function TenantList() {
  const { t } = useI18n();
  const { loading: loadingDevices, data } = useQuery(NEW_DEVICE_LIST);
  const [createDevice, { loading: loadingCreate }] = useMutation(
    NEW_CREATE_DEVICE,
    {
      refetchQueries: [{ query: NEW_DEVICE_LIST }],
      variables: {
        device: {
          locale: 'NB'
        }
      }
    },
  );

  if (loadingDevices) {
    return <Spinner />;
  }

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const deviceList = R.pipe(
    R.pathOr(null, ['deviceList']),
    R.defaultTo([]),
    R.sortBy(R.prop('deviceEasyId')),
  )(data);

  async function handleCreate() {
    if (!window.confirm('A new device will be created.')) {
      return;
    }

    try {
      await createDevice();
      toast.success(t('device.createSuccessToast'));
    } catch (err) {
      console.log('Error creating device', err);
      toast.error(t('device.createErrorToast'));
    }
  }

  return (
    <>
      <BSButton
        className="float-right"
        disabled={loadingCreate}
        onClick={handleCreate}
      >
        <Translate text="device.createButtonText" />
      </BSButton>
      <BSTable striped bordered hover>
        <thead>
          <tr>
            <th className="text-right">
              <Translate text="device.properties.deviceEasyId" />
            </th>
            <th>
              <Translate text="device.properties.tokenLastUpdatedAt" />
            </th>
            <th>
              <Translate text="device.properties.lastSeen" />
            </th>
            <th>
              <Translate text="device.properties.currentVersion" />
            </th>
            <th>
              <Translate text="device.properties.provisioningStatus" />
            </th>
            <th className="text-center">
              <Translate text="tenant.label" />
            </th>
            <th className="text-center">
              <Translate text="subTenant.label" />
            </th>
            <th className="text-center">
              <Translate text="device.properties.patient" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {deviceList.map(device => {
            return (
              <tr key={device.id}>
                <td className="text-right">
                  {device.deviceEasyId}
                </td>
                <td>
                  {device.deviceTokenUpdated && (
                    <DateTime date={device.deviceTokenUpdated} timezone={tz}/>
                  )}
                </td>
                <td>
                  {device.lastSeen ? (
                    <DateTime date={device.lastSeen} timezone={tz} /> )
                  : "❌"
                  }
                </td>
                <td> {device.currentVersion ? device.currentVersion : "❌"} </td>
                <td className="text-center">{device.mdmProvisioningStatus ||  "❌"}</td>
                <td className="text-center">
                  {device.tenant && (
                    <Link to={tenantRoute(device.tenant.id)}>
                      {device.tenant.name}
                    </Link>
                  )}
                </td>
                <td className="text-center">
                  {device.subTenant && (
                    <Link to={tenantRoute(device.subTenant.id)}>
                      {device.subTenant.name}
                    </Link>
                  )}
                </td>
                <td className="text-center">{device.patient && device.tenant &&
                    <Link to={patientRoute(device.tenant.id, device.patient.id)}>
                    {device.patient.name}
                  </Link>
                }</td>
                <td>
                  <Link to={'/device/' + device.id}>
                    <Translate text="edit" />
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </BSTable>
    </>
  );
}

export default TenantList;
