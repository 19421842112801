import React, { useState } from 'react';
import * as R from 'ramda';
import { useQuery } from '@apollo/react-hooks';
import { useLazyQuery } from 'hooks/apollo';
import { useRouteMatch } from 'react-router-dom';

import Spinner from 'components/common/Spinner';
import Translate from 'components/common/Translate';
import { NEW_TENANT_PATIENT_LIST_PAGE } from 'apollo/operations/tenant';
import GroupPatients from 'components/modules/tenant/GroupPatients';
import CreatePatient from 'components/modules/patient/CreatePatient';

function TenantPatientsTab() {
  const [withDeleted, setWithDeleted] = useState(false);
  const { params } = useRouteMatch();
  const { tenantId } = params;
  const { loading, data } = useQuery(NEW_TENANT_PATIENT_LIST_PAGE, {
    variables: { id: tenantId, includeDeleted: withDeleted },
  });

  const refetchQueries = [
    {
      query: NEW_TENANT_PATIENT_LIST_PAGE,
      variables: { id: tenantId, includeDeleted: withDeleted },
    },
  ];

  function handleWithDeleted(event) {
    setWithDeleted(event.target.checked);
  }

  const subTenants = R.pipe(
    R.pathOr(null, ['tenant', 'subTenants']),
    R.defaultTo([]),
  )(data);

  return (
    <div>
      <div className="mt-2 text-right">
        <div className="custom-control custom-switch float-right mt-2 mr-3">
          <input
            type="checkbox"
            className="custom-control-input"
            id="withDeleted"
            checked={withDeleted}
            onChange={handleWithDeleted}
          />
          <label className="custom-control-label" htmlFor="withDeleted">
            <Translate text="patient.showDeletedButton" />
          </label>
        </div>
      </div>
      <div className="clearfix mb-2" />

      {loading && <Spinner />}
      {
        subTenants.length == 0 && (
          <div className="alert alert-warning">
            <Translate text="group.missingWarning" />
          </div>
        )
      }
      {subTenants.map(subTenant => (
        <div key={subTenant.id} className="card mt-3">
          <div className="card-body">
            <h3 className="card-title">{subTenant.name}</h3>

            {subTenant.groups.filter(group => group.deleted_at == null).map(group => (
              <div key={group.id} className="card mt-3">
                <div className="card-header">
                  <CreatePatient
                    tenantId={tenantId}
                    subTenantId={subTenant.id}
                    groupId={group.id}
                    refetchQueries={refetchQueries}
                  >
                    {showModal => (
                      <button
                        type="button"
                        className="btn btn-sm btn-primary float-right"
                        onClick={showModal}
                      >
                        <Translate text="patient.createLabel" />
                      </button>
                    )}
                  </CreatePatient>
                  
                  <div className="mt-1">{group.name}</div>
                </div>

                <GroupPatients
                  tenantId={tenantId}
                  subTenantId={subTenant.id}
                  groupId={group.id}
                  patients={group.patients}
                  withDeleted={withDeleted}
                  refetchQueries={refetchQueries}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default TenantPatientsTab;
