import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from "react-router-dom";

import { userType } from 'types';
import { useI18n } from 'hooks/i18n';
import { SET_USER_LOCKED } from 'apollo/operations/user';
import { toast } from 'react-toastify';
import Translate from 'components/common/Translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ToggleUserLock({ user }) {
  let history = useHistory();
  const { t } = useI18n();
  const [setUserLocked, { loading }] = useMutation(SET_USER_LOCKED);

  async function handleWithLocked(event) {
    event.preventDefault();

    const userId = user.id;
    const setLocked = !user.locked;

    const confirmText = setLocked
      ? t('user.confirmLock')
      : t('user.confirmUnlock');

    if (!window.confirm(confirmText)) {
      return;
    }


    try {
      let updatedUser = await setUserLocked({ variables: { userId, isLocked: !user.locked } });
      if (updatedUser.data.setUserLocked.deleted) {
        window.alert(t('user.alertDeleted'))
        history.goBack();
      }
    } catch (err) {
      console.log(err)
      toast.error(t('user.updateUserLockedToast'));
    }
  }

  return (
        <button
        type="button"
        className={`btn btn-primary mt-2 ${user.locked ? 'btn-warning' : 'btn-danger'}`}
        disabled={ loading }
        onClick={handleWithLocked}
        >
            {user.locked ? <><FontAwesomeIcon icon="unlock" style={{marginRight: 10}}/> <Translate text="unlock" /></> : <><FontAwesomeIcon icon="lock" style={{marginRight: 10}}/><Translate text="delete" /> </> }
        </button>
  );
}

ToggleUserLock.propTypes = {
  user: userType.isRequired,
};

export default ToggleUserLock;
