import React from 'react';
import * as R from 'ramda';
import { useParams, useRouteMatch, Switch, Route } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { NEW_PATIENT_DETAIL_PAGE } from 'apollo/operations/patient';
import StandardLayout from 'components/layouts/StandardLayout';
import Spinner from 'components/common/Spinner';
import UpdatePatient from 'components/modules/patient/UpdatePatient';
import Translate from 'components/common/Translate';
import { useI18n } from 'hooks/i18n';
import {
  tenantPatientsRoute,
  patientRoute,
  patientCallHistoryRoute,
} from 'routes';
import PatientContactsTab from './PatientContactsTab';
import PatientCallHistoryTab from './PatientCallHistoryTab';
import NavLink from 'components/common/NavLink';
import PatientSubTenantLinkManager from 'components/modules/patient/PatientSubTenantLinkManager';
import PatientSubTenantList from 'components/modules/patient/PatientSubTenantList';

function PatientDetailPage() {
  const { t } = useI18n();
  const match = useRouteMatch();
const { tenantId, patientId } = useParams();

  const { data, loading } = useQuery(NEW_PATIENT_DETAIL_PAGE, {
    variables: { id: patientId },
  });


  const refetchQueries = [
    { query: NEW_PATIENT_DETAIL_PAGE, variables: { id: patientId } },
  ];

  const patient = R.pathOr(null, ['patient'], data);
  const contacts = R.pathOr([], ['patient', 'contacts'], data);
  const subTenants = R.pathOr([], ['patient', 'contactSubTenants'], data);

  return (
    <StandardLayout
      breadcrumb={[
        [tenantPatientsRoute(tenantId), t('route.patientList')],
        [match.path, t('route.patientDetail')],
      ]}
    >
      <h1>
        <Translate text="route.patientDetail" />
      </h1>

      {loading && <Spinner />}
      {!loading && patient && (
        <div className="row">
          <div className="col">
            <UpdatePatient
              tenantId={tenantId}
              // subTenantId={patient.subTenantId}
              patient={patient}
            />
          </div>
        </div>
      )}

      <ul className="nav nav-tabs mt-3 mb-3">
        <li className="nav-item">
          <NavLink
            to={patientRoute(tenantId, patientId)}
            className="nav-link"
            exact
          >
            <Translate text="contactsLabel" />
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to={patientCallHistoryRoute(tenantId, patientId)}
            className="nav-link"
            exact
          >
            <Translate text="callHistoryLabel" />
          </NavLink>
        </li>
      </ul>

      {!loading && patient && (
        <Switch>
          <Route
            path={`${match.path}/call-history`}
            render={() => <PatientCallHistoryTab patientId={patientId} />}
          />
          <Route
            path={match.path}
            render={() => (
              <PatientContactsTab
                patientId={patientId}
                contacts={contacts}
                refetchQueries={refetchQueries}
              />
            )}
          />
        </Switch>
      )}
    </StandardLayout>
  );
}

export default PatientDetailPage;
