import React, { useState, useEffect, useCallback } from 'react';
import * as R from 'ramda';

import { lang } from 'lang';
import { useAuth } from 'hooks/auth';

export const I18nContext = React.createContext();

const translate = locale => (key, props) => {
  const getText = l => R.path([l, key]);

  // Try to get translation for the selected locale
  let text = getText(locale)(lang);
  // If no translation found, use the default locale (en)
  if (!text) {
    text = getText('EN')(lang);
  }
  if (!text) {
    throw new Error(`Missing translation for: ${key}`);
  }
  if (typeof text !== 'string') {
    console.log('Matched translation:', text);
    throw new TypeError(
      `Matched translation text is '${typeof text}'; string is required.`,
    );
  }

  // Look for any variables present in the translation string and interpolate
  // them with the provided variable map
  const variables = R.pipe(
    R.match(/{{[a-zA-Z0-9_]+}}/g),
    R.map(R.replace(/[{{}}]/g, '')),
  )(text);

  const interpolated = variables.reduce((acc, cur) => {
    const value = R.prop(cur, props);
    if (value) return R.replace(`{{${cur}}}`, value, acc);
    return acc;
  }, text);

  return interpolated;
};

export function I18nContextProvider({ defaultState, children }) {
  const { localAuthority } = useAuth();
  const userLocale = R.pathOr(null, ['defaultLocale'], localAuthority);

  const defaultLocale = R.defaultTo(
    'en',
    window.localStorage.getItem('locale'),
  );

  const [locale, setLocale] = useState(defaultLocale);

  const handleSetLocale = useCallback(value => {
    window.localStorage.setItem('locale', value);
    setLocale(value);
  }, []);

  useEffect(() => {
    if (userLocale && userLocale !== locale) {
      console.log('User profile updated, setting locale to ', userLocale);
      handleSetLocale(userLocale);
    }
  }, [userLocale, locale, handleSetLocale]);

  const translateFn = translate(locale);

  return (
    <I18nContext.Provider
      value={{
        locale,
        setLocale: handleSetLocale,
        translate: translateFn,
        t: translateFn, // Shorthand for translate fn
      }}
    >
      {children}
    </I18nContext.Provider>
  );
}
