import React from 'react';
import * as R from 'ramda';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import * as types from 'types';
import { useModalState } from 'hooks/modal';
import Translate from 'components/common/Translate';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_PATIENT_POSSIBLE_CONTACTS } from 'apollo/operations/patient';
import { Field, Formik, Form } from 'formik';
import FormikField from 'components/common/FormikField';
import { useI18n } from 'hooks/i18n';
import { PATIENT_CONTACT_USER_CREATE } from 'apollo/operations/patientContact';
import { setValidationErrors } from 'utils/forms';
import ErrorBox from 'components/common/ErrorBox';
import { toast } from 'react-toastify';

function AddContactModal({ patientId, refetchQueries, children }) {
  const { t } = useI18n();
  const { show, showModal, hideModal } = useModalState();

  const [
    loadPossibleContacts,
    { data, loading: loadingLoad },
  ] = useLazyQuery(GET_PATIENT_POSSIBLE_CONTACTS, { variables: { patientId } });

  const [
    patientContactUserCreate,
    { loading: loadingCreate, error },
  ] = useMutation(PATIENT_CONTACT_USER_CREATE, { refetchQueries });

  function handleShow(event) {
    if (event) event.preventDefault();

    loadPossibleContacts();
    showModal();
  }

  async function handleSubmit(values, formikBag) {
    try {
      const userId = R.pipe(
        R.prop('userId'),
        parseInt,
        //
      )(values);

      // await patientContactUserCreate({ variables: { patientId: patientId, nextOfKinAdmin: values.nextOfKinAdmin, user: {email: values.email, name: values.name}} });
      await patientContactUserCreate({ variables: { patientId: patientId, nextOfKinAdmin: false, user: {email: values.email, name: values.name}} });
      hideModal();
      toast.success(t('patient.addContactSuccessToast'));
    } catch (err) {
      console.log('Error creating userPatient link', err);
      setValidationErrors(err, formikBag);
      toast.error(t('patient.addContactErrorToast'));
    }
  }

  const loading = loadingLoad || loadingCreate;

  return (
    <>
      {children(handleShow)}

      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate text="patient.addContact" />
          </Modal.Title>
        </Modal.Header>

        <Formik onSubmit={handleSubmit} initialValues={{ email: '', name: '', nextOfKinAdmin: false }}>
          <Form>
            <Modal.Body>
            <Field
              name="name"
              label={t('user.properties.name')}
              component={FormikField}
            />
            <Field
              name="email"
              label={t('user.properties.email')}
              component={FormikField}
              required
            />

            {/* <Field
              name="nextOfKinAdmin"
              label={t('user.properties.nextOfKinAdmin')}
              component={FormikField}
              type="checkbox"
            /> */}

              <ErrorBox graphQLError={error} />
            </Modal.Body>

            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                <Translate text="save" />
              </button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}

AddContactModal.propTypes = {
  /** Modal trigger */
  children: PropTypes.func.isRequired,
  refetchQueries: types.refetchQueriesType.isRequired,
  patientId: types.patientIdType.isRequired,
};

export default AddContactModal;
