import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useMutation } from '@apollo/react-hooks';

import { patientIdType, refetchQueriesType } from 'types';
import Translate from 'components/common/Translate';
import { NEW_SOFT_DELETE_PATIENT } from 'apollo/operations/patient';
import { useI18n } from 'hooks/i18n';
import { toast } from 'react-toastify';

function DeletePatientButton({ patientId, refetchQueries }) {
  const { t } = useI18n();
  const [deletePatient, { loading }] = useMutation(NEW_SOFT_DELETE_PATIENT, {
    variables: { id: patientId },
    refetchQueries,
  });

  async function handleDelete() {
    const confirmText = t('patient.deleteConfirmText');
    if (!window.confirm(confirmText)) return;

    try {
      await deletePatient();
      toast.success(t('patient.deleteSuccessToast'));
    } catch (err) {
      console.log('Error deleting patient:', err);
      toast.error(t('patient.deleteErrorToast'));
    }
  }

  return (
    <Dropdown.Item onClick={handleDelete} disabled={loading}>
      <Translate text="delete" />
    </Dropdown.Item>
  );
}

DeletePatientButton.propTypes = {
  patientId: patientIdType.isRequired,
  refetchQueries: refetchQueriesType.isRequired,
};

export default DeletePatientButton;
