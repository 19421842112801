import React from 'react'
import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago'

import en from 'javascript-time-ago/locale/en.json'
import no from 'javascript-time-ago/locale/no.json'

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(no)


export default function LastSeen({ date, locale }) {
  if (["nb", "no", "nn"].includes(locale.toLowerCase())) {
    return (
      <div>
        <ReactTimeAgo date={date} locale='no'/>
      </div>
    )
  } else {
    return (
      <div>
        <ReactTimeAgo date={date} locale='en'/>
      </div>
    )
  }

}
