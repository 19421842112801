import React from 'react';
import * as R from 'ramda';

import { deviceListRoute } from 'routes';
import StandardLayout from 'components/layouts/StandardLayout';
import UpdateDevice from 'components/modules/device/UpdateDevice';
import UpdateDeviceKiosk from 'components/modules/device/UpdateDeviceKiosk';
import DeviceInfo from 'components/modules/device/DeviceInfo';
import { useRouteMatch } from 'react-router-dom';
import { useI18n } from 'hooks/i18n';
import { useQuery } from '@apollo/react-hooks';
import { NEW_GET_DEVICE } from 'apollo/operations/device';
import Spinner from 'components/common/Spinner';
import { useAuth } from 'hooks/auth';

function DevicePage() {
  const { t } = useI18n();
  const match = useRouteMatch();
  const { id } = match.params;  
  const { hasAnyRole } = useAuth();

  const canKiosk = hasAnyRole(['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN']);

  const { loading, data } = useQuery(NEW_GET_DEVICE, {
    variables: { id },
  });

  const refetchQueries = [
    { query: NEW_GET_DEVICE, variables: { id } },
  ];

  const device = R.prop(['device'], data);


  let breadcrumb = [
    [match.path, t('route.updateDevice')],
  ]

  if (hasAnyRole(['ROLE_ADMIN'])) {
    breadcrumb.unshift([deviceListRoute(), t('route.deviceList')]);
  }

  return (
    <StandardLayout
      breadcrumb={breadcrumb}
    >
      {loading && <Spinner />}
      <div className="row">
        <div className="col">
          {device && <UpdateDevice device={device} refetchQueries={refetchQueries}/>}
          {/* {device && <UpdateDeviceKiosk device={device} />} */}
        </div>
        <div className="col">
          {device && <DeviceInfo device={device} />}
          {canKiosk && device && <UpdateDeviceKiosk device={device} refetchQueries={refetchQueries} />}
        </div>
      </div>
    </StandardLayout>
  );
}

export default DevicePage;
