import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';

import { patientContactType, patientIdType, refetchQueriesType } from 'types';
import { PATIENT_CONTACT_USER_DELETE } from 'apollo/operations/patientContact';
import { useI18n } from 'hooks/i18n';
import { useSortFilter } from 'hooks/data';
import { tenantUserRoute } from 'routes';
import { Link } from 'react-router-dom';
import Translate from 'components/common/Translate';
import SortTrigger from 'components/common/SortTrigger';
import AddContactModal from 'components/modules/patient/AddContactModal';
import TenantResendEmail from 'components/modules/user/TenantResendEmail';

function PatientContactsTab({ patientId, contacts, refetchQueries }) {
  const { t } = useI18n();
  const { items, sortIndicatorProps } = useSortFilter(
    contacts,
    'userName',
    'userName',
  );
  const [
    patientContactUserDelete,
    { loading, error },
  ] = useMutation(PATIENT_CONTACT_USER_DELETE, { refetchQueries });

  async function handleDelete (e, patientId, userId, userName) {
    e.preventDefault();
    if (!window.confirm(t('patient.removeContactConfirm', { userName }))) {
      return;
    }

    try {
      await patientContactUserDelete({
        variables: {
          userId,
          patientId,
        },
      });
      toast.success(t('patient.removeContactSuccessToast'));
    } catch (err) {
      console.log('Error deleting contact', err);
      toast.error(t('patient.removeContactErrorToast'));
    }
  };

  return (
    <>
      <AddContactModal patientId={patientId} refetchQueries={refetchQueries}>
        {showModal => (
          <button
            type="button"
            className="btn btn-primary float-right"
            onClick={showModal}
          >
            <Translate text="patient.addContact" />
          </button>
        )}
      </AddContactModal>
      <div className="clearfix mb-2" />

      <table className="table">
        <thead>
          <tr>
            <th>
              <SortTrigger
                colName="userName"
                sortIndicatorProps={sortIndicatorProps}
              >
                <Translate text="user.properties.name" />
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="email"
                sortIndicatorProps={sortIndicatorProps}
              >
                <Translate text="user.properties.email" />
              </SortTrigger>
            </th>
              <th>
                <Translate text="user.properties.isEmailVerified" />
            </th>
            <th>
                <Translate text="user.properties.type" />
            </th>
            <th>
                <Translate text="patient.removeContact" />
            </th>
            <th />
          </tr>
        </thead>

        <tbody>
          {items.map(contact => (
            <tr key={`patientcontacts-${contact.user.id}`}>
              <td>
                {/* <Link to={tenantUserRoute(contact.tenantId, contact.userId)}> */}
                  {contact.user.name}
                {/* </Link> */}
              </td>
             <td>
               {contact.user.email}
             </td>
              <td>
                {contact.user.isEmailVerified ? <Translate text="active" /> : <TenantResendEmail user={contact.user} customRole={"NEXT_OF_KIN"}/>}
              </td>
              <td>
               {contact.nextOfKinAdmin ? <Translate text="user.properties.nextOfKin" />  : ""}
             </td>
              <td>
                <a href="#delete" onClick={(e) => {handleDelete(e, patientId, contact.user.id, contact.user.name )}}>
                  ❌
              </a>
             </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

PatientContactsTab.propTypes = {
  patientId: patientIdType.isRequired,
  contacts: PropTypes.arrayOf(patientContactType).isRequired,
  refetchQueries: refetchQueriesType.isRequired,
};

export default PatientContactsTab;
