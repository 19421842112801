import React, { useEffect } from 'react';
import * as R from 'ramda';
import { useLocation, withRouter, Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import { useAuth } from 'hooks/auth';
import {
  homeRoute,
  // registerRoute,
  loginRoute,
  requestPasswordResetRoute,
} from 'routes';
import StandardLayout from 'components/layouts/StandardLayout';
// import LoginForm from 'components/modules/auth/LoginForm';
import Translate from 'components/common/Translate';
import { useI18n } from 'hooks/i18n';
import { NEW_CREATE_AUTHORITY } from 'apollo/operations/auth';
import LoginButton from 'components/modules/auth/LoginButton';

function LoginPage() {
  const { t } = useI18n();
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const {data, loading} = useQuery(NEW_CREATE_AUTHORITY, {
    variables: { redirectTo: '/' },
  });

  if (isAuthenticated) {
    const redirectTo = R.pathOr(
      homeRoute(),
      ['state', 'postLoginRedirect'],
      location,
    );
    return <Redirect to={redirectTo} />;
  }

  return (
    <StandardLayout breadcrumb={[[loginRoute(), t('auth.loginHeading')]]}>
      <div className="row justify-content-center">
        <div className="col-6 text-center ">
          {/* <LoginForm /> */}
          {
            !loading && 
            <LoginButton auth0Url={data?.loginUrl?.value}/>
          }
        </div>
      </div>
    </StandardLayout>
  );
}

LoginPage.propTypes = {
  location: PropTypes.object,
};

export default withRouter(LoginPage);
