import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SortIndicator({ sort, sortReverse, colName }) {
  if (sort !== colName) {
    return null;
  }

  if (sortReverse) {
    return <FontAwesomeIcon icon="chevron-down" />;
  } else {
    return <FontAwesomeIcon icon="chevron-up" />;
  }
}

SortIndicator.defaultProps = {
  sort: '',
  sortReverse: false,
  colName: '',
};

SortIndicator.propTypes = {
  sort: PropTypes.string,
  sortReverse: PropTypes.bool,
  colName: PropTypes.string,
};

export default SortIndicator;
