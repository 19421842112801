import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronDown,
  faChevronUp,
  faPencilAlt,
  faDownload,
  faQuestionCircle,
  faSpinner,
  faSquare,
  faCheckSquare,
  faTrashAlt,
  faArrowRight,
  faTimes,
  faPlusCircle,
  faExclamationCircle,
  faCrown,
  faMinusCircle,
  faHome,
  faLock,
  faUnlock,
} from '@fortawesome/free-solid-svg-icons';
// import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(
  faSpinner,
  faSquare,
  faCheckSquare,
  faTrashAlt,
  faChevronDown,
  faChevronUp,
  faPencilAlt,
  faDownload,
  faQuestionCircle,
  faArrowRight,
  faTimes,
  faPlusCircle,
  faMinusCircle,
  faExclamationCircle,
  faCrown,
  faHome,
  faLock,
  faUnlock,
);
