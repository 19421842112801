import * as R from 'ramda';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import fetch from 'unfetch';

import { GRAPHQL_URL } from 'config/constants';
import { getAuthToken } from 'utils/auth';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

const uploadLink = createUploadLink({
  uri: `${GRAPHQL_URL}`,
  credentials: 'include',
  fetch,
});

const authLink = setContext((_, { headers }) => {
  const token = getAuthToken();
  if (!token) return { headers };
  return R.assocPath(['headers', 'X-Auth-Token'], token, { headers });
});

const cache = new InMemoryCache();

const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache,
  defaultOptions,
  resolvers: {},
});

export default client;
