import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './index.css';

const Spinner = ({ show, className, ...props }) =>
  show ? <div className={classnames('spinner', className)} {...props} /> : null;

Spinner.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
};

Spinner.defaultProps = {
  show: true,
};

export default Spinner;
