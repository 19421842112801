import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Table as BSTable } from 'react-bootstrap';

import { userType, tenantIdType, subTenantType } from 'types';
import { useSortFilter } from 'hooks/data';
import SortTrigger from 'components/common/SortTrigger';
import Translate from 'components/common/Translate';
import TenantResendEmail from 'components/modules/user/TenantResendEmail';
import RemoveSubTenantUser from 'components/modules/user/RemoveSubTenantUser';
import { Link } from 'react-router-dom';
import { tenantUserRoute } from 'routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function TenantUserListTable({ tenantId, subTenant, userList, refetchQueries }) {
  const { items, sortIndicatorProps } = useSortFilter(userList, 'name', 'name');

  const subTenantAdminIds = subTenant.admins.map(admin => admin.id);
  const isSubTenantAdmin = user => subTenantAdminIds.includes(user.id);

  return (
    <BSTable bordered hover>
      <thead>
        <tr>
          <th>
            <SortTrigger colName="name" sortIndicatorProps={sortIndicatorProps}>
              <Translate text="user.properties.name" />
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="email"
              sortIndicatorProps={sortIndicatorProps}
            >
              <Translate text="user.properties.email" />
            </SortTrigger>
          </th>
          <th>
            <SortTrigger colName="id" sortIndicatorProps={sortIndicatorProps}>
              <Translate text="user.properties.id" />
            </SortTrigger>
          </th>
          <th className="text-center">
            <SortTrigger
              colName="isEmailVerified"
              sortIndicatorProps={sortIndicatorProps}
            >
              <Translate text="user.properties.isEmailVerified" />
            </SortTrigger>
          </th>
          <th>
              <Translate text="user.properties.delete" />
          </th>
          <th>
              <Translate text="user.properties.isAdmin" />
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map(user => {
          return (
            <tr key={user.id}>
              <td>
                  {user.name}
              </td>
              <td>{user.email}</td>
              <td>{user.id}</td>
              <td className="text-center">
                {user.isEmailVerified ? <Translate text="active" /> : <TenantResendEmail user={user}/>}
              </td>
              <td className="text-center">
                <RemoveSubTenantUser userId={user.id} subTenantId={subTenant.id} refetchQueries={refetchQueries}/>
              </td>
              <td className="text-center">
                {isSubTenantAdmin(user) && '✔'
                }
              </td>
            </tr>
          );
        })}
      </tbody>
    </BSTable>
  );
}

TenantUserListTable.propTypes = {
  tenantId: tenantIdType.isRequired,
  subTenant: subTenantType.isRequired,
  userList: PropTypes.arrayOf(userType).isRequired,
};

export default TenantUserListTable;
