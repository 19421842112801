import React from 'react';
import * as R from 'ramda';
import { Modal } from 'react-bootstrap';
import { useMutation } from '@apollo/react-hooks';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import * as types from 'types';
import { useI18n } from 'hooks/i18n';
import { useModalState } from 'hooks/modal';
import { NEW_CREATE_PARTNER_USER } from 'apollo/operations/user';
import { validationSchema, defaultUser } from 'utils/modules/user';
import { setValidationErrors } from 'utils/forms';
import Translate from 'components/common/Translate';
import ErrorBox from 'components/common/ErrorBox';
import UserFormFields from 'components/modules/user/UserFormFields';

function PartnerCreateUser({ partnerId, refetchQueries, children }) {
  const { t } = useI18n();
  const { show, showModal, hideModal } = useModalState();
  const [createPartnerUser, { data, loading, error }] = useMutation(
    NEW_CREATE_PARTNER_USER,
    {
      variables: {
        partnerId,
      },
      refetchQueries,
    },
  );

  async function handleSubmit(values, formikBag) {
    try {
      await createPartnerUser({ variables: { user: values } });
      toast.success(t('user.createPartnerUserSuccessToast'));
    } catch (err) {
      setValidationErrors(err, formikBag);
      toast.error(t('user.createPartnerUserErrorToast'));
    }
  }

  const fieldLabels = {
    name: t('user.properties.name'),
    displayName: t('user.properties.displayName'),
    email: t('user.properties.email'),
    defaultLocale: t('user.properties.defaultLocale'),
  };

  const mutationSuccess = R.path(['createPartnerUser', 'success'], data);

  const successMessage = (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">
          <Translate text="user.createPartnerUserSuccessHeading" />
        </h5>
        <p>
          <Translate text="user.createPartnerUserSuccessText" />
        </p>
        {/* <p className="text-center">
          <strong>{createdUserEmail}</strong>
        </p> */}
      </div>
    </div>
  );

  return (
    <>
      {children(showModal)}

      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate text="user.createButtonText" />
          </Modal.Title>
        </Modal.Header>

        <Formik
          onSubmit={handleSubmit}
          initialValues={defaultUser()}
          validationSchema={validationSchema(fieldLabels)}
        >
          <Form>
            <Modal.Body>
              {mutationSuccess ? (
                successMessage
              ) : (
                <>
                  <UserFormFields />
                  <ErrorBox graphQLError={error} />
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-light"
                onClick={hideModal}
              >
                {data ? (
                  <Translate text="close" />
                ) : (
                  <Translate text="cancel" />
                )}
              </button>

              {!data && (
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  <Translate text="user.createButtonText" />
                </button>
              )}
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}

PartnerCreateUser.propTypes = {
  partnerId: types.partnerIdType.isRequired,
  refetchQueries: types.refetchQueriesType.isRequired,
  children: PropTypes.func.isRequired,
};

export default PartnerCreateUser;
