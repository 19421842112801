import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from "react-router-dom";
import { OverlayTrigger, Tooltip, Popover as BSPopover } from 'react-bootstrap';

import { userType } from 'types';
import { useI18n } from 'hooks/i18n';
import { NEW_SUB_TENANT_USER_REMOVE } from 'apollo/operations/user';
import { toast } from 'react-toastify';
import Translate from 'components/common/Translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function RemoveSubTenantUser({ userId, subTenantId, refetchQueries }) {
  const { t } = useI18n();
  const [removeSubTenantUser, { loading }] = useMutation(NEW_SUB_TENANT_USER_REMOVE, {
    refetchQueries
  });

  async function handleRemove(event) {
    event.preventDefault();

    if (!window.confirm(t('user.confirmRemoveUserFromSubTenant'))) {
      return;
    }

    try {
      let updatedUser = await removeSubTenantUser({ variables: { userId, subTenantId} });
      if (updatedUser.data.sucesss) {
        window.alert(t('removedUserSuccessToast'))
      }
    } catch (err) {
      console.log(err)
      toast.error(t('user.removedUserFromSubTenantFailedToast'));
    }
  }

  return (
    <a href="#delete" onClick={handleRemove}>
      {loading ? <FontAwesomeIcon icon="spinner" /> : "❌"}
    </a>
  );
}


export default RemoveSubTenantUser;
