import React from 'react';
import * as R from 'ramda';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { NEW_TENANT_DEVICE_PAGE } from 'apollo/operations/tenant';
import Spinner from 'components/common/Spinner';
import TenantDeviceList from 'components/modules/device/TenantDeviceList';
import { useI18n } from 'hooks/i18n';
import { useAuth } from 'hooks/auth';

function TenantDevicesTab() {
  const { params } = useRouteMatch();
  const { tenantId } = params;
  const { t } = useI18n();
  const { hasAnyRole } = useAuth();
  const { loading, data } = useQuery(NEW_TENANT_DEVICE_PAGE, {
    variables: { id: tenantId },
  });

  const isSubTenantAdmin = hasAnyRole(['ROLE_SUB_TENANT_ADMIN']);

  const devices = R.pipe(
    R.pathOr(null, ['tenant', 'devices']),
    R.defaultTo([]),
  )(data);

  const subTenants = R.pipe(
    R.pathOr(null, ['tenant', 'subTenants']),
    R.defaultTo([]),
  )(data);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {subTenants.map(subTenant => (
        <div key={subTenant.id} className="card mt-3">
          <div className="card-header">{subTenant.name}</div>
          <TenantDeviceList
            tenantId={tenantId}
            subTenant={subTenant}
            deviceList={devices}
          />
        </div>
      ))}
      {!isSubTenantAdmin && <div key={'no-subtenant'} className="card mt-3">
          <div className="card-header">{t('device.noArea')}</div>
            <TenantDeviceList
              tenantId={tenantId}
              noSubTenant={true}
              deviceList={devices}
            />
      </div>}
    </>
  );
}

TenantDevicesTab.propTypes = {};

export default TenantDevicesTab;
