import React from 'react';
import { FastField } from 'formik';
import PropTypes from 'prop-types';

import { defaultKioskModes } from 'utils/modules/device';
import FormikField from 'components/common/FormikField';

function KioskSelect(props) {
  return (
    <FastField
      component={FormikField}
      type="select"
      options={defaultKioskModes}
      {...props}
    />
  );
}

KioskSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
};

KioskSelect.defaultProps = {
  label: 'Kiosk Mode',
  required: false,
};

export default KioskSelect;
