import React, { useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import * as R from 'ramda';

import StandardLayout from 'components/layouts/StandardLayout';
import { tenantDevicesRoute, tenantDeviceRoute } from 'routes';
import { useI18n } from 'hooks/i18n';
import { EDIT_DEVICE_PAGE } from 'apollo/operations/device';
import Spinner from 'components/common/Spinner';
import UpdateDevice from 'components/modules/device/UpdateDevice';
import NotFound from 'components/errors/NotFound';

function TenantDevicePage() {
  const { t } = useI18n();
  const match = useRouteMatch();
  const { push: routerPush } = useHistory();
  const { tenantId, deviceId } = match.params;

  const { data, loading, error } = useQuery(EDIT_DEVICE_PAGE, {
    variables: { id: deviceId },
  });

  const device = R.path(['getDevice'], data);
  const deviceTenantId = R.path(['tenantId'], device);

  useEffect(() => {
    if (!tenantId || !deviceTenantId) {
      // Device not loaded yet
      return;
    }

    // Redirect to the new tenant ID set by the form if it is changed
    if (tenantId !== deviceTenantId) {
      routerPush(tenantDeviceRoute(deviceTenantId, deviceId));
    }
  }, [tenantId, deviceTenantId, routerPush, deviceId]);

  const refetchQueries = [];

  return (
    <StandardLayout
      breadcrumb={[
        [tenantDevicesRoute(tenantId), t('route.deviceList')],
        [match.path, t('route.updateDevice')],
      ]}
    >
      {loading && <Spinner />}
      {!loading && device && (
        <UpdateDevice device={device} refetchQueries={refetchQueries} />
      )}
      {(error || (!loading && !device)) && <NotFound />}
    </StandardLayout>
  );
}

TenantDevicePage.propTypes = {};

export default TenantDevicePage;
