import React from 'react';

import { tenantCreateRoute } from 'routes';
import StandardLayout from 'components/layouts/StandardLayout';
import CreateTenant from 'components/modules/tenant/CreateTenant';
import { useI18n } from 'hooks/i18n';

function TenantsPage() {
  const { t } = useI18n();

  return (
    <StandardLayout
      breadcrumb={[[tenantCreateRoute(), t('route.createTenant')]]}
    >
      <CreateTenant />
    </StandardLayout>
  );
}

export default TenantsPage;
