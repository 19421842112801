import React from 'react';
import classnames from 'classnames';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

function FormikCheckboxField({ field, className, label, ...props }) {
  return (
    <div className={classnames('checkbox', className)}>
      <label>
        <input {...field} {...props} type="checkbox" /> {label}
      </label>
      <div className="text-danger">
        <ErrorMessage name={field.name} />
      </div>
    </div>
  );
}

FormikCheckboxField.propTypes = {
  // form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  className: PropTypes.string,
  label: PropTypes.node,
};

FormikCheckboxField.defaultProps = {
  className: null,
  label: null,
};

export default FormikCheckboxField;
