import React from 'react';
import PropTypes from 'prop-types';

import SortIndicator from 'components/common/SortIndicator';

function SortTrigger({ colName, sortIndicatorProps, disabled, children }) {
  function handleClick() {
    sortIndicatorProps.onSortChange(colName);
  }
  if (disabled) {
    return (
      <button
      type="button"
      className="btn btn-link"
      style={{ margin: 0, padding: 0, cursor: "default", textDecoration: "none" }}
    >
      {children}
    </button>
    )
  }

  return (
    <button
      type="button"
      className="btn btn-link"
      onClick={handleClick}
      style={{ margin: 0, padding: 0 }}
    >
      {children} <SortIndicator {...sortIndicatorProps} colName={colName} />
    </button>
  );
}

SortTrigger.propTypes = {
  colName: PropTypes.string.isRequired,
  sortIndicatorProps: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default SortTrigger;
