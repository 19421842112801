import React from 'react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import * as types from 'types';
import { setValidationErrors } from 'utils/forms';
import { CREATE_GROUP, NEW_CREATE_GROUP } from 'apollo/operations/group';
import GroupFormFields from './GroupFormFields';
import { useI18n } from 'hooks/i18n';
import Translate from 'components/common/Translate';
import { Modal } from 'react-bootstrap';
import { useModalState } from 'hooks/modal';

function CreateGroup({ subTenantId, refetchQueries, children }) {
  const { t } = useI18n();
  const { show, showModal, hideModal } = useModalState();
  const [createGroup, { loading }] = useMutation(NEW_CREATE_GROUP, {
    refetchQueries,
  });

  async function handleSubmit(values, formikBag) {
    try {
      await createGroup({
        variables: {
          subTenantId,
          group: { name: values.group.name },
        },
      });
      toast.success(t('group.createSuccessToast'));
      hideModal();
    } catch (err) {
      console.log('error creating group:', err);
      setValidationErrors(err, formikBag);
      toast.error(t('group.createErrorToast'));
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate text="group.createLabel" />
          </Modal.Title>
        </Modal.Header>

        <Formik onSubmit={handleSubmit} initialValues={{ group: { name: '' } }}>
          <Form>
            <Modal.Body>
              <GroupFormFields />
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                <Translate text="group.createLabel" />
              </button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}

CreateGroup.propTypes = {
  subTenantId: types.subTenantIdType.isRequired,
  refetchQueries: types.refetchQueriesType.isRequired,
  children: PropTypes.func.isRequired,
};

export default CreateGroup;
