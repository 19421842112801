import React from 'react';
import * as R from 'ramda';
import { ErrorMessage, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { useI18n } from 'hooks/i18n';
import Translate from '../Translate';

function FormikErrors({ graphQLError }) {
  const { submitCount, errors } = useFormikContext();
  const { t } = useI18n();

  const hasBeenSubmitted = submitCount > 0;
  const hasErrors = Object.keys(errors).length > 0;

  const is400 =
    // !R.anyPass([R.isEmpty, R.isNil], graphQLError) &&
    R.pipe(R.path(['networkError', 'statusCode']), R.equals(400))(graphQLError);

  if (!hasBeenSubmitted) return null;
  if (!is400 && !hasErrors) return null;

  const errorText = t('graphQLGenericError');
  const error400Message = t('graphQLBadRequest');

  return (
    <div className="alert alert-danger">
      <h4>
        <Translate text="error" />
      </h4>
      <p>
        {errorText}
        {is400 && (
          <>
            <br />
            {error400Message}
          </>
        )}
      </p>

      <ul>
        {Object.keys(errors).map(field => (
          <ErrorMessage key={field} name={field}>
            {message => <li key={field}>{message}</li>}
          </ErrorMessage>
        ))}
      </ul>
    </div>
  );
}

FormikErrors.propTypes = {
  graphQLError: PropTypes.object,
};

FormikErrors.defaultProps = {
  graphQLError: null,
};

export default FormikErrors;
