import gql from 'graphql-tag';

import { SubTenantParts, NewSubTenantParts } from 'apollo/fragments';

export const CREATE_SUBTENANT = gql`
  mutation CreateSubTenant($tenantId: String!, $subTenant: SubTenantInput!) {
    createSubTenant(tenantId: $tenantId, subTenant: $subTenant) {
      ...SubTenantParts
    }
  }

  ${SubTenantParts}
`;

export const UPDATE_SUBTENANT = gql`
  mutation UpdateSubTenant($subTenantId: ID!, $subTenant: SubTenantInput!) {
    updateSubTenant(subTenantId: $subTenantId, subTenant: $subTenant) {
      ...SubTenantParts
    }
  }

  ${SubTenantParts}
`;

export const GRANT_SUB_TENANT_ADMIN = gql`
  mutation GrantSubTenantAdmin($subTenantId: ID!, $userId: Int!) {
    grantSubTenantAdmin(subTenantId: $subTenantId, userId: $userId) {
      ...SubTenantParts
    }
  }

  ${SubTenantParts}
`;

export const REVOKE_SUB_TENANT_ADMIN = gql`
  mutation RevokeSubTenantAdmin($subTenantId: ID!, $userId: Int!) {
    revokeSubTenantAdmin(subTenantId: $subTenantId, userId: $userId) {
      ...SubTenantParts
    }
  }

  ${SubTenantParts}
`;


export const NEW_CREATE_SUBTENANT = gql`
  mutation SubtenantCreate($tenantId: UUID4!, $subTenant: SubTenantInput!) {
    subTenantCreate(tenantId: $tenantId, subTenant: $subTenant) {
      ...NewSubTenantParts
    }
  }

  ${NewSubTenantParts}
`;

export const NEW_UPDATE_SUBTENANT = gql`
  mutation SubTenantUpdate($id: UUID4!, $subTenant: SubTenantInput!) {
    subTenantUpdate(id: $id, subTenant: $subTenant) {
      ...NewSubTenantParts
    }
  }

  ${NewSubTenantParts}
`;

export const NEW_SUBTENANT_USER_ADMIN_CREATE = gql`
  mutation SubTenantUserAdminCreate($subTenantId: UUID4!, $user: UserInput!) {
    subTenantUserAdminCreate(subTenantId: $subTenantId, user: $user) {
      message
      success
    }
  }
`;

export const NEW_SUBTENANT_USER_ADMIN_DELETE = gql`
  mutation SubTenantUserAdminDelete($subTenantId: UUID4!, $userId: UUID4!) {
    subTenantUserAdminDelete(subTenantId: $subTenantId, userId: $userId) {
      message
      success
    }
  }
`;

export const NEW_SUBTENANT_SOFT_DELETE = gql`
  mutation SubTenantSoftDelete($id: UUID4!) {
    subTenantSoftDelete(id: $id) {
      ...NewSubTenantParts
    }
  }

  ${NewSubTenantParts}
`;
