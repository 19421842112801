import PropTypes from 'prop-types';

export function tuple(typeCheckers) {
  return PropTypes.arrayOf(function(value, index, ...rest) {
    return typeCheckers[index].call(PropTypes, value, index, ...rest);
  });
}

export const breadcrumbType = PropTypes.arrayOf(
  tuple([PropTypes.string, PropTypes.string]),
);

export const refetchQueriesType = PropTypes.arrayOf(
  PropTypes.shape({
    query: PropTypes.any.isRequired,
    variables: PropTypes.object,
  }),
);

export const userIdType = PropTypes.string; // uuid
export const userUuidType = PropTypes.string;
export const userType = PropTypes.shape({
  id: userIdType,
  auth0Id: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string), // ['admin', 'user', ...]
  isEnabled: PropTypes.bool,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});

export const tenantIdType = PropTypes.string; // uuid
export const tenantLocaleType = PropTypes.string; // uuid
export const tenantType = PropTypes.shape({
  id: tenantIdType,
  // ... placeholder for other properties
});

export const deviceIdType = PropTypes.string; // uuid
export const deviceDeviceIdType = PropTypes.number; // integer for android device reference
export const deviceType = PropTypes.shape({
  id: deviceIdType,
  deviceId: deviceDeviceIdType,
  tenantId: tenantIdType,
  deviceKey: PropTypes.string,
  // googleDeviceToken // updated at timestamp field?
});

export const patientIdType = PropTypes.string; // uuid
export const patientType = PropTypes.shape({
  id: patientIdType,
  name: PropTypes.string,
  deviceId: deviceIdType, // fkey to device uuid
  device: deviceType,
});

export const subTenantIdType = PropTypes.string; // uuid
export const subTenantType = PropTypes.shape({
  id: PropTypes.string,
  tenantId: tenantIdType, // fkey to tenant
  name: PropTypes.string,
});

export const groupIdType = PropTypes.string; // uuid
export const groupType = PropTypes.shape({
  id: groupIdType,
  tenantId: tenantIdType,
  subTenantId: subTenantIdType,
  name: PropTypes.string,
});

export const patientContactType = PropTypes.shape({
  userId: userIdType,
  userName: PropTypes.string,
  source: PropTypes.oneOf(['direct', 'subTenant']),
  groupName: PropTypes.string,
  tenantId: tenantIdType,
  tenantName: PropTypes.string,
});

export const patientSubTenantLinkIdType = PropTypes.number;
export const patientSubTenantLinkType = PropTypes.shape({
  id: patientSubTenantLinkIdType.isRequired,
  patientId: patientIdType.isRequired,
  subTenantId: subTenantIdType.isRequired,
  createdById: userIdType.isRequired,
  createdAt: PropTypes.string.isRequired,
  patient: patientType,
  subTenant: subTenantType,
  createdBy: userType,
});

export const partnerIdType = PropTypes.string; // uuid
export const partnerType = PropTypes.shape({
  id: partnerIdType,
  // ... placeholder for other properties
});