import gql from 'graphql-tag';
import { UserPatientParts } from 'apollo/fragments';

export const PATIENT_CONTACT_USER_CREATE = gql`
  mutation PatientContactUserCreate($patientId: UUID4!, $user: UserInput!, $nextOfKinAdmin: Boolean!) {
    patientContactUserCreate(patientId: $patientId, user: $user, nextOfKinAdmin: $nextOfKinAdmin) {
      message
      success
    }
  }
`;

export const PATIENT_CONTACT_USER_DELETE = gql`
  mutation PatientContactUserDelete($patientId: UUID4!, $userId: UUID4!) {
    patientContactUserDelete(patientId: $patientId, userId: $userId) {
      message
      success
    }
  }
`;

export const DELETE_USER_PATIENT_LINK = gql`
  mutation DeleteUserPatientLink($userId: Int!, $patientId: String!) {
    deleteUserPatientLink(userId: $userId, patientId: $patientId) {
      success
      message
    }
  }
`;
