import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import * as types from 'types';
import { useModalState } from 'hooks/modal';
import Translate from 'components/common/Translate';
import { Formik, Form, Field } from 'formik';
import FormikField from 'components/common/FormikField';
import { useI18n } from 'hooks/i18n';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_GROUP, NEW_UPDATE_GROUP } from 'apollo/operations/group';
import { toast } from 'react-toastify';
import { setValidationErrors } from 'utils/forms';
import ErrorBox from 'components/common/ErrorBox';

function UpdateGroupModal({ groupId, groupName, refetchQueries, children }) {
  const { t } = useI18n();
  const { show, showModal, hideModal } = useModalState();

  const [updateGroup, { loading, error }] = useMutation(NEW_UPDATE_GROUP, {
    refetchQueries,
  });

  async function handleSubmit(values, formikBag) {
    try {
      await updateGroup({
        variables: {
          id: groupId,
          group: { name: values.name },
        },
      });
      toast.success(t('group.updateSuccessToast'));
      hideModal();
    } catch (err) {
      console.log('Error updating subTenant ', err);
      toast.error(t('group.updateErrorToast'));
      setValidationErrors(err, formikBag);
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate text="group.updateGroupLabel" />
          </Modal.Title>
        </Modal.Header>

        <Formik onSubmit={handleSubmit} initialValues={{ name: groupName }}>
          <Form>
            <Modal.Body>
              <Field
                name="name"
                label={t('group.properties.name')}
                component={FormikField}
                required
              />

              <ErrorBox graphQLError={error} />
            </Modal.Body>

            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                <Translate text="save" />
              </button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}

UpdateGroupModal.propTypes = {
  groupId: types.groupIdType.isRequired,
  groupName: PropTypes.string.isRequired,
};

export default UpdateGroupModal;
