import React from 'react';
import { Field } from 'formik';

import { useI18n } from 'hooks/i18n';
import FormikField from 'components/common/FormikField';
import * as types from 'types';
import UnassignedDeviceSelect from 'components/modules/device/UnassignedDeviceSelect';
import LocaleSelect from 'components/modules/locale/LocaleSelect';

function PatientFormFields({ tenantId, subTenantId, patientId }) {
  const { t } = useI18n();

  return (
    <>
      <Field
        name="patient.name"
        label={t('patient.properties.name')}
        component={FormikField}
      />

      <LocaleSelect
        name="patient.locale"
        label={t('patient.properties.locale')}
      />

      {patientId && (
        <UnassignedDeviceSelect
          tenantId={tenantId}
          subTenantId={subTenantId}
          patientId={patientId}
          name="patient.device.id"
        />
      )}
    </>
  );
}

PatientFormFields.propTypes = {
  /** Pass down the tenantId for fetching the list of devices */
  tenantId: types.tenantIdType.isRequired,
  subTenantId: types.subTenantIdType.isRequired,
  /** patientId indicates that this is an update action */
  patientId: types.patientIdType,
};

PatientFormFields.defaultProps = {
  patientId: null,
};

export default PatientFormFields;
