import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { userType } from 'types';
import { useI18n } from 'hooks/i18n';
import { NEW_RESEND_USER_EMAIL } from 'apollo/operations/user';
import { toast } from 'react-toastify';
import { useAuth } from 'hooks/auth';

function ResendUserEmail({ user, customRole }) {
  const { t } = useI18n();
  const [resendEmail, { loading }] = useMutation(NEW_RESEND_USER_EMAIL);
  const [sent, setSent] = useState(false)
  const { hasAnyRole } = useAuth();
  
  function getRole() {
    if (customRole == 'NEXT_OF_KIN') {
      return 'NEXT_OF_KIN'
    }
    if (hasAnyRole(['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN'])) {
      return 'PARTNER_ADMIN';
    }
  
    if (hasAnyRole(['ROLE_TENANT_ADMIN'])) {
      return 'TENANT_ADMIN';
    }

    if (hasAnyRole(['ROLE_SUB_TENANT_ADMIN'])) {
      return 'SUB_TENANT_ADMIN';
    }
    return ''
  }

  async function handleClick(event) {
    event.preventDefault();

    const userId = user.id;
    const role = getRole();

    if (role == '') {
      toast.error(t('user.alertInvalidRole'));
      return
    } 
  
    try {
      await resendEmail({ variables: { userId, context: role } });
      setSent(true)
      toast.success(t('user.sendEmailSuccessToast'));
    } catch (err) {
      toast.error(t('user.sendEmailErrorToast'));
    }
  }

  return (
    <>
        <span
            className={`btn btn-link`}
            disabled={ loading || sent }
            style={{padding: 0, margin: 0}}
            onClick={handleClick}
            >
                {t('user.resendEmail')}
        </span>

    </>
  );
}

ResendUserEmail.propTypes = {
  user: userType.isRequired
};

export default ResendUserEmail;
