import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SecuredRoute from 'components/common/SecuredRoute';

import HomePage from 'pages/HomePage';
import NotFoundPage from 'pages/NotFoundPage';
import LoginPage from 'pages/LoginPage';
import RegisterPage from 'pages/RegisterPage';
// import RequestPasswordResetPage from 'pages/RequestPasswordResetPage';
// import ConfirmPasswordResetPage from 'pages/ConfirmPasswordResetPage';
import ProfilePage from 'pages/ProfilePage';
import VerifyEmailPage from 'pages/VerifyEmailPage';
import TenantListPage from 'pages/TenantListPage';
import TenantPage from 'pages/TenantPage';
import TenantCreatePage from 'pages/TenantCreatePage';
import TenantUpdatePage from 'pages/TenantUpdatePage';
import NextOfKinPatientsListPage from 'pages/NextOfKinPatientsListPage';
import DevicePage from 'pages/DevicePage';
import PatientDetailPage from 'pages/PatientDetailPage';
import DeviceListPage from 'pages/DeviceListPage';
import VerifyTenantUserEmailPage from 'pages/VerifyTenantUserEmailPage';
import UserDetailPage from 'pages/UserDetailPage';
import TenantDevicePage from 'pages/TenantDevicePage';
import PartnerListPage from 'pages/PartnerListPage';
import PartnerCreatePage from 'pages/PartnerCreatePage';
import PartnerDetailPage from 'pages/PartnerDetailPage';
import PartnerTenantCreatePage from 'pages/PartnerTenantCreatePage';

const Routes = () => (
  <Switch>
    <SecuredRoute path="/" exact component={HomePage} />
    <Route path="/login" component={LoginPage} />
    <Route path="/register" component={RegisterPage} />
    <Route path="/verify-email/:token" component={VerifyEmailPage} />
    <Route
      path="/verify-tenant-user-email/:token"
      component={VerifyTenantUserEmailPage}
    />
    {/* <Route path="/password-reset" exact component={RequestPasswordResetPage} />
    <Route path="/password-reset/:token" component={ConfirmPasswordResetPage} /> */}
    <SecuredRoute path="/profile/:userId" component={ProfilePage} />

    <SecuredRoute
      path="/next-of-kin/:userId/patients"
      component={NextOfKinPatientsListPage}
      roles={['ROLE_ADMIN']}
    />

    <SecuredRoute
      path="/tenants"
      exact
      component={TenantListPage}
      roles={['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN']}
    />
    <SecuredRoute
      path="/tenants/create"
      component={TenantCreatePage}
      roles={['ROLE_ADMIN']}
    />
    <SecuredRoute
      path="/tenants/:tenantId/update"
      component={TenantUpdatePage}
      roles={['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN', 'ROLE_TENANT_ADMIN']}
    />
    <SecuredRoute
      path="/tenants/:tenantId/users/:userId"
      exact
      component={UserDetailPage}
      roles={['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN', 'ROLE_TENANT_ADMIN', 'ROLE_SUB_TENANT_ADMIN']}
    />
    <SecuredRoute
      path="/tenants/:tenantId/patients/:patientId"
      component={PatientDetailPage}
      roles={['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN', 'ROLE_TENANT_ADMIN', 'ROLE_SUB_TENANT_ADMIN']}
    />
    <SecuredRoute
      path="/tenants/:tenantId/devices/:deviceId"
      exact
      component={TenantDevicePage}
      roles={['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN', 'ROLE_TENANT_ADMIN', 'ROLE_SUB_TENANT_ADMIN']}
    />
    <SecuredRoute
      path="/tenants/:tenantId"
      component={TenantPage}
      roles={['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN', 'ROLE_TENANT_ADMIN', 'ROLE_SUB_TENANT_ADMIN']}
    />


    <SecuredRoute
      path="/devices"
      exact
      component={DeviceListPage}
      roles={['ROLE_ADMIN']}
    />

    <SecuredRoute path="/device/:id" component={DevicePage} roles={['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN', 'ROLE_TENANT_ADMIN', 'ROLE_SUB_TENANT_ADMIN']} />
    <SecuredRoute path="/partner/:partnerId/device/:id" component={DevicePage} roles={['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN']} />

    <SecuredRoute
      path="/partners/create"
      component={PartnerCreatePage}
      roles={['ROLE_ADMIN']}
    />
    <SecuredRoute
      path="/partners"
      exact
      component={PartnerListPage}
      roles={['ROLE_ADMIN']}
    />
    
    <SecuredRoute
      path="/partner/:partnerId/tenants/create"
      component={PartnerTenantCreatePage}
      roles={['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN']}
    />
    <SecuredRoute path="/partner/:partnerId" component={PartnerDetailPage} roles={['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN']} />


    <Route path="/not-found" component={NotFoundPage} />
    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;

export const homeRoute = () => '/';
export const aboutRoute = () => `${homeRoute()}#about`;

// Auth
export const loginRoute = () => '/login';

// User
export const registerRoute = () => '/register';
export const verifyEmailRoute = token => `/verify-email/${token || ''}`;
export const verifyTenantUserEmailRoute = token =>
  `/verify-tenant-user-email/${token || ''}`;
// export const requestPasswordResetRoute = () => '/password-reset';
// export const confirmPasswordResetRoute = token =>
//   `${requestPasswordResetRoute()}/${token || ''}`;
export const profileRoute = (userId) => `/profile/${userId}`;

// Devices
export const deviceListRoute = () => '/devices';
export const deviceRoute = deviceId => `/device/${deviceId}`;

// Partners
export const partnerListRoute = () => '/partners';
export const partnerCreateRoute = () => '/partners/create';
export const partnerRoute = partnerId => `/partner/${partnerId}`;
export const partnerTenantCreateRoute = partnerId => `${partnerRoute(partnerId)}/tenants/create`;
export const partnerDeviceRoute = (partnerId, deviceId) => `${partnerRoute(partnerId)}/device/${deviceId}`;

// Tenant
export const tenantListRoute = () => '/tenants';
export const tenantCreateRoute = () => '/tenants/create';
export const tenantUpdateRoute = tenantId => `/tenants/${tenantId}/update`;
export const tenantRoute = tenantId => `/tenants/${tenantId}`;
export const tenantSetupRoute = tenantId => `${tenantRoute(tenantId)}/setup`;
export const tenantPatientsRoute = tenantId =>
  `${tenantRoute(tenantId)}/patient`;
export const tenantUsersRoute = tenantId => `${tenantRoute(tenantId)}/users`;
export const tenantUserRoute = (tenantId, userId) =>
  `${tenantUsersRoute(tenantId)}/${userId}`;
export const tenantCreateUserRoute = tenantId =>
  `${tenantUsersRoute(tenantId)}/create`;
export const tenantNextOfKinRoute = tenantId =>
  `${tenantRoute(tenantId)}/next-of-kin`;
export const tenantDevicesRoute = tenantId =>
  `${tenantRoute(tenantId)}/devices`;
export const tenantDeviceRoute = (tenantId, deviceId) =>
  `${tenantDevicesRoute(tenantId)}/${deviceId}`;
export const createPatientRoute = tenantId =>
  `/tenants/${tenantId}/patient/create`;

//Next Of kin
export const nextOfKinPatientsListRoute = userId =>
  `/next-of-kin/${userId || ''}/patients`;

// Patient
export const patientRoute = (tenantId, patientId) =>
  `${tenantRoute(tenantId)}/patients/${patientId}`;
export const patientCallHistoryRoute = (tenantId, patientId) =>
  `${tenantRoute(tenantId)}/patients/${patientId}/call-history`;

// Error pages
export const notFoundRoute = () => '/not-found';
