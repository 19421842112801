import React from 'react';
import * as R from 'ramda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { userType, refetchQueriesType } from 'types';
import Translate from 'components/common/Translate';
import Tooltip from 'components/common/Tooltip';
import {
  GRANT_SUB_TENANT_ADMIN,
  REVOKE_SUB_TENANT_ADMIN,
  NEW_SUBTENANT_USER_ADMIN_CREATE,
  NEW_SUBTENANT_USER_ADMIN_DELETE,
} from 'apollo/operations/subTenant';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useI18n } from 'hooks/i18n';
import { Spinner } from 'react-bootstrap';

function UserSubTenantAdminManager({ user, refetchQueries }) {
  const { t } = useI18n();
  const [
    grantSubTenantAdmin,
    { loading: loadingGrant },
  ] = useMutation(NEW_SUBTENANT_USER_ADMIN_CREATE, { refetchQueries });
  const [
    revokeSubTenantAdmin,
    { loading: loadingRevoke },
  ] = useMutation(NEW_SUBTENANT_USER_ADMIN_DELETE, { refetchQueries });

  const subTenants = [R.path(['subTenant'], user)];

  const adminSubTenantIds = user =>
  R.pipe(
    R.pathOr({}, ['subTenant']),
    R.pathOr([], ['admins']),
    R.map(R.prop('id')),
  )(user);
  console.log(adminSubTenantIds(user))

  const isSubTenantAdmin = subTenant =>
    R.pipe(
      R.prop('id'),
      R.contains(R.__, adminSubTenantIds),
      //
    )(subTenant);

  const loading = loadingGrant || loadingRevoke;

  const handleGrant = subTenant => async () => {
    try {
      await grantSubTenantAdmin({
        variables: {
          subTenantId: subTenant.id,
          user: {email: user.email, name: user.name},
        },
      });
      toast.success(
        t('user.grantAreaAdminSuccessToast', { subTenantName: subTenant.name }),
      );
    } catch (err) {
      console.log('Error setting sub tenant admin', err);
      toast.error(t('user.grantAreaAdminErrorToast'));
    }
  };

  const handleRevoke = subTenant => async () => {
    try {
      await revokeSubTenantAdmin({
        variables: {
          subTenantId: subTenant.id,
          userId: user.id,
        },
      });
      toast.success(
        t('user.revokeAreaAdminSuccessToast', {
          subTenantName: subTenant.name,
        }),
      );
    } catch (err) {
      console.log('Error revoking sub tenant admin', err);
      toast.error(t('user.revokeAreaAdminErrorToast'));
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <Translate text="user.areaAdminLabel" />
      </div>

      <div className="card-body">
        {subTenants.map(subTenant => (
          subTenant && <>
          <div key={subTenant.id} className="row mb-2">
            <div className="col-sm-6">{subTenant.name}</div>

            <div className="col-sm-4">
              {isSubTenantAdmin(subTenant) && (
                <div className="badge badge-primary">
                  <FontAwesomeIcon icon="crown" />{' '}
                  <Translate text="user.areaAdminLabel" />
                </div>
              )}
            </div>

            <div className="col-sm-2 text-right">
              {loading && <Spinner />}
              {!loading && (
                <button
                  type="button"
                  className="btn btn-link"
                  style={{ padding: 0 }}
                  onClick={
                    isSubTenantAdmin(subTenant)
                      ? handleRevoke(subTenant)
                      : handleGrant(subTenant)
                  }
                  disabled={loading}
                >
                  {isSubTenantAdmin(subTenant) ? (
                    <Tooltip
                      overlay={<Translate text="user.revokeAreaAdminTooltip" />}
                    >
                      <span className="text-danger">
                        <FontAwesomeIcon
                          icon="minus-circle"
                          style={{ marginRight: '3px' }}
                        />
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      overlay={<Translate text="user.grantAreaAdminTooltip" />}
                    >
                      <span className="text-primary">
                        <FontAwesomeIcon icon="crown" />
                      </span>
                    </Tooltip>
                  )}
                </button>
              )}
            </div>
          </div>
          </>
        ))}
      </div>
    </div>
  );
}

UserSubTenantAdminManager.propTypes = {
  user: userType.isRequired,
  refetchQueries: refetchQueriesType.isRequired,
};

export default UserSubTenantAdminManager;
