import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { partnerTenantCreateRoute } from 'routes';
import StandardLayout from 'components/layouts/StandardLayout';
import CreatePartnerTenant from 'components/modules/tenant/CreatePartnerTenant';
import { useI18n } from 'hooks/i18n';

function PartnerTenantCreatePage() {
  const { t } = useI18n();
  const match = useRouteMatch();
  const partnerId = match.params.partnerId;
  
  return (
    <StandardLayout
      breadcrumb={[[partnerTenantCreateRoute(partnerId), t('route.createTenant')]]}
    >
      <CreatePartnerTenant partnerId={partnerId} />
    </StandardLayout>
  );
}

export default PartnerTenantCreatePage;
