import { yup } from 'utils/validation';

export const defaultLocales = [
  { value: 'NB', label: 'Norwegian' },
  { value: 'EN', label: 'English' },
];

export const defaultUser = () => ({
  email: '',
  name: '',
  displayName: '',
  defaultLocale: 'NB',
});

export const validationSchema = labels =>
  yup.object({
    email: yup
      .string()
      .label(labels.email)
      .required(),
    name: yup
      .string()
      .label(labels.name)
      .required(),
    displayName: yup
      .string()
      .label(labels.displayName)
      .nullable(),
    defaultLocale: yup
      .string()
      .label(labels.defaultLocale)
      .oneOf(defaultLocales.map(l => l.value))
      .required(),
  });
