import gql from 'graphql-tag';

import {
  TenantParts,
  PatientParts,
  GroupParts,
  DeviceParts,
  SubTenantParts,
  UserParts,
  NewTenantParts,
  NewUserParts,
  NewSubTenantParts,
  NewPublicUserParts,
  NewGroupParts,
  NewPatientParts,
  NewDeviceParts
} from 'apollo/fragments';

export const GET_TENANTS = gql`
  query GetTenants {
    getTenants {
      ...TenantParts
    }
  }

  ${TenantParts}
`;

export const CREATE_TENANT = gql`
  mutation CreateTenant($tenant: TenantInput!) {
    createTenant(tenant: $tenant) {
      ...TenantParts
    }
  }

  ${TenantParts}
`;

export const UPDATE_TENANT = gql`
  mutation UpdateTenant(
    $id: String!
    $name: String!
    $enforceApplicationSecurity: Boolean!
  ) {
    updateTenant(
      id: $id
      name: $name
      enforceApplicationSecurity: $enforceApplicationSecurity
    ) {
      ...TenantParts
    }
  }

  ${TenantParts}
`;

export const UPDATE_TENANT_LOCALE = gql`
  mutation UpdateTenantLocale(
    $id: String!
    $locale: Locale!
  ) {
    updateTenantLocale(
      id: $id
      locale: $locale
    ) {
      ...TenantParts
    }
  }

  ${TenantParts}
`;

export const GET_NEXT_OF_KIN_PATIENTS = gql`
  query GetNextOfKinPatients($userId: Int!) {
    getNextOfKinPatients(userId: $userId) {
      ...PatientParts
      tenant {
        id
        name
      }
    }
  }

  ${PatientParts}
`;

export const TENANT_SETUP_PAGE = gql`
  query TenantSetupPage($tenantId: String!) {
    tenant(tenantId: $tenantId) {
      ...TenantParts
      users {
        ...UserParts
      }
      subTenants {
        ...SubTenantParts
        admins {
          ...UserParts
        }
        groups {
          ...GroupParts
        }
      }
    }
  }

  ${TenantParts}
  ${SubTenantParts}
  ${GroupParts}
  ${UserParts}
`;

export const TENANT_PATIENT_LIST_PAGE = gql`
  query TenantPatientListPage($tenantId: String!, $withDeleted: Boolean) {
    tenant(tenantId: $tenantId) {
      ...TenantParts
      subTenants {
        ...SubTenantParts
        groups {
          ...GroupParts
          patients(withDeleted: $withDeleted) {
            ...PatientParts
            device {
              ...DeviceParts
            }
          }
        }
      }
    }
  }

  ${TenantParts}
  ${PatientParts}
  ${GroupParts}
  ${DeviceParts}
  ${SubTenantParts}
`;

export const TENANT_USER_LIST_PAGE = gql`
  query TenantUserListPage($tenantId: String!, $withLocked: Boolean) {
    tenant(tenantId: $tenantId) {
      ...TenantParts
      subTenants {
        ...SubTenantParts
        users(withLocked: $withLocked) {
          ...UserParts
          subTenantAdmin {
            ...SubTenantParts
          }
        }
      }
    }
  }

  ${TenantParts}
  ${SubTenantParts}
  ${UserParts}
`;

export const TENANT_DEVICES_PAGE = gql`
  query TenantDevicesPage($tenantId: String!) {
    tenant(tenantId: $tenantId) {
      ...TenantParts
      subTenants {
        ...SubTenantParts
        devices {
          ...DeviceParts
          patient {
            ...PatientParts
          }
        }
      }
    }
  }

  ${TenantParts}
  ${SubTenantParts}
  ${DeviceParts}
  ${PatientParts}
`;

export const GET_TENANT_SUB_TENANTS = gql`
  query GetTenantSubTenants($tenantId: String!) {
    tenant(tenantId: $tenantId) {
      ...TenantParts
      subTenants {
        ...SubTenantParts
      }
    }
  }

  ${TenantParts}
  ${SubTenantParts}
`;

export const NEW_GET_TENANTS = gql`
  query TenantList {
    tenantList {
      ...NewTenantParts
    }
  }

  ${NewTenantParts}
`;

export const NEW_GET_TENANT = gql`
  query TenantList {
    tenantList {
      ...NewTenantParts
    }
  }

  ${NewTenantParts}
`;

export const NEW_TENANT_SETUP_PAGE = gql`
  query NewTenantSetupPage($id: UUID4!) {
    tenant(id: $id) {
      ...NewTenantParts
      admins {
        ...NewPublicUserParts
      }
      subTenants {
        ...NewSubTenantParts
        admins {
          ...NewPublicUserParts
        }
        users {
          ...NewUserParts
        }
        groups {
          ...NewGroupParts
          patients {
            ...NewPatientParts
          }
        }
      }
    }
  }

  ${NewTenantParts}
  ${NewPublicUserParts}
  ${NewSubTenantParts}
  ${NewUserParts}
  ${NewGroupParts}
  ${NewPatientParts}
`;

export const NEW_TENANT_USER_LIST_PAGE = gql`
  query NewTenantUserListPage($id: UUID4!) {
    tenant(id: $id) {
      ...NewTenantParts
      admins {
        ...NewPublicUserParts
      }
      subTenants {
        ...NewSubTenantParts
        admins {
          ...NewPublicUserParts
        }
        users {
          ...NewUserParts
        }
      }
    }
  }

  ${NewTenantParts}
  ${NewSubTenantParts}
  ${NewUserParts}
  ${NewPublicUserParts}
`;

export const NEW_UPDATE_TENANT = gql`
  mutation TenantUpdate(
    $id: UUID4!
    $tenant: TenantInput!
  ) {
    tenantUpdate(
      id: $id
      tenant: $tenant
    ) {
      ...NewTenantParts
    }
  }

  ${NewTenantParts}
`;

export const NEW_CREATE_PARTNER_TENANT = gql`
  mutation TenantCreate(
    $partnerId: UUID4!,
    $tenant: TenantInput!

  ) {
    tenantCreate(
      partnerId: $partnerId,
      tenant: $tenant
    ) {
      ...NewTenantParts
    }
  }

  ${NewTenantParts}
`;

export const NEW_REMOVE_PARTNER_TENANT = gql`
  mutation PartnerTenantRemove(
    $partnerId: UUID4!,
    $tenantId: UUID4!

  ) {
    PartnerTenantRemove(
      partnerId: $partnerId,
      tenantId: $tenantId
    ) {
      success
      message
    }
  }
`;

export const NEW_TENANT_PATIENT_LIST_PAGE = gql`
  query NewTenantPatientListPage($id: UUID4!, $includeDeleted: Boolean) {
    tenant(id: $id) {
      ...NewTenantParts
      subTenants {
        ...NewSubTenantParts
        groups {
          ...NewGroupParts
          patients(includeDeleted: $includeDeleted) {
            ...NewPatientParts
            device {
              ...NewDeviceParts
            }

          }
        }
      }
    }
  }

  ${NewTenantParts}
  ${NewSubTenantParts}
  ${NewGroupParts}
  ${NewPatientParts}
  ${NewDeviceParts}
`;

// uncomment above and delete below after patients
// export const NEW_TENANT_PATIENT_LIST_PAGE = gql`
//   query NewTenantPatientListPage($id: UUID4!) {
//     tenant(id: $id) {
//       ...NewTenantParts
//       subTenants {
//         ...NewSubTenantParts
//         groups {
//           ...NewGroupParts
//         }
//       }
//     }
//   }

//   ${NewTenantParts}
//   ${NewSubTenantParts}
//   ${NewGroupParts}
// `;

// export const NEW_TENANT_DEVICE_PAGE = gql`
//   query NewTenantPatientListPage($id: UUID4!) {
//     tenant(id: $id) {
//       ...NewTenantParts
//       subTenants {
//         ...NewSubTenantParts
//         groups {
//           ...NewGroupParts
//           patients {
//             ...NewPatientParts
//             device {
//               ...NewDeviceParts
//             }
//           }
//         }
//       }
//     }
//   }

//   ${NewTenantParts}
//   ${NewSubTenantParts}
//   ${NewGroupParts}
//   ${NewPatientParts}
//   ${NewDeviceParts}
// `;

export const NEW_TENANT_DEVICE_PAGE = gql`
  query NewTenantPatientListPage($id: UUID4!) {
    tenant(id: $id) {
      ...NewTenantParts
      subTenants {
        ...NewSubTenantParts
      }
      devices {
        ...NewDeviceParts
        patient {
          ...NewPatientParts
        }
        subTenant {
          ...NewSubTenantParts
        }
      }
    }
  }

  ${NewTenantParts}
  ${NewDeviceParts}
  ${NewPatientParts}
  ${NewSubTenantParts}
`;

export const NEW_GET_TENANT_SUB_TENANTS = gql`
  query NewGetTenantSubTenants($id: UUID4!) {
    tenant(id: $id) {
      ...NewTenantParts
      subTenants {
        ...NewSubTenantParts
      }
    }
  }

  ${NewTenantParts}
  ${NewSubTenantParts}
`;

export const NEW_CREATE_TENANT = gql`
  mutation TenantCreate($tenant: TenantInput!) {
    tenantCreate(tenant: $tenant) {
      ...NewTenantParts
    }
  }

  ${NewTenantParts}
`;