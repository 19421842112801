import React from 'react';
import classnames from 'classnames';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

import FormLabel from './FormLabel';
import { useI18n } from 'hooks/i18n';
import Translate from '../Translate';

function FormikSelectField({
  field,
  onChange,
  className,
  isInvalid,
  label,
  options,
  showBlankOption,
  blankOptionLabel,
  renderInput,
  renderOption,
  helpText,
  multiple,
  required,
  ...props
}) {
  const { t } = useI18n();

  const defaultOptionRenderer = option => (
    <option
      key={option.value}
      value={option.value}
      disabled={option.disabled || false}
    >
      {option.label}
    </option>
  );

  const blankText = blankOptionLabel || t('blankOptionLabel');

  const input = (
    <select
      {...field}
      {...props}
      id={field.name}
      // Use onChange fn if provided through props
      value={field.value || ''}
      onChange={onChange || field.onChange}
      className={classnames(className, 'form-control')}
      multiple={multiple}
    >
      {showBlankOption && !multiple && <option value="">{blankText}</option>}
      {options.map(renderOption ? renderOption : defaultOptionRenderer)}
    </select>
  );

  return (
    <div
      className={classnames('form-group', {
        'has-error': isInvalid,
        'text-danger': isInvalid,
      })}
    >
      <FormLabel
        name={field.name}
        label={label}
        helpText={helpText}
        required={required}
      />
      {renderInput ? (
        renderInput(input)
      ) : (
        <>
          {input}
          {multiple && (
            <div>
              <small className="text-muted">
                <Translate text="selectMultipleInstructions" />
              </small>
            </div>
          )}
        </>
      )}
      <ErrorMessage name={field.name} />
    </div>
  );
}

FormikSelectField.propTypes = {
  // form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  /** onChange can be set to override the default change handler */
  onChange: PropTypes.func,
  className: PropTypes.string,
  isInvalid: PropTypes.bool.isRequired,
  label: PropTypes.node,
  renderInput: PropTypes.func,
  renderOption: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  showBlankOption: PropTypes.bool,
  blankOptionLabel: PropTypes.string,
  helpText: PropTypes.node,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
};

FormikSelectField.defaultProps = {
  onChange: null,
  className: null,
  label: null,
  renderInput: null,
  renderOption: null,
  showBlankOption: true,
  blankOptionLabel: null,
  helpText: null,
  multiple: false,
  required: false,
};

export default FormikSelectField;
