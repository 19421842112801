import React from 'react';
import { Link } from 'react-router-dom';

import * as routes from 'routes';
import NavLink from 'components/common/NavLink';
import CurrentUser from './CurrentUser';
import RoleSecured from 'components/common/RoleSecured';
import Translate from 'components/common/Translate';

function Navbar() {
  return (
    <div className="navbar navbar-dark bg-primary navbar-expand-md">
      <div className="container">
        <Link to={routes.homeRoute()} className="navbar-brand">
          Blink Provisioning
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <RoleSecured roles={['ROLE_ADMIN']}>
            <li className="nav-item">
                <NavLink
                  to={routes.tenantListRoute()}
                  exact
                  className="nav-link"
                >
                  <Translate text="navbar.tenantListLink" />
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to={routes.partnerListRoute()}
                  exact
                  className="nav-link"
                >
                  <Translate text="navbar.partnerListLink" />
                </NavLink>
              </li>


              <li className="nav-item">
                <NavLink
                  to={routes.deviceListRoute()}
                  exact
                  className="nav-link"
                >
                  <Translate text="navbar.adminDevicesLink" />
                </NavLink>
              </li>
            </RoleSecured>
          </ul>

          <ul className="navbar-nav">
            {/* <RoleSecured roles={['admin']}>
              <li className="nav-item">
                <NavLink to={routes.adminRoute()} className="nav-link">
                  Admin
                </NavLink>
              </li>
            </RoleSecured> */}

            <CurrentUser />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
