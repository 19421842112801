import React from 'react';
import { Field } from 'formik';

import { useI18n } from 'hooks/i18n';
import FormikField from 'components/common/FormikField';
import { defaultLocales } from 'utils/modules/user';

function UserFormFields({disableEmail}) {
  const { t } = useI18n();

  return (
    <>
      <Field
        name="email"
        label={t('user.properties.email')}
        component={FormikField}
        type="email"
        required
        disabled={disableEmail}
      />
      <Field
        name="name"
        label={t('user.properties.name')}
        component={FormikField}
        required
      />
      <Field
        name="displayName"
        label={t('user.properties.displayName')}
        component={FormikField}
      />
      <Field
        name="defaultLocale"
        label={t('user.properties.defaultLocale')}
        component={FormikField}
        type="select"
        options={defaultLocales}
        required
      />
    </>
  );
}

export default UserFormFields;
