import React from 'react';
import * as R from 'ramda';
import classnames from 'classnames';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

import FormLabel from './FormLabel';

const OptionWrapper = ({ inline, children }) =>
  inline ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <div className="radio">{children}</div>
  );

function FormikRadioField({
  form,
  field,
  className,
  label,
  helpText,
  required,
  options,
  inline,
  ...props
}) {
  const handleChange = value => () => {
    form.setFieldValue(field.name, value);
  };

  const fieldPath = R.pipe(R.prop('name'), R.split('.'), R.path)(field);
  const currentValue = R.pipe(R.prop('values'), fieldPath)(form);

  return (
    <>
      <div>
        <FormLabel label={label} helpText={helpText} required={required} />
      </div>

      {options.map((option, i) => (
        <OptionWrapper key={i} inline={inline}>
          <label className={classnames({ 'radio-inline': inline })}>
            <input
              {...field}
              {...props}
              type="radio"
              value={option.value}
              onChange={handleChange(option.value)}
              checked={option.value === currentValue}
            />{' '}
            {option.text}
          </label>
        </OptionWrapper>
      ))}

      <div className="text-danger">
        <ErrorMessage name={field.name} />
      </div>
    </>
  );
}

FormikRadioField.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  className: PropTypes.string,
  label: PropTypes.node,
  helpText: PropTypes.node,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      text: PropTypes.node.isRequired,
    }),
  ).isRequired,
  inline: PropTypes.bool,
};

FormikRadioField.defaultProps = {
  className: null,
  label: null,
  helpText: null,
  required: false,
  inline: false,
};

export default FormikRadioField;
