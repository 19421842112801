import React from 'react';
import moment from 'moment';
import * as R from 'ramda';

import { patientIdType } from 'types';
import { useSortFilter } from 'hooks/data';
import Translate from 'components/common/Translate';
import SortTrigger from 'components/common/SortTrigger';
import { useQuery } from '@apollo/react-hooks';
import { NEW_PATIENT_CALL_HISTORY } from 'apollo/operations/patient'
import Spinner from 'components/common/Spinner';
import DateTime from 'components/common/DateTime';
import { useAuth } from 'hooks/auth';

function PatientCallHistoryTab({ patientId }) {
  const { loading, data } = useQuery(NEW_PATIENT_CALL_HISTORY, { variables: { id: patientId } });
  const { hasAnyRole } = useAuth();

  const isAdmin = hasAnyRole(['admin']);

  const calls = R.path(['patient', 'calls'], data);

  const callHistoryList = loading ? [] : calls.map((call) => {
    return {
      userName: call.user.name,
      callId: call.id,
      callStart: call.callStart || call.insertedAt,
      callEnd: call.callEnd,
      callDuration: call.callLength
    }
  })

  const { items, sortIndicatorProps } = useSortFilter(
    callHistoryList,
    'callStart',
    'callStart',
    true
  );

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="clearfix mb-2" />
      <table className="table">
        <thead>
          <tr>
            <th>
              <SortTrigger
                colName="userName"
                sortIndicatorProps={sortIndicatorProps}
              >
                <Translate text="user.label" />
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="callStart"
                sortIndicatorProps={sortIndicatorProps}
              >
                <Translate text="call.callStart" />
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="callEnd"
                sortIndicatorProps={sortIndicatorProps}
              >
                <Translate text="call.callEnd" />
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="callDuration"
                sortIndicatorProps={sortIndicatorProps}
              >
                <Translate text="call.callDuration" />
                (<Translate text="call.minutes" />)
              </SortTrigger>
            </th>
            <th />
          </tr>
        </thead>

        <tbody>
          {items.map(callHistory => (
            <tr key={`${callHistory.callId}`}>
              <td>
                {callHistory.userName}
              </td>
              <td>{<DateTime date={callHistory.callStart} timezone={tz} />}</td>
              <td>{callHistory.callDuration ? <DateTime date={callHistory.callEnd} timezone={tz} /> : <Translate text="call.missedCall" />}</td>
              {/* <td>{callHistory.alertReceivedAt ? <DateTime date={callHistory.alertReceivedAt} /> : 'n/a'}</td> */}
              <td>{Math.round(callHistory.callDuration / 60, -1)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

PatientCallHistoryTab.propTypes = {
  patientId: patientIdType.isRequired,
};

export default PatientCallHistoryTab;
