import React from 'react';
import * as R from 'ramda';
import { useQuery } from '@apollo/react-hooks';
import { Table as BSTable } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { NEW_GET_PARTNERS } from 'apollo/operations/partner';
import Translate from 'components/common/Translate';

function PartnerList() {
  const { loading, data } = useQuery(NEW_GET_PARTNERS);

  if (loading) {
    return <div>Loading...</div>;
  }
  
  const partnerList = R.pipe(
    R.pathOr(null, ['partnerList']),
    R.defaultTo([]),
    R.sortBy(R.prop('name')),
  )(data);

  return (
    <BSTable striped bordered hover>
      <thead>
        <tr>
          <th>
            <Translate text="partner.properties.name" />
          </th>
          <th>
            <Translate text="partner.properties.id" />
          </th>
          <th>
            <Translate text="edit" />
          </th>
        </tr>
      </thead>
      <tbody>
        {partnerList.map(partner => {
          return (
            <tr key={partner.id}>
              <td>
                <Link to={'/partner/' + partner.id}>{partner.name}</Link>
              </td>
              <td>{partner.id}</td>
              <td>
                <Link to={'/partner/' + partner.id }><Translate text="edit" /></Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </BSTable>
  );
}

export default PartnerList;
