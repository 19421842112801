import gql from 'graphql-tag';

export const UserTokenParts = gql`
  fragment UserTokenParts on UserToken {
    token
    isValid
    expiresAt
  }
`;

export const UserParts = gql`
  fragment UserParts on User {
    id
    name
    displayName
    email
    isEnabled
    isEmailVerified
    isAdmin
    isTenantAdmin
    createdAt
    updatedAt
    roles
    type
    defaultLocale
    tenantId
    subTenantId
    locked
    deleted
  }
`;

export const TenantParts = gql`
  fragment TenantParts on Tenant {
    id
    name
    locale
    enforceApplicationSecurity
    provisioningIdleLogoutSeconds
  }
`;

export const SubTenantParts = gql`
  fragment SubTenantParts on SubTenant {
    id
    name
    tenantId
  }
`;

export const GroupParts = gql`
  fragment GroupParts on Group {
    id
    name
    tenantId
    subTenantId
  }
`;

export const PatientParts = gql`
  fragment PatientParts on Patient {
    id
    name
    tenantId
    subTenantId
    locale
    deleted
  }
`;

export const PatientContactParts = gql`
  fragment PatientContactParts on PatientContact {
    userId
    userName
    source
    subTenantName
    tenantId
    tenantName
  }
`;

export const DeviceParts = gql`
  fragment DeviceParts on Device {
    id
    tenantId
    subTenantId
    name
    locale
    deviceId
    deviceKey
    googleDeviceToken
    tokenLastUpdatedAt
    lastSeen
    currentVersion
  }
`;

export const UserPatientParts = gql`
  fragment UserPatientParts on UserPatient {
    id
    type
    userId
    patientId
    approved
    favorite
  }
`;

export const CallParticipantParts = gql`
  fragment CallParticipantParts on CallParticipants {
    id
    endedAt
    joinedAt
    alertReceivedAt
  }
`;

export const CallParts = gql`
  fragment CallParts on Call {
    id
    endedAt
    createdAt
    group
    opentokSessionId
  }
`;

export const PatientSubTenantLinkParts = gql`
  fragment PatientSubTenantLinkParts on PatientSubTenantLink {
    id
    patientId
    subTenantId
    createdById
    createdAt
  }
`;

export const NewTenantParts = gql`
  fragment NewTenantParts on Tenant {
    aadTenantId
    enforceApplicationSecurity
    googleWorkTenantId
    healthCheckTimeMinutes
    id
    locale
    name
    provisioningIdleLogoutSeconds
  }
`;

// accountLocked, isInvited
export const NewUserParts = gql`
  fragment NewUserParts on User {
    id
    name
    displayName
    email
    isEmailVerified
    insertedAt
    defaultLocale
    roles
  }
`;

export const NewPublicUserParts = gql`
  fragment NewPublicUserParts on PublicUser {
    id
    name
    email
    isEmailVerified
  }
`;

export const NewSubTenantParts = gql`
  fragment NewSubTenantParts on SubTenant {
    id
    name
    deletedAt
  }
`;

export const NewGroupParts = gql`
  fragment NewGroupParts on Group {
    id
    name
    deleted_at
  }
`;

export const NewPatientParts = gql`
  fragment NewPatientParts on Patient {
    id
    name
    locale
    deleted
  }
`;

export const NewDeviceParts = gql`
  fragment NewDeviceParts on Device {
    currentVersion
    deviceEasyId
    id
    kioskMode
    lastSeen
    lastSeenBattery
    locale
    mdmProvisioningStatus
    mdmQrCode
    googleTokenLastUpdated
  }
`;

export const NewPatientContactParts = gql`
  fragment NewPatientContactParts on PatientContact {
    nextOfKinAdmin
  }
`;

export const NewPatientCallParts = gql`
  fragment NewPatientCallParts on DirectPatientCall  {
    callEnd
    callLength
    id
    callStart
    status
    insertedAt
  }
`;

export const NewPartnerParts = gql`
fragment NewPartnerParts on Partner {
  id
  name
}

`