import React, { useEffect, useRef } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { useLazyQuery } from '@apollo/react-hooks';

import { NEW_GET_TENANT_SUB_TENANTS } from 'apollo/operations/tenant';
import FormikField from 'components/common/FormikField';
import { tenantIdType, subTenantIdType } from 'types';

function SubTenantSelectField({
  tenantId,
  name,
  label,
  disabled,
  filter,
  ...props
}) {
  const [getSubTenants, { data, loading }] = useLazyQuery(
    NEW_GET_TENANT_SUB_TENANTS,
    {
      variables: { id: tenantId },
    },
  );
  const tenantIdRef = useRef(null);

  useEffect(() => {
    // Reload when tenantId changes
    if (tenantId !== tenantIdRef.current) {
      getSubTenants();
    }
    tenantIdRef.current = tenantId;
  }, [tenantId, getSubTenants]);

  const tenant = R.path(['tenant'], data);

  const subTenants = !tenant
    ? []
    : R.pipe(
        R.path(['subTenants']),
        R.defaultTo([]),
        // Remove items from the 'filter' array of ids if set
        R.filter(
          R.pipe(
            R.prop('id'),
            R.complement(R.contains(R.__, filter)),
            //
          ),
        ),
        R.sortBy(
          R.pipe(
            R.prop('name'),
            R.toLower,
            //
          ),
        ),
      )(tenant);

  const options = subTenants.map(st => ({
    value: st.id,
    label: st.name,
  }));

  return (
    <Field
      {...props}
      name={name}
      label={label}
      component={FormikField}
      type="select"
      disabled={disabled || loading}
      options={options}
    />
  );
}

SubTenantSelectField.propTypes = {
  tenantId: tenantIdType,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  /** Array of sub tenant ids to hide from the list */
  filter: PropTypes.arrayOf(subTenantIdType),
};

SubTenantSelectField.defaultProps = {
  tenantId: null,
  disabled: false,
  filter: [],
};

export default SubTenantSelectField;
