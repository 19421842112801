import React from 'react';
import { withRouter, Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth } from 'hooks/auth';

const SecuredRoute = ({ roles, blockedRoles, location, ...props }) => {
  const { isAuthenticated, hasAnyRole } = useAuth();

  if (!isAuthenticated) {
    // If not authenticated, redirect to the login page.
    // The current route will be saved in the router's `state` prop so
    // it can be used for redirect after a successful login attempt.
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            postLoginRedirect: {
              pathname: location.pathname,
              search: location.search,
            },
          },
        }}
      />
    );
  }

  const hasAllowedRole = hasAnyRole(roles);
  const hasBlockedRole = hasAnyRole(blockedRoles);
  // Allow access if the role list provided was empty; they just need to be logged in
  const isAllowed = (hasAllowedRole || !roles.length) && !hasBlockedRole;
  if (!isAllowed) {
    return <Redirect to="/forbidden" />;
  }

  return <Route {...props} />;
};

SecuredRoute.propTypes = {
  /** List of roles that are ALLOWED access to the route */
  roles: PropTypes.arrayOf(PropTypes.string),
  /** List of roles that are BLOCKED from accessing the route. Overrides allowed roles. */
  blockedRoles: PropTypes.arrayOf(PropTypes.string),
  /** Location object injected by react-router */
  location: PropTypes.object.isRequired,
};

SecuredRoute.defaultProps = {
  userRoles: [],
  roles: [],
  blockedRoles: [],
};

export default withRouter(SecuredRoute);
