import PropTypes from 'prop-types';

import { useI18n } from 'hooks/i18n';

function Translate({ text, variables }) {
  const { translate } = useI18n();

  return translate(text, variables);
}

Translate.propTypes = {
  text: PropTypes.string.isRequired,
  variables: PropTypes.object,
};

Translate.defaultProps = {
  variables: null,
};

export default Translate;
