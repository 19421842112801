import React, { useState } from 'react';
import * as R from 'ramda';
import { useQuery } from '@apollo/react-hooks';
import { useRouteMatch } from 'react-router-dom';

import { TENANT_USER_LIST_PAGE, NEW_TENANT_USER_LIST_PAGE } from 'apollo/operations/tenant';
import Translate from 'components/common/Translate';
import Spinner from 'components/common/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TenantUserListTable from 'components/modules/user/TenantUserListTable';
import TenantCreateUser from 'components/modules/user/TenantCreateUser';

function TenantUsersTab() {
  const { params } = useRouteMatch();
  const { tenantId } = params;
  const [ withLocked, setWithLocked ] = useState(false);
  const { loading, data } = useQuery(NEW_TENANT_USER_LIST_PAGE, {
    variables: { id: tenantId },
  });

  const refetchQueries = [
    { query: NEW_TENANT_USER_LIST_PAGE, variables: { id: tenantId } },
  ];

  const handleWithLocked = (event) => {
    console.log(event.target.checked)
    setWithLocked(event.target.checked)
  }

  const subTenants = R.pipe(
    R.pathOr(null, ['tenant', 'subTenants']),
    R.defaultTo([]),
  )(data);

  const subTenantUsers = R.pipe(
    R.pathOr(null, ['users']),
    R.defaultTo([]),
  );

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
    {
      subTenants.length == 0 &&
        <div className="alert alert-warning">
          <FontAwesomeIcon icon="exclamation-circle" />{' '}
          <Translate text="tenant.noSubTenantWarning" />
        </div>
    }
      {subTenants.map(subTenant => (
        <div key={subTenant.id} className="card mt-3">
          <div className="card-body">
            <TenantCreateUser
              tenantId={tenantId}
              subTenantId={subTenant.id}
              refetchQueries={refetchQueries}
            >
              {showModal => (
                <button
                  type="button"
                  className="btn btn-sm btn-primary float-right"
                  onClick={showModal}
                >
                  <Translate text="user.createUserButtonText" />
                </button>
              )}
            </TenantCreateUser>

            <h3 className="card-title">{subTenant.name}</h3>

            <TenantUserListTable
              tenantId={tenantId}
              subTenant={subTenant}
              userList={subTenantUsers(subTenant)}
              withLocked={withLocked}
              refetchQueries={refetchQueries}
            />
          </div>
        </div>
      ))}
    </>
  );
}

export default TenantUsersTab;
