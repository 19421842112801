import React from 'react';
import * as R from 'ramda';
import { useQuery } from '@apollo/react-hooks';
import { Table as BSTable } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Translate from 'components/common/Translate';

function PatientContactList({contacts}) {


  return (
    <div className="card">
    <div className="card-header">
      <Translate text="patientContact.directLabel" />
    </div>
    <div className="card-body">
    <BSTable striped bordered hover>
      <thead>
        <tr>
          <th>
            <Translate text="patient.properties.name" />
          </th>
        </tr>
      </thead>
      <tbody>
        {contacts.map(contact => {
            return (
              <tr key={contact.patient?.id}>
              <td>
                {contact.patient?.name}
              </td>
              </tr>
          )
        })}
      </tbody>
    </BSTable>
    </div>
    </div>
  );
}

export default PatientContactList;
