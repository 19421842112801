import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { profileRoute } from 'routes';
import { useI18n } from 'hooks/i18n';
import StandardLayout from 'components/layouts/StandardLayout';
import UpdateUser from 'components/modules/user/UpdateUser';
import { useAuth } from 'hooks/auth';
import { useHistory, useParams } from "react-router-dom";
import { NEW_USER_CONTACTS } from 'apollo/operations/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PatientContactList from 'components/modules/patient/PatientContactList';
import Spinner from 'components/common/Spinner';
import Spacer from 'components/common/Spacer';
import Unauthorized from 'components/errors/Unauthorized';


function UserProfilePage() {
  const { t } = useI18n();
  const { hasAnyRole, localAuthority } = useAuth();
  const { userId } = useParams();
  const { loading, data } = useQuery(NEW_USER_CONTACTS, {
    variables: { id: userId },
  });

  if (loading) {
    return <Spinner />;
  }

  const dataUser = data?.user;

  const isSelf = localAuthority.id == userId;
  const isAdmin = hasAnyRole(['ROLE_ADMIN']);
  const isPartnerAdmin = hasAnyRole(['ROLE_PARTNER_ADMIN']);
  const isTenantAdmin = hasAnyRole(['ROLE_TENANT_ADMIN']);
  const isSubTenantAdmin = hasAnyRole(['ROLE_SUB_TENANT_ADMIN']);

  const getUserType = () => {
    if (isSelf) {
      return 'self';
    } else if (isAdmin) {
      return 'admin';
    } else if (isPartnerAdmin) {
      return 'partnerAdmin';
    } else if (isTenantAdmin) {
      return 'tenantAdmin';
    } else if (isSubTenantAdmin) {
      return 'subTenantAdmin';
    } else {
      return 'unknown';
    }
  }

  const renderProfile = (userType) => {
    switch (userType) {
      case 'self':
        return <UpdateUser /> 
      case 'admin':
        return <UpdateUser />
      case 'partnerAdmin':
        return <UpdateUser />
      case 'tenantAdmin':
        return <UpdateUser /> 
      case 'subTenantAdmin':
        return <UpdateUser />
      default:
        return <Unauthorized />;
    }    
  } 


  return (
    <StandardLayout breadcrumb={[[profileRoute(userId), t('route.profile')]]}>
      {(loading && data) ? <Spinner /> : (
        <> 
          {renderProfile(getUserType())}
          <Spacer v={80} />
          <PatientContactList contacts={dataUser?.directContacts} />
        </>
      )}
    </StandardLayout>
  );
}

export default UserProfilePage;
