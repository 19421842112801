import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import * as R from 'ramda';
import { useQuery } from '@apollo/react-hooks';

import { tenantUsersRoute } from 'routes';
import { NEW_USER_DETAIL_PAGE } from 'apollo/operations/user';
import { useI18n } from 'hooks/i18n';
import StandardLayout from 'components/layouts/StandardLayout';
import Spinner from 'components/common/Spinner';
import TenantUpdateUser from 'components/modules/user/TenantUpdateUser';
import UserSubTenantAdminManager from 'components/modules/user/UserSubTenantAdminManager';
import ToggleUserLock from 'components/modules/user/ToggleUserLock';
import RoleSecured from 'components/common/RoleSecured';

function UserDetailPage() {
  const match = useRouteMatch();
  const { t } = useI18n();

  const userId = match.params.userId
  const tenantId = match.params.tenantId

  const { data, loading } = useQuery(NEW_USER_DETAIL_PAGE, {
    variables: { id: userId },
  });

  const refetchQueries = [
    { query: NEW_USER_DETAIL_PAGE, variables: { id: userId } },
  ];

  const user = R.path(['user'], data);

  const pageTitle = t('route.updateUser');

  return (
    <StandardLayout
      breadcrumb={[
        [tenantUsersRoute(tenantId), t('route.tenantUsers')],
        [match.path, pageTitle],
      ]}
    >
      <h1>{pageTitle}</h1>
      {loading && <Spinner />}
      {!loading && user && (
        <div className="row">
          <div className="col">
            <TenantUpdateUser user={user} />
          </div>

          <div className="col">
            <RoleSecured roles={['ROLE_ADMIN', 'ROLE_TENANT_ADMIN']}>
              <UserSubTenantAdminManager
                user={user}
                refetchQueries={refetchQueries}
              />
            </RoleSecured>
            <RoleSecured roles={['ROLE_ADMIN', 'ROLE_TENANT_ADMIN', 'ROLE_SUB_TENANT_ADMIN']}>
              <ToggleUserLock user={user} />
            </RoleSecured>
          </div>
        </div>
      )}
    </StandardLayout>
  );
}

export default UserDetailPage;
