import React from 'react';
import * as R from 'ramda';
import { useQuery } from '@apollo/react-hooks';
import { Table as BSTable } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { GET_TENANTS, NEW_GET_TENANTS } from 'apollo/operations/tenant';
import Translate from 'components/common/Translate';

function TenantList() {
  // const { loading, data } = useQuery(GET_TENANTS);
  const { loading, data } = useQuery(NEW_GET_TENANTS);

  if (loading) {
    return <div>Loading...</div>;
  }
  
  const tenantList = R.pipe(
    R.pathOr(null, ['tenantList']),
    R.defaultTo([]),
    R.sortBy(R.prop('name')),
  )(data);

  return (
    <BSTable striped bordered hover>
      <thead>
        <tr>
          <th>
            <Translate text="tenant.properties.name" />
          </th>
          <th>
            <Translate text="tenant.properties.id" />
          </th>
          <th>
            <Translate text="edit" />
          </th>
        </tr>
      </thead>
      <tbody>
        {tenantList.map(tenant => {
          return (
            <tr key={tenant.id}>
              <td>
                <Link to={'/tenants/' + tenant.id}>{tenant.name}</Link>
              </td>
              <td>{tenant.id}</td>
              <td>
                <Link to={'/tenants/' + tenant.id + '/update'}><Translate text="edit" /></Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </BSTable>
  );
}

export default TenantList;
