import React from 'react';
import * as R from 'ramda';
import { userType } from 'types';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';

import { verifyEmailRoute } from 'routes';
import { validationSchema, defaultUser } from 'utils/modules/user';
import Translate from 'components/common/Translate';
import ErrorBox from 'components/common/ErrorBox';
import UserFormFields from './UserFormFields';
import { useI18n } from 'hooks/i18n';
import { UPDATE_USER } from 'apollo/operations/user';
import { ASSIGN_USER_SUBTENANT } from 'apollo/operations/user';
import { setValidationErrors } from 'utils/forms';
import SubTenantSelectField from 'components/modules/subTenant/SubTenantSelectField';

function TenantUpdateUser({ user }) {
  const { t } = useI18n();
  const [updateUser, { loading, error }] = useMutation(UPDATE_USER);
  const [updateUserSubtenant, { subloading, suberror }] = useMutation(ASSIGN_USER_SUBTENANT);

  const initialValues = R.mergeDeepLeft(user, defaultUser());

  async function handleSubmit(values, formikBag) {
    const fields = R.keys(defaultUser());
    const userInput = R.pick(fields, values);
    console.log('subtenant', values)
    console.log('subtenant', values.subTenantId)
    debugger;

    try {
      await updateUserSubtenant({
        variables: {
          userId: user.id,
          subTenantId: values.subTenantId
        }
      })
      await updateUser({
        variables: {
          id: user.id,
          user: userInput,
          verifyPath: verifyEmailRoute(),
        },
      });
      formikBag.resetForm();
      toast.success(t('user.updateProfileSuccessToast'));
    } catch (err) {
      console.log('Error updating user profile', err);
      toast.error(t('user.updateProfileErrorToast'));
      setValidationErrors(err, formikBag);
    }
  }

  const userValidationSchema = validationSchema({
    email: t('user.properties.email'),
    name: t('user.properties.name'),
    displayName: t('user.properties.displayName'),
    defaultLocale: t('user.properties.defaultLocale'),
  });

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={userValidationSchema}
    >
      <Form>
        <div className="card">
          <div className="card-header">
            <Translate text="user.updateProfileLabel" />
          </div>

          <div className="card-body">
            <UserFormFields disableEmail={true}/>
            
            <SubTenantSelectField
              name="subTenantId"
              label={t('subTenant.label')}
              tenantId={initialValues.tenantId}
            />

            <ErrorBox graphqlError={error} />
          </div>

          <div className="card-footer text-right">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              <Translate text="save" />
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}

TenantUpdateUser.propTypes = {
  user: userType.isRequired,
};

export default TenantUpdateUser;
