import * as R from 'ramda';
import { yupToFormErrors } from 'formik';

/**
 * Sets errors in a formik form if the request resulted in a validation_error
 * @param {Object} errors - The result of the errors object from the Apollo request
 * @param {Object} formikBag - Formik actions object passed by onSubmit
 */
export function setValidationErrors(error, formikBag) {
  const errorType = R.path(['graphQLErrors', 0, 'message'], error);
  const errorsObject = R.path(
    ['graphQLErrors', 0, 'extensions', 'errors'],
    error,
  );
  const isValidationError = R.equals('validation_error', errorType);

  if (!isValidationError) return;

  formikBag.setErrors(yupToFormErrors(errorsObject));
}
