import React from 'react';

import { useI18n } from 'hooks/i18n';
import Translate from 'components/common/Translate';

function LoginButton({ auth0Url, redirectUrl }) {
  const { t } = useI18n();

  const redirect = () => {
    const fullRedirectUrl = new URL(auth0Url)
    window.location.replace(fullRedirectUrl)
  }

  return (
    <button className="btn btn-primary btn-lg" onClick={redirect}>
      <Translate
        text="navbar.logInLink"
      />
    </button>
  );
}

export default LoginButton;
