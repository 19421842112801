import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

import FormLabel from './FormLabel';

function FormikFileField({
  form,
  field,
  className,
  isInvalid,
  label,
  renderInput,
  helpText,
  required,
  ...props
}) {
  const fileRef = useRef();

  const handleChange = event => {
    form.setFieldValue(field.name, event.currentTarget.files[0]);
  };

  useEffect(() => {
    if (!field.value) {
      fileRef.current.value = '';
    }
  }, [field.value]);

  const input = (
    <input
      {...props}
      ref={fileRef}
      type="file"
      id={field.name}
      onChange={handleChange}
      className={classnames(className, 'form-control')}
    />
  );

  return (
    <div
      className={classnames('form-group', {
        'has-error': isInvalid,
        'text-danger': isInvalid,
      })}
    >
      <FormLabel
        name={field.name}
        label={label}
        helpText={helpText}
        required={required}
      />
      {renderInput ? renderInput(input) : input}
      <ErrorMessage name={field.name} />
    </div>
  );
}

FormikFileField.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  className: PropTypes.string,
  isInvalid: PropTypes.bool.isRequired,
  label: PropTypes.node,
  renderInput: PropTypes.func,
  helpText: PropTypes.node,
  required: PropTypes.bool,
};

FormikFileField.defaultProps = {
  className: null,
  label: null,
  renderInput: null,
  helpText: null,
  required: false,
};

export default FormikFileField;
