import React from 'react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import * as types from 'types';
import { setValidationErrors } from 'utils/forms';
import { UPDATE_TENANT_LOCALE, NEW_UPDATE_TENANT } from 'apollo/operations/tenant';
import LocaleSelect from 'components/modules/locale/LocaleSelect';
import { useI18n } from 'hooks/i18n';
import Translate from 'components/common/Translate';
import { Modal } from 'react-bootstrap';
import { useModalState } from 'hooks/modal';

function UpdateTenantLocale({ tenant, tenantId, tenantLocale, refetchQueries, children }) {
  const { t } = useI18n();
  const { show, showModal, hideModal } = useModalState();
  // const [ updateTenantLocale, { loading }] = useMutation(UPDATE_TENANT_LOCALE, {
  //   refetchQueries,
  // });

  const [ updateTenant, { loading }] = useMutation(NEW_UPDATE_TENANT, {
    refetchQueries,
  });


  async function handleSubmit(values, formikBag) {
    try {
      console.log('tenant', tenantId, values.locale)
      // await updateTenantLocale({ variables: { id: tenantId, locale: values.locale} });
      await updateTenant({ variables: { id: tenantId, tenant: {name: tenant.name, locale: values.locale} }});
      toast.success(t('tenant.updateSuccessToast'));
      hideModal();
    } catch (err) {
      console.log('Error updateing tenant', err);
      setValidationErrors(err, formikBag);
      toast.error(t('tenant.updateErrorToast'));
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate text="tenant.updateLocaleLabel" />
          </Modal.Title>
        </Modal.Header>

        <Formik onSubmit={handleSubmit} initialValues={{ locale: tenantLocale }}>
          <Form>
            <Modal.Body>
            <LocaleSelect
              name="locale"
              label={t('tenant.properties.locale')}
              required
            />
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                <Translate text="tenant.updateButtonText" />
              </button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}

UpdateTenantLocale.propTypes = {
  tenant: types.tenantType.isRequired,
  tenantId: types.tenantIdType.isRequired,
  tenantLocale: types.tenantLocaleType.isRequired,
  refetchQueries: types.refetchQueriesType.isRequired,
  children: PropTypes.func.isRequired,
};

export default UpdateTenantLocale;
