import React from 'react';
import { Table as BSTable } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { patientRoute, tenantDeviceRoute, deviceRoute } from 'routes';
import Translate from 'components/common/Translate';
import { useSortFilter } from 'hooks/data';
import SortTrigger from 'components/common/SortTrigger';
import DateTime from 'components/common/DateTime';
import { tenantIdType, deviceType } from 'types';

function TenantDeviceList({ tenantId, subTenant, noSubTenant, deviceList }) {

  // const patientDevices = R.pipe(R.pathOr(null, ['device']), R.defaultTo([]));
  let filteredDevices;
  if (noSubTenant) {
    filteredDevices = deviceList.filter(device => device.subTenant == null);
  } else {
    filteredDevices = deviceList.filter(device => device.subTenant?.id === subTenant.id);
  }

  const { items, sortIndicatorProps } = useSortFilter(
    filteredDevices,
    'deviceId',
    'name',
    'lastSeen',
    'currentVersion',
    'mdmProvisoningStatus'
  );

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <BSTable bordered hover>
      <thead>
        <tr>
          <th>
            <SortTrigger
              colName="deviceId"
              sortIndicatorProps={sortIndicatorProps}
            >
              <Translate text="device.properties.deviceId" />
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="lastSeen"
              sortIndicatorProps={sortIndicatorProps}
            >
              <Translate text="device.properties.lastSeen" />
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="currentVersion"
              sortIndicatorProps={sortIndicatorProps}
            >
              <Translate text="device.properties.currentVersion" />
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="mdmProvisoningStatus"
              sortIndicatorProps={sortIndicatorProps}
            >
              <Translate text="device.properties.mdmProvisoningStatus" />
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="patient.name"
              sortIndicatorProps={sortIndicatorProps}
            >
              <Translate text="device.properties.patient" />
            </SortTrigger>
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {items.map(device => {
          return (
            <tr key={device.deviceEasyId}>
              <td>
                {/* <Link to={tenantDeviceRoute(tenantId, device.id)}> */}
                <Link to={deviceRoute(device.id)}>
                  <Translate text="device.label" /> {device.deviceEasyId}
                </Link>
              </td>
              <td>
                {device.lastSeen ? <DateTime date={device.lastSeen} timezone={tz}/> : "❌"}
              </td>
              <td>
                {device.currentVersion ? device.currentVersion : "❌"}
              </td>
              <td>
                {device.mdmProvisioningStatus}
              </td>
              <td>
                {device.patient ? (
                  <Link to={patientRoute(tenantId, device.patient.id)}>
                    {device.patient.name}
                  </Link>
                ) : (
                  <Translate text="tenantDeviceList.noPatientText" />
                )}
              </td>
              <td className="text-right">
                <Link
                  // to={tenantDeviceRoute(tenantId, device.id)}
                  to={deviceRoute(device.id)}
                  className="btn btn-sm btn-primary"
                >
                  <Translate text="edit" />
                </Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </BSTable>
  );
}

TenantDeviceList.propTypes = {
  tenantId: tenantIdType.isRequired,
  deviceList: PropTypes.arrayOf(deviceType).isRequired,
};

export default TenantDeviceList;
