import { useState } from 'react';

export function useModalState() {
  const [show, setShow] = useState(false);

  const showModal = event => {
    if (event) event.preventDefault();
    setShow(true);
  };

  const hideModal = event => {
    if (event) event.preventDefault();
    setShow(false);
  };

  return { show, showModal, hideModal };
}
