import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { useQuery } from '@apollo/react-hooks';

import { NEW_GET_PARTNERS } from 'apollo/operations/partner';
import FormikField from 'components/common/FormikField';

function PartnerSelectField({ name, label, disabled, onChange, ...props }) {
  const { data, loading } = useQuery(NEW_GET_PARTNERS);

  const partners = R.pipe(
    R.path(['partnerList']),
    R.defaultTo([]),
    R.sortBy(R.prop('name')),
  )(data);

  const options = partners.map(partner => ({
    value: partner.id,
    label: partner.name,
  }));

  return (
    <Field
      {...props}
      name={name}
      label={label}
      component={FormikField}
      onChange={onChange}
      type="select"
      disabled={disabled || loading}
      options={options}
    />
  );
}

PartnerSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

PartnerSelectField.defaultProps = {
  disabled: false,
};

export default PartnerSelectField;
