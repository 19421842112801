import React from 'react';
import * as R from 'ramda';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import { NEW_GET_TENANT_DEVICES, NEW_GET_SUBTENANT_DEVICES } from 'apollo/operations/device';
import FormikField from 'components/common/FormikField';
import { patientIdType, tenantIdType } from 'types';
import { useI18n } from 'hooks/i18n';

function UnassignedDevicesSelect({
  tenantId,
  subTenantId,
  patientId,
  name,
  ...props
}) {

  const { t } = useI18n();
  // const { data, loading } = useQuery(NEW_GET_TENANT_DEVICES, {
  //   variables: { id: tenantId,  },
  // });
  const { data, loading } = useQuery(NEW_GET_SUBTENANT_DEVICES, {
    variables: { subTenantId: subTenantId },
  });

  const getLabel = device => {
    let prefix = `Device ${device.deviceEasyId}`;
    let suffix =
      device.patient?.id === patientId
        ? device.patient.name
        : `[${t('device.unassigned')}]`;

    return `${prefix} - ${suffix}`;
  };

  const devices = R.pipe(
    R.path(['deviceList']),
    R.defaultTo([]),
    //
  )(data);

  const unassignedDevices = R.pipe(
    R.filter(
      R.anyPass([
        R.whereEq({ patient: null }),
        R.pathEq(['patient', 'id'], patientId),
      ]),
    ),
    // R.filter(
    //   R.whereEq({ subTenant: { id: subTenantId } }),
    // ),
    R.sortBy(R.prop('deviceId')),
    R.map(d => ({
      value: d.id,
      label: getLabel(d),
    })),
  )(devices);

  const patientHasDevice = R.pipe(
    R.map(R.path(['patient', 'id'])),
    R.reject(R.isNil),
    R.contains(patientId),
  )(devices);
  
  const blankOptionLabel = patientHasDevice
    ? t('device.deviceBlankOptionLabelUnassign')
    : t('device.deviceBlankOptionLabel');

  return (
    <Field
      name={name}
      label={t('device.properties.newDeviceId')}
      component={FormikField}
      type="select"
      options={unassignedDevices}
      blankOptionLabel={blankOptionLabel}
      disabled={loading}
    />
  );
}

UnassignedDevicesSelect.propTypes = {
  name: PropTypes.string.isRequired,
  tenantId: tenantIdType.isRequired,
  patientId: patientIdType.isRequired,
};

export default UnassignedDevicesSelect;
