import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'

import * as types from 'types';
import { useSortFilter } from 'hooks/data';
import SortTrigger from 'components/common/SortTrigger';
import Translate from 'components/common/Translate';
import UpdateGroupModal from 'components/modules/group/UpdateGroupModal';
import DeleteGroup from 'components/modules/group/DeleteGroup';

function SubTenantGroupList({ tenantId, subTenantId, groups, refetchQueries }) {
  const filteredGroups = groups.filter(group => group.deleted_at == null)
  const { items, sortIndicatorProps } = useSortFilter(filteredGroups, 'name', 'name');

  return (
    <table className="table table-bordered table-hover mb-0">
      <thead>
        <tr>
          <th>
            <SortTrigger colName="name" sortIndicatorProps={sortIndicatorProps}>
              <Translate text="group.properties.name" />
            </SortTrigger>
          </th>
          <th>
            <Translate text="group.deleteLabel" />
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map(group => (
          <tr key={group.id}>
            <td>
              {group.name} 
              <UpdateGroupModal groupId={group.id} groupName={group.name} refetchQueries={refetchQueries}>
              {showModal => (
                <small>
                  <FontAwesomeIcon icon={faPen} onClick={showModal} className={"ml-3 cursor-pointer"} />
                </small>
              )}
          </UpdateGroupModal>
            </td>
            <td>
              <DeleteGroup groupId={group.id} refetchQueries={refetchQueries} disabled={group.patients.length > 0}/>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

SubTenantGroupList.propTypes = {
  tenantId: types.tenantIdType.isRequired,
  subTenantId: types.subTenantIdType.isRequired,
  groups: PropTypes.arrayOf(types.groupType).isRequired,
  refetchQueries: types.refetchQueriesType.isRequired,
};

export default SubTenantGroupList;
