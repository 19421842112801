import React from 'react';

import './index.css';
import Translate from 'components/common/Translate';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col">
            <span className="text-muted">
              Copyright &copy;{' '}
              <a
                href="https://blinktec.no"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blink
              </a>
              . <Translate text="footerText" />
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {};

export default Footer;
