import React from 'react';
import * as R from 'ramda';
import { useRouteMatch, Redirect } from 'react-router-dom';

import { useI18n } from 'hooks/i18n';
import { notFoundRoute, homeRoute } from 'routes';
import { useAuth } from 'hooks/auth';
import StandardLayout from 'components/layouts/StandardLayout';
import ConfirmTenantUserEmail from 'components/modules/auth/ConfirmTenantUserEmail';

function VerifyTenantUserEmailPage() {
  const { isAuthenticated } = useAuth();
  const { t } = useI18n();
  const match = useRouteMatch();
  const token = R.path(['params', 'token'], match);

  if (!token) return <Redirect to={notFoundRoute()} />;
  if (isAuthenticated) return <Redirect to={homeRoute()} />;

  return (
    <StandardLayout
      breadcrumb={[[match.path, t('route.verifyTenantUserEmail')]]}
    >
      <ConfirmTenantUserEmail token={token} />
    </StandardLayout>
  );
}

export default VerifyTenantUserEmailPage;
