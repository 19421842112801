import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import { REFRESH_AUTHORITY, NEW_REFRESH_AUTHORITY } from 'apollo/operations/auth';
import { NEW_GET_TENANTS } from 'apollo/operations/tenant';
import { NEW_GET_PARTNERS } from 'apollo/operations/partner';
import { NEW_GET_SELF } from 'apollo/operations/user';
import { useAuth } from 'hooks/auth';
import { useLazyQuery } from 'hooks/apollo';
import Translate from 'components/common/Translate';

function TokenValidator({ children }) {
  const { isAuthenticated, setLocalAuthority } = useAuth();
  const [triedAuth, setTriedAuth] = useState(false);
  const [refreshAuthority, { loading }] = useMutation(REFRESH_AUTHORITY);
  const [newRefreshAuthority] = useLazyQuery(NEW_REFRESH_AUTHORITY);
  const [netGetPartners] = useLazyQuery(NEW_GET_PARTNERS);
  const [newGetTenants] = useLazyQuery(NEW_GET_TENANTS);
  const [newGetSelf] = useLazyQuery(NEW_GET_SELF);

  useEffect(() => {
    async function logIn() {
      try {
        const authRes = await newRefreshAuthority();
        const selfRes = await newGetSelf();
        const tenantsRes = await newGetTenants();
        const partnersRes = await netGetPartners();

        const authData = authRes.data.authorityRefresh;
        const selfData = selfRes.data.user;
        const tenantsData = tenantsRes.data.tenantList;
        const partnersData = partnersRes.data.partnerList;

        let roles = selfData.roles;
        // all users have user role
        roles.push("ROLE_USER")
        if (!authData?.anonymous) {
          const user = {
            id: authData.userId,
            isAdmin: authData.isAdmin,
            name: authData.metadata.name,
            email: authData.metadata.email,
            roles: roles,
            displayName: selfData.displayName,
            tenants: tenantsData,
            partners: partnersData
          }
          setLocalAuthority(user);
        }
      } catch (err) {
        console.log('login err')
        console.log(err)
        // console.log('Could not find a session or session is invalid');
      } finally {
        setTriedAuth(true);
      }
    }

    if (!isAuthenticated) {
      if (!triedAuth) {
        logIn();
      }
    } else {
      setTriedAuth(true);
    }
  }, [triedAuth, isAuthenticated, setLocalAuthority, refreshAuthority]);

  if (loading || (!isAuthenticated && !triedAuth)) {
    return (
      <span>
        <Translate text="auth.authenticating" />
      </span>
    );
  }

  return children;
}

TokenValidator.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TokenValidator;
