import React from 'react';
import * as R from 'ramda';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { GET_TENANTS, NEW_GET_TENANTS } from 'apollo/operations/tenant';
import StandardLayout from 'components/layouts/StandardLayout';
import Spinner from 'components/common/Spinner';
import UpdateTenant from 'components/modules/tenant/UpdateTenant';
import Translate from 'components/common/Translate';
import { useI18n } from 'hooks/i18n';

function TenantUpdatePage() {
  const { t } = useI18n();
  const match = useRouteMatch();
  const { tenantId } = useParams();

  // const { data, loading } = useQuery(GET_TENANTS);
  const { data, loading } = useQuery(NEW_GET_TENANTS);

  const tenant = R.pipe(
    R.prop(['tenantList']),
    R.defaultTo([]),
    R.find(R.whereEq({ id: tenantId })),
  )(data);

  return (
    <StandardLayout breadcrumb={[[match.path, t('route.tenantUpdate')]]}>
      <h1>
        <Translate text="route.tenantUpdate" />
      </h1>

      {loading && <Spinner />}
      {!loading && tenant && (
        <UpdateTenant tenant={tenant} />
      )}
    </StandardLayout>
  );
}

export default TenantUpdatePage;
