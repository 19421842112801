import React from 'react';
import * as R from 'ramda';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';

import { deviceType } from 'types';
import { useI18n } from 'hooks/i18n';
import { NEW_UPDATE_DEVICE_KIOSK } from 'apollo/operations/device';
import { setValidationErrors } from 'utils/forms';
import FormikField from 'components/common/FormikField';
import Translate from 'components/common/Translate';
import KioskSelect from 'components/modules/device/KioskSelect';
import { useAuth } from 'hooks/auth';
import ErrorBox from 'components/common/ErrorBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function UpdateDeviceKiosk({ device, refetchQueries }) {
  const { t } = useI18n();
  const { hasAnyRole } = useAuth();

  const [updateDeviceKiosk, { loading, error }] = useMutation(NEW_UPDATE_DEVICE_KIOSK, {refetchQueries});

  async function handleSubmit(values, formikBag) {
    try {
      console.log(values)
      await updateDeviceKiosk({
        variables: {
          id: device.id,
          state: values.kioskMode,
        },
      });
      toast.success(t('device.updateSuccessToast'));
    } catch (err) {
      console.log('error updating device kiosk:', err);
      setValidationErrors(err, formikBag);
      toast.error(t('device.updateErrorToast'));
    }
  }

  const isAdmin = hasAnyRole(['ROLE_ADMIN']);
  const isPartnerAdmin = hasAnyRole(['ROLE_PARTNER_ADMIN']);
  const isTenantAdmin = hasAnyRole(['ROLE_TENANT_ADMIN']);
  const isSubTenantAdmin = hasAnyRole(['ROLE_SUB_TENANT_ADMIN']);

  const isAnyAdmin = isAdmin || isPartnerAdmin || isTenantAdmin || isSubTenantAdmin;

  let defaultDevice = device;

  return (
    <Formik onSubmit={handleSubmit} initialValues={defaultDevice} enableReinitialize>
      {({ values }) => (
        <Form>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                <Translate text="device.updateHeadingText" />
              </h5>

            <KioskSelect
              name="kioskMode"
              label={t('device.properties.kioskMode')}
            />

              <ErrorBox graphQLError={error} />
            </div>

            <div className="card-footer text-right">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading || !isAnyAdmin}
              >
                <Translate text="device.updateButtonText" />
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

UpdateDeviceKiosk.propTypes = {
  device: deviceType.isRequired,
};

export default UpdateDeviceKiosk;
