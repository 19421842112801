import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';

import { useI18n } from 'hooks/i18n';
import StandardLayout from 'components/layouts/StandardLayout';
import PartnerDetail from 'components/modules/partner/PartnerDetail';
import { tenantCreateRoute } from 'routes';
import Translate from 'components/common/Translate';

function PartnerDetailPage() {
  const match = useRouteMatch();
  const { t } = useI18n();

  return (
    <StandardLayout breadcrumb={[[match.path, t('route.partnerDetail')]]}>
      <div className="float-right">
        {/* <Link to={tenantCreateRoute()} className="btn btn-primary">
          <Translate text="route.createTenant" />
        </Link> */}
      </div>

      <h1>
        <Translate text="route.partnerDetail" />
      </h1>

      <div className="clearfix" />
      <div className="mt-2" />

      <PartnerDetail />
    </StandardLayout>
  );
}

export default PartnerDetailPage;
