import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';

import apolloClient from 'apollo/client';
import Routes from 'routes';
import { AuthContextProvider } from 'contexts/auth';
import { I18nContextProvider } from 'contexts/i18n';
import ToastContainer from 'components/ToastContainer';
import TokenValidator from 'components/modules/auth/TokenValidator';
import IdleTimer from './IdleTimer';

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <ToastContainer />

        <AuthContextProvider>
          <I18nContextProvider>
            <TokenValidator>
              <IdleTimer />
              <Routes />
            </TokenValidator>
          </I18nContextProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
