import React from 'react';
import { Table as BSTable } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { patientRoute, tenantDeviceRoute, partnerDeviceRoute, tenantRoute, deviceRoute } from 'routes';
import Translate from 'components/common/Translate';
import { useSortFilter } from 'hooks/data';
import SortTrigger from 'components/common/SortTrigger';
import DateTime from 'components/common/DateTime';
import { tenantIdType, deviceType } from 'types';

function PartnerDeviceList({ deviceList }) {

  // const patientDevices = R.pipe(R.pathOr(null, ['device']), R.defaultTo([]));

  const { items, sortIndicatorProps } = useSortFilter(
    deviceList,
    'deviceEasyId',
    'lastSeen',
    'patient.name'
  );
  
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <BSTable bordered hover>
      <thead>
        <tr>
          <th>
            <SortTrigger
              colName="deviceEasyId"
              sortIndicatorProps={sortIndicatorProps}
            >
              <Translate text="device.properties.deviceId" />
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="lastSeen"
              sortIndicatorProps={sortIndicatorProps}
            >
              <Translate text="device.properties.lastSeen" />
            </SortTrigger>
          </th>
          <th className="text-center">
              <Translate text="tenant.label" />
            </th>
            <th className="text-center">
              <Translate text="subTenant.label" />
            </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {items.map(device => {
          return (
            <tr key={device.id}>
              <td>
                <Link to={deviceRoute(device.id)}>
                  {device.deviceEasyId}
                  </Link>
              </td>
              <td>
                {device.lastSeen ? <DateTime date={device.lastSeen}  timezone={tz}/> : "❌"}
              </td>
              <td className="text-center">
                  {device.tenant && (
                    <Link to={tenantRoute(device.tenant.id)}>
                      {device.tenant.name}
                    </Link>
                  )}
                </td>
                <td className="text-center">
                  {device.subTenant && (
                    <Link to={tenantRoute(device.subTenant.id)}>
                      {device.subTenant.name}
                    </Link>
                  )}
                </td>
              <td className="text-right">
                <Link
                  to={deviceRoute(device.id)}
                  className="btn btn-sm btn-primary"
                >
                  <Translate text="edit" />
                </Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </BSTable>
  );
}

PartnerDeviceList.propTypes = {
  deviceList: PropTypes.arrayOf(deviceType).isRequired,
};

export default PartnerDeviceList;
