import React from 'react';
import * as R from 'ramda';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';

import { setValidationErrors } from 'utils/forms';
import { NEW_CREATE_PARTNER } from 'apollo/operations/partner';
import { partnerListRoute } from 'routes';
import { useI18n } from 'hooks/i18n';
import FormikField from 'components/common/FormikField';
import Translate from 'components/common/Translate';
import ErrorBox from 'components/common/ErrorBox';
import LocaleSelect from '../locale/LocaleSelect';

function CreatePartner() {
  const { t } = useI18n();
  const [createPartner, { loading, data, error }] = useMutation(NEW_CREATE_PARTNER);

  async function handleSubmit(values, formikBag) {
    const fields = ['name'];
    const partner = R.pick(fields, values);

    try {
      await createPartner({ variables: { partner } });
      toast.success(t('tenant.createSuccessToast'));
    } catch (err) {
      console.log('Error creating partner', err);
      setValidationErrors(err, formikBag);
      toast.error(t('partner.createErrorToast'));
    }
  }

  if (data) {
    return <Redirect to={partnerListRoute()} />;
  }


  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ name: '' }}
    >
      <Form>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">
              <Translate text="partner.createHeadingText" />
            </h5>
            <Field
              name="name"
              label={t('partner.properties.name')}
              component={FormikField}
              required
            />
          </div>

          <ErrorBox graphQLError={error} />

          <div className="card-footer text-right">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              <Translate text="partner.createButtonText" />
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}

export default CreatePartner;
