import React from 'react';
import { Table as BSTable } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import * as types from 'types';
import { patientRoute } from 'routes';
import { useSortFilter } from 'hooks/data';
import SortTrigger from 'components/common/SortTrigger';
import DeletePatientButton from 'components/modules/patient/DeletePatientButton';
import Translate from 'components/common/Translate';

function GroupPatients({
  tenantId,
  subTenantId,
  groupId,
  patients,
  withDeleted,
  refetchQueries,
}) {
  const { items, sortIndicatorProps } = useSortFilter(patients, 'name', 'name');

  return (
    <BSTable bordered hover>
      <thead>
        <tr>
          <th>
            <SortTrigger colName="name" sortIndicatorProps={sortIndicatorProps}>
              <Translate text="patient.properties.name" />
            </SortTrigger>
          </th>
          <th className="text-center">
            <SortTrigger
              colName="device.deviceId"
              sortIndicatorProps={sortIndicatorProps}
            >
              <Translate text="patient.properties.deviceId" />
            </SortTrigger>
          </th>
          {withDeleted && (
            <th className="text-center">
              <SortTrigger
                colName="deleted"
                sortIndicatorProps={sortIndicatorProps}
              >
                <Translate text="patient.properties.deleted" />
              </SortTrigger>
            </th>
          )}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {!items.length && (
          <tr>
            <td colSpan={4} className="text-center">
              <em>
                <Translate text="noItemsToDisplay" />
              </em>
            </td>
          </tr>
        )}
        {items.map(patient => {
          return (
            <tr key={patient.id}>
              <td className="align-middle">
                <Link to={patientRoute(tenantId, patient.id)}>
                  {patient.name}
                </Link>
              </td>
              <td className="align-middle text-center">
                {patient.device ? patient.device.deviceEasyId : '--'}
              </td>
              {withDeleted && (
                <td className="align-middle text-center">
                  {patient.deleted && <FontAwesomeIcon icon="trash-alt" />}
                </td>
              )}
              <td className="align-middle text-right">
                <div className="btn-group" role="group">
                  <Link
                    to={patientRoute(tenantId, patient.id)}
                    className="btn btn-primary btn-sm"
                  >
                    <Translate text="edit" />
                  </Link>

                  {!patient.deleted && (
                    <div className="btn-group" role="group">
                      <button
                        type="button"
                        className="btn btn-light btn-sm btn-outline-primary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></button>
                      <div className="dropdown-menu">
                        <DeletePatientButton
                          patientId={patient.id}
                          refetchQueries={refetchQueries}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </BSTable>
  );
}

GroupPatients.propTypes = {
  tenantId: types.tenantIdType.isRequired,
  subTenantId: types.subTenantIdType.isRequired,
  groupId: types.groupIdType.isRequired,
  patients: PropTypes.arrayOf(types.patientType).isRequired,
  withDeleted: PropTypes.bool.isRequired,
  refetchQueries: types.refetchQueriesType.isRequired,
};

export default GroupPatients;
