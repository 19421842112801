import React from 'react';
import * as R from 'ramda';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { Table as BSTable, Button as BSButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { NEW_CREATE_DEVICE, NEW_DEVICE_LIST } from 'apollo/operations/device';
import { deviceRoute, tenantRoute } from 'routes';
import Spinner from 'components/common/Spinner';
import { toast } from 'react-toastify';
import DateTime from 'components/common/DateTime';
import Translate from 'components/common/Translate';
import { useI18n } from 'hooks/i18n';

function CreatePartnerDevice({partnerId, refetchQueries}) {
  const { t } = useI18n();
  const { loading: loadingDevices, data } = useQuery(NEW_DEVICE_LIST);
  const [createDevice, { loading: loadingCreate }] = useMutation(
    NEW_CREATE_DEVICE,
    {
      variables: {
        device: {
          partnerId: partnerId
        }
      },
      refetchQueries,
    },
  );

  if (loadingDevices) {
    return <Spinner />;
  }

  async function handleCreate() {
    if (!window.confirm('A new device will be created.')) {
      return;
    }

    try {
      await createDevice();
      toast.success(t('device.createSuccessToast'));
    } catch (err) {
      console.log('Error creating device', err);
      toast.error(t('device.createErrorToast'));
    }
  }

  return (
    <>
      <BSButton
        className="float-right"
        disabled={loadingCreate}
        onClick={handleCreate}
      >
        <Translate text="device.createButtonText" />
      </BSButton>
    </>
  );
}

export default CreatePartnerDevice;
