import React from 'react';
import * as R from 'ramda';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';

import { setValidationErrors } from 'utils/forms';
import { CREATE_TENANT, NEW_CREATE_TENANT } from 'apollo/operations/tenant';
import { tenantListRoute } from 'routes';
import { useI18n } from 'hooks/i18n';
import { validationSchema as tenantValidationSchema } from 'utils/modules/tenant';
import FormikField from 'components/common/FormikField';
import Translate from 'components/common/Translate';
import ErrorBox from 'components/common/ErrorBox';
import LocaleSelect from '../locale/LocaleSelect';

function CreateTenant() {
  const { t } = useI18n();
  const [createTenant, { loading, data, error }] = useMutation(NEW_CREATE_TENANT);

  async function handleSubmit(values, formikBag) {
    const fields = ['name', 'locale'];
    const tenant = R.pick(fields, values);

    try {
      await createTenant({ variables: { tenant } });
      toast.success(t('tenant.createSuccessToast'));
    } catch (err) {
      console.log('Error creating tenant', err);
      setValidationErrors(err, formikBag);
      toast.error(t('tenant.createErrorToast'));
    }
  }

  if (data) {
    return <Redirect to={tenantListRoute()} />;
  }

  const validationSchema = tenantValidationSchema({
    name: t('tenant.properties.name'),
  });

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ name: '', locale: '' }}
      validationSchema={validationSchema}
    >
      <Form>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">
              <Translate text="tenant.createHeadingText" />
            </h5>
            <Field
              name="name"
              label={t('tenant.properties.name')}
              component={FormikField}
              required
            />

            <LocaleSelect
              name="locale"
              label={t('user.properties.defaultLocale')}
              required
            />
          </div>

          <ErrorBox graphQLError={error} />

          <div className="card-footer text-right">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              <Translate text="tenant.createButtonText" />
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}

export default CreateTenant;
