import React from 'react';

import { Table as BSTable } from 'react-bootstrap';

import Translate from 'components/common/Translate';
import PartnerDeleteUser from 'components/modules/user/PartnerDeleteUser';
import TenantResendEmail from 'components/modules/user/TenantResendEmail';

function PartnerUsers({partner, users, refetchQueries}) {

  return (
    <BSTable striped bordered hover>
    <thead>
      <tr>
        <th>
          <Translate text="name" />
        </th>
        <th>
          <Translate text="email" />
        </th>
        <th>
          <Translate text="user.properties.isEmailVerified" />
        </th>
        <th>
          <Translate text="remove" />
        </th>
      </tr>
    </thead>
    <tbody>
      {users.map(user => {
        return (
          <tr key={user.id}>
            <td>
              {user.name}
            </td>
            <td>{user.email}</td>
            <td className="text-center">
                {user.isEmailVerified ? <Translate text="active" /> : <TenantResendEmail user={user}/>}
              </td>
            <td>
              <PartnerDeleteUser userId={user.id} partnerId={partner.id} refetchQueries={refetchQueries} />
            </td>
          </tr>
        )
      } )}
    </tbody>
  </BSTable>
  );
}

export default PartnerUsers;