import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import * as types from 'types';
import { useSortFilter } from 'hooks/data';
import SortTrigger from 'components/common/SortTrigger';
import Translate from 'components/common/Translate';
import { Link } from 'react-router-dom';
import { tenantUserRoute } from 'routes';
import DeleteUserAdmin from 'components/modules/user/DeleteUserAdmin';
import { useAuth } from 'hooks/auth';
import TenantResendEmail from 'components/modules/user/TenantResendEmail';

function TenantAdminList({ tenantId, users, refetchQueries }) {
  const { localAuthority } = useAuth();
  const selfId = R.propOr(null, 'id', localAuthority);
  // const nonSelfUsers = users.filter(user => user.id !== selfId);

  const { items, sortIndicatorProps } = useSortFilter(users || [], 'name', 'name');

  return (
    <table className="table table-bordered table-hover mb-0">
      <thead>
        <tr>
          <th>
            <SortTrigger colName="name" sortIndicatorProps={sortIndicatorProps}>
              <Translate text="user.properties.name" />
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="isEmailVerified"
              sortIndicatorProps={sortIndicatorProps}
            >
              <Translate text="user.properties.isEmailVerified" />
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="isAdmin"
              sortIndicatorProps={sortIndicatorProps}
            >
              <Translate text="user.properties.isAdmin" />
            </SortTrigger>
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map(user => (
          <tr key={user.id}>
            <td>
              <Link to={tenantUserRoute(tenantId, user.id)}>{user.name}</Link>
            </td>
            <td className="text-center">
                {user.isEmailVerified ? <Translate text="active" /> : <TenantResendEmail user={user}/>}
            </td>
            <td>
              <DeleteUserAdmin user={user} tenantId={tenantId} refetchQueries={refetchQueries} disabled={user.id === selfId}>
                {user.id !== selfId ? '❌' : '✔'}
              </DeleteUserAdmin>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

TenantAdminList.propTypes = {
  tenantId: types.tenantIdType.isRequired,
  users: PropTypes.arrayOf(types.userType).isRequired,
};

export default TenantAdminList;
