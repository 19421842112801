import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

import * as types from 'types';
import { homeRoute } from 'routes';
import Spacer from 'components/common/Spacer';
import Navbar from 'components/layouts/Navbar';
import Footer from 'components/layouts/Footer';

function StandardLayout({ breadcrumb, children }) {
  const pageName = R.pipe(
    R.defaultTo([]),
    R.last,
    R.propOr(null, 1),
  )(breadcrumb);

  const title = `${pageName ? `${pageName} | ` : ''}Blink Provisioning`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Navbar />

      {breadcrumb && (
        <nav aria-label="breadcrumb" className="mt-1">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item" aria-current="page">
                <Link to={homeRoute()}>Blink</Link>
              </li>
              {breadcrumb.map(([url, label], i) => {
                const isLastItem = i === breadcrumb.length - 1;
                return (
                  <li
                    key={i}
                    className={classnames('breadcrumb-item', {
                      active: isLastItem,
                    })}
                  >
                    {isLastItem && label}
                    {!isLastItem && <Link to={url}>{label}</Link>}
                  </li>
                );
              })}
            </ol>
          </div>
        </nav>
      )}

      <div className="container mt-3">{children}</div>
      <Spacer v={80} />

      <Footer />
    </>
  );
}

StandardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumb: types.breadcrumbType,
};

StandardLayout.defaultProps = {
  breadcrumb: null,
};

export default StandardLayout;
