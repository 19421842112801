import gql from 'graphql-tag';

import { GroupParts, NewGroupParts } from 'apollo/fragments';

export const CREATE_GROUP = gql`
  mutation CreateGroup($tenantId: ID!, $subTenantId: ID!, $group: GroupInput!) {
    createGroup(tenantId: $tenantId, subTenantId: $subTenantId, group: $group) {
      ...GroupParts
    }
  }

  ${GroupParts}
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroup($groupId: ID!, $group: GroupInput!) {
    updateGroup(groupId: $groupId, group: $group) {
      ...GroupParts
    }
  }

  ${GroupParts}
`;

export const NEW_CREATE_GROUP = gql`
  mutation GroupCreate($subTenantId: UUID4!, $group: GroupInput!) {
    groupCreate(subTenantId: $subTenantId, group: $group) {
      ...NewGroupParts
    }
  }

  ${NewGroupParts}
`;

export const NEW_UPDATE_GROUP = gql`
  mutation GroupUpdate($id: UUID4!, $group: GroupInput!) {
    groupUpdate(id: $id, group: $group) {
      ...NewGroupParts
    }
  }

  ${NewGroupParts}
`;

export const NEW_DELETE_GROUP = gql`
  mutation GroupDelete($id: UUID4!) {
    groupDelete(id: $id) {
      success
      message
    }
  }
`;


export const NEW_SOFT_DELETE_GROUP = gql`
  mutation GroupSoftDelete($id: UUID4!) {
    groupSoftDelete(id: $id) {
      success
      message
    }
  }
`;
