import React from 'react';
import { Field } from 'formik';

import { useI18n } from 'hooks/i18n';
import FormikField from 'components/common/FormikField';

function SubTenantFormFields() {
  const { t } = useI18n();

  return (
    <>
      <Field
        name="subTenant.name"
        label={t('subTenant.properties.name')}
        component={FormikField}
      />

    </>
  );
}


export default SubTenantFormFields;
