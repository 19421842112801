import { useEffect, useRef, useCallback } from 'react';
import { useLazyQuery as apolloLazyQuery } from '@apollo/react-hooks';

/**
 * Monkey patch for apollo's useLazyQuery hook to return a promise from the execute fn
 * @param {ApolloQuery} query
 * @param {Object} options
 * @returns {Array<executeFn, result>}
 */
export function useLazyQuery(query, options) {
  const [execute, result] = apolloLazyQuery(query, options);

  const resolveRef = useRef();

  useEffect(() => {
    if (result.called && !result.loading && resolveRef.current) {
      resolveRef.current(result);
      resolveRef.current = undefined;
    }
    // eslint-disable-next-line
  }, [result.loading, result.called]);

  const queryLazily = useCallback(
    (variables, context) => {
      execute({ variables, context });

      return new Promise(resolve => {
        resolveRef.current = resolve;
      });
    },
    [execute],
  );

  return [queryLazily, result];
}
