import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/common/Tooltip';
import Popover from 'components/common/Popover';
import HelpIcon from 'components/common/HelpIcon';
import { useI18n } from 'hooks/i18n';

function Asterisk() {
  const { t } = useI18n();

  return (
    <Tooltip overlay={t('requiredFieldTooltip')}>
      <div
        className="text-danger"
        style={{
          display: 'inline-block',
          fontSize: '22pt',
          lineHeight: '16px',
          position: 'relative',
          top: '9px',
          left: '3px',
        }}
      >
        *
      </div>
    </Tooltip>
  );
}

function FormLabel({ name, label, helpText, required }) {
  if (!label) return null;

  return (
    <>
      {helpText && (
        <div className="float-right">
          <Popover trigger={['click']} content={helpText}>
            <HelpIcon />
          </Popover>
        </div>
      )}
      <label htmlFor={name} className="control-label">
        {label}
        {required && <Asterisk />}
      </label>
    </>
  );
}

FormLabel.propTypes = {
  label: PropTypes.node,
  helpText: PropTypes.node,
  required: PropTypes.bool,
};

FormLabel.defaultProps = {
  label: null,
  helpText: null,
  required: false,
};

export default FormLabel;
