import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SpacerEl = styled.div``;

function Spacer({ v, h, unit, style, ...props }) {
  const spacingStyles = {};
  if (!R.isNil(v)) {
    spacingStyles.marginTop = `${v}${unit}`;
  }

  if (!R.isNil(h)) {
    spacingStyles.marginLeft = `${h}${unit}`;
    spacingStyles.display = 'inline-block';
  }

  return <SpacerEl {...props} style={{ ...style, ...spacingStyles }} />;
}

Spacer.propTypes = {
  /** Vertical spacing */
  v: PropTypes.number,
  /** Horizontal spacing */
  h: PropTypes.number,
  unit: PropTypes.oneOf(['px', 'em' /* ...add more if needed */]),
  style: PropTypes.object,
};

Spacer.defaultProps = {
  v: null,
  h: null,
  unit: 'px',
  style: {},
};

export default Spacer;
