import React from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';

import { useAuth } from 'hooks/auth';
import { useI18n } from 'hooks/i18n';
import { homeRoute } from 'routes';
import { DELETE_AUTHORITY, NEW_DELETE_AUTHORITY } from 'apollo/operations/auth';

function LogoutButton() {
  const { t } = useI18n();
  const client = useApolloClient();
  const history = useHistory();
  const { setLocalAuthority } = useAuth();
  
  const {loading, data} = useQuery(NEW_DELETE_AUTHORITY, {
    variables: { redirectTo: '/' },
  });

  

  function auth0Logout(auth0LogoutUrl) {
    window.location.replace(auth0LogoutUrl)

  }

  async function handleLogout(event) {
    event.preventDefault();
    
    const confirmText = t('logoutConfirm');
    if (!window.confirm(confirmText)) return;


    try {
      // Clear the local user stored in memory
      setLocalAuthority(null);
      // Reset any loaded data in apollo
      client.resetStore();

      auth0Logout(data.logoutUrl.value)
    } catch (err) {
      console.error('Error logging out', err);
    }
  }

  return (
    <a
      className="dropdown-item"
      href="#log-out"
      onClick={handleLogout}
      data-testid="logoutButton"
      disabled={loading}
    >
      {t('navbar.logOutLink')}
    </a>
  );
}

export default LogoutButton;
