import React from 'react';
import PropTypes from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';
import { Modal } from 'react-bootstrap';

import { useAuth } from 'hooks/auth';
import { useModalState } from 'hooks/modal';
import { useMutation } from '@apollo/react-hooks';
import { DELETE_AUTHORITY } from 'apollo/operations/auth';
import { APP_ENV } from 'config/constants';
import Translate from './common/Translate';

function IdleTimerWrapper() {
  const { localAuthority, setLocalAuthority } = useAuth();
  const [deleteAuthority] = useMutation(DELETE_AUTHORITY, {
    variables: {
      deviceType: 'web',
    },
  });

  const timeout = localAuthority?.tenant?.provisioningIdleLogoutSeconds;

  async function handleLogout() {
    console.log(`Inactive for more than ${timeout} seconds, logging out.`);

    if (APP_ENV !== 'production') {
      console.log('In development mode -- not logging out due to inactivity.');
      // return;
    }

    try {
      await deleteAuthority();
      setLocalAuthority(null);
    } catch (err) {
      console.log('Error logging out', err);
    }
  }

  // console.log(localAuthority, timeout);
  if (!localAuthority || !timeout) return null;
  // if (timeout < 60) {
  //   console.log('Idle timeout is less than 60 seconds -- disabled');
  //   return null;
  // }

  return <IdleTimer timeout={timeout} onLogout={handleLogout} />;
}

function IdleTimer({ timeout, onLogout }) {
  const { show, showModal, hideModal } = useModalState();

  function handleIdleModal() {
    console.log('You are now idle.');
    showModal();
  }

  function handleActiveModal() {
    console.log('You are now active.');
    hideModal();
  }

  let modalTimeout;
  if (timeout > 10 && timeout < 60) {
    modalTimeout = timeout - 10;
  } else if (timeout > 60 && timeout < 300) {
    modalTimeout = timeout - 30;
  } else if (timeout >= 300) {
    modalTimeout = timeout - 60;
  } else {
    // Timeout is too short, don't show modal at all
    modalTimeout = timeout + 60;
  }

  console.log(
    `Registering idle timer: modal at ${modalTimeout} seconds, logout at ${timeout} seconds.`,
  );

  // eslint-disable-next-line no-unused-vars
  const modalTimer = useIdleTimer({
    timeout: 1000 * modalTimeout,
    onIdle: handleIdleModal,
    onActive: handleActiveModal,
  });
  // eslint-disable-next-line no-unused-vars
  const logoutTimer = useIdleTimer({
    timeout: 1000 * timeout,
    onIdle: onLogout,
    onActive: () => {},
  });

  return (
    <Modal show={show} onHide={hideModal}>
      <Modal.Body>
        <Translate
          text="logoutFromIdleWarning"
          variables={{ time: timeout - modalTimeout }}
        />
      </Modal.Body>
    </Modal>
  );
}

IdleTimer.propTypes = {
  /** Duration in seconds before logging out */
  timeout: PropTypes.number.isRequired,
};

export default IdleTimerWrapper;
