import React, { useState } from 'react';
import { OverlayTrigger, Tooltip, Popover as BSPopover } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';

function renderTooltip({ children, ...props }) {
  return <Tooltip {...props}>{children}</Tooltip>;
}

function renderPopover(id, title, content) {
  return (
    <BSPopover id={id}>
      {title && <BSPopover.Title as="h3">{title}</BSPopover.Title>}
      <BSPopover.Content>{content}</BSPopover.Content>
    </BSPopover>
  );
}

function Popover({ trigger, placement, tooltip, title, content, children }) {
  const [overlayId] = useState(v4());

  const tooltipProps = props => ({
    ...props,
    id: overlayId,
    children: content,
  });

  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      delay={{ show: 250, hide: 400 }}
      overlay={
        tooltip
          ? props => renderTooltip(tooltipProps(props))
          : renderPopover(overlayId, title, content)
      }
    >
      {children}
    </OverlayTrigger>
  );
}

Popover.propTypes = {
  trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.array]), // click, hover, focus
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  tooltip: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

Popover.defaultProps = {
  trigger: ['hover', 'focus'],
  placement: 'top',
  tooltip: false,
  title: null,
};

export default Popover;
