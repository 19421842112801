import React from 'react';
import PropTypes from 'prop-types';

import Translate from 'components/common/Translate';

function NotFound({ message }) {
  return (
    <>
      <h1>
        <Translate text="notFoundHeading" />
      </h1>
      <p>{message || <Translate text="notFoundMessage" />}</p>
    </>
  );
}

NotFound.propTypes = {
  message: PropTypes.node,
};

NotFound.defaultProps = {
  message: null,
};

export default NotFound;
