import React from 'react';
import * as R from 'ramda';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';

import { deviceType } from 'types';
import { useI18n } from 'hooks/i18n';
import { NEW_UPDATE_DEVICE, NEW_RESTART_APP_ON_DEVICE } from 'apollo/operations/device';
import { setValidationErrors } from 'utils/forms';
import FormikField from 'components/common/FormikField';
import Translate from 'components/common/Translate';
import TenantSelectField from 'components/modules/tenant/TenantSelectField';
import PartnerSelectField from 'components/modules/partner/PartnerSelectField';
import SubTenantSelectField from 'components/modules/subTenant/SubTenantSelectField';
import LocaleSelect from 'components/modules/locale/LocaleSelect';
import { useAuth } from 'hooks/auth';
import ErrorBox from 'components/common/ErrorBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function UpdateDevice({ device, refetchQueries }) {
  const { t } = useI18n();
  const { hasAnyRole, localAuthority } = useAuth();

  const [updateDevice, { loading, error }] = useMutation(NEW_UPDATE_DEVICE);
  const [restartApp, { loadingRestart }] = useMutation(NEW_RESTART_APP_ON_DEVICE);

  async function handleSubmit(values, formikBag) {
    try {
      await updateDevice({
        variables: {
          id: device.id,
          device: {
            partnerId: values.partnerId || null,
            tenantId: values.tenantId || null,
            subTenantId: values.subTenantId || null,
            locale: values.locale,
            // TODO: add optional patientId
          },
        },
      });
      toast.success(t('device.updateSuccessToast'));
    } catch (err) {
      console.log('error updating user:', err);
      setValidationErrors(err, formikBag);
      toast.error(t('device.updateErrorToast'));
    }
  }

  async function restartAppButton() {
    try {
      await restartApp({variables: {id: device.id}})

      toast.success(t('device.restartAppPushSuccessful'));
    } catch (err) {
      console.log('error pushing app restart:', err);
      toast.error(t('device.restartAppPushError'));
    }
  }

  const isAssigned = !R.isNil(device.patient);
  const isAdmin = hasAnyRole(['ROLE_ADMIN']);
  const isPartnerAdmin = hasAnyRole(['ROLE_PARTNER_ADMIN']);
  const isTenantAdmin = hasAnyRole(['ROLE_TENANT_ADMIN']);
  const isSubTenantAdmin = hasAnyRole(['ROLE_SUB_TENANT_ADMIN']);

  const partnerIds = localAuthority.partners?.map(partner => partner.id);
  const tenantIds = localAuthority.tenants?.map(tenant => tenant.id);
  const canEditPartner = isAdmin;
  const canEditTenant = isAdmin || (isPartnerAdmin && partnerIds.includes(device.partner?.id));
  const canEditSubTenant = isAdmin || ((isPartnerAdmin || isTenantAdmin || isSubTenantAdmin) && tenantIds.includes(device.tenant?.id));

  let defaultDevice = device;
  defaultDevice.tenantId = device.tenant?.id || null;
  defaultDevice.subTenantId = device.subTenant?.id || null;
  defaultDevice.partnerId = device.partner?.id || null;

  return (
    <Formik onSubmit={handleSubmit} initialValues={defaultDevice} enableReinitialize>
      {({ values }) => (
        <Form>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                <Translate text="device.updateHeadingText" />
              </h5>
              
              <div className="text-right">
              <button
                type="button"
                className="btn btn-secondary"
                disabled={!device.googleTokenLastUpdated || loadingRestart}
                onClick={()=>{ restartAppButton() }}
              >
                <Translate text="device.restartApp" />
              </button>
              </div>

              <Field
                name="id"
                label={t('device.properties.deviceId')}
                component={FormikField}
                disabled
              />

            <LocaleSelect
              name="locale"
              label={t('device.properties.locale')}
            />

            {isAssigned || !canEditPartner ? (
                // Fake a form input so the name can be shown
                <div className="form-group">
                  <label className="control-label">{t('partner.label')}</label>
                  <input
                    type="text"
                    value={device.partner?.name}
                    className="form-control"
                    disabled
                  />
                </div>
              ) : (
                <PartnerSelectField name="partnerId" label={t('partner.label')} />
              )}

              {isAssigned || !canEditTenant ? (
                // Fake a form input so the name can be shown
                <div className="form-group">
                  <label className="control-label">{t('tenant.label')}</label>
                  <input
                    type="text"
                    value={device.tenant?.name}
                    className="form-control"
                    disabled
                  />
                </div>
              ) : (
                <TenantSelectField name="tenantId" label={t('tenant.label')} partnerId={values.partnerId}/>
              )}

              {isAssigned || !canEditSubTenant ? (
                <div className="form-group">
                  <label className="control-label">
                    {t('subTenant.label')}
                  </label>
                  <input
                    type="text"
                    value={device.subTenant?.name}
                    className="form-control"
                    disabled
                  />
                </div>
              ) : (
                <SubTenantSelectField
                  name="subTenantId"
                  label={t('subTenant.label')}
                  tenantId={values.tenantId || !canEditSubTenant}
                />
              )}

              {!device.googleTokenLastUpdated && (
                <div className="alert alert-warning">
                  <FontAwesomeIcon icon="exclamation-circle" />{' '}
                  <Translate text="device.noTokenWarning" />
                </div>
              )}

            {isAssigned && (
                <div className="alert alert-warning">
                  <FontAwesomeIcon icon="exclamation-circle" />{' '}
                  <Translate text="device.assignedWarning" />
                </div>
              )}

              <ErrorBox graphQLError={error} />
            </div>

            <div className="card-footer text-right">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                <Translate text="device.updateButtonText" />
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

UpdateDevice.propTypes = {
  device: deviceType.isRequired,
};

export default UpdateDevice;
