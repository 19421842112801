import { yup } from 'utils/validation';

export const defaultTenant = () => ({
  name: '',
});

export const validationSchema = labels =>
  yup.object({
    name: yup
      .string()
      .label(labels.name)
      .required(),
  });
