import React from 'react';
import * as R from 'ramda';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CONFIRM_TENANT_USER_EMAIL } from 'apollo/operations/user';
import { homeRoute, loginRoute } from 'routes';
import { yup } from 'utils/validation';
import FormikField from 'components/common/FormikField';
import ErrorBox from 'components/common/ErrorBox';
import { setValidationErrors } from 'utils/forms';
import Translate from 'components/common/Translate';

function ConfirmTenantUserEmail({ token }) {
  const history = useHistory();
  const [confirmPasswordReset, { error, loading }] = useMutation(
    CONFIRM_TENANT_USER_EMAIL,
  );
  async function handleSubmit(values, actions) {
    const { password } = values;

    try {
      await confirmPasswordReset({
        variables: { token, password },
      });

      toast.success(
        'Your account has been successfully set up and is ready for use.',
      );
      history.replace(loginRoute());
    } catch (err) {
      setValidationErrors(err, actions);
      console.log('Error confirming new password', err);
    }
  }

  // If error is set, render a message instead of the form
  if (error) {
    const isInvalidTokenError = R.pipe(
      R.path(['graphQLErrors', 0, 'message']),
      R.equals('token_not_found'),
    )(error);

    if (isInvalidTokenError) {
      return (
        <div className="text-center">
          <p>
            <Translate text="user.invalidTokenError" />
          </p>
          <Link to={homeRoute()}>
            <Translate text="route.home" />
          </Link>
        </div>
      );
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ password: '', passwordConfirm: '' }}
      validationSchema={yup.object({
        password: yup
          .string()
          .required()
          .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
          .matches(/[a-z]/, 'Must contain at least one lowercase letter')
          .matches(/[0-9]/, 'Must contain at least one number')
          .min(8),
        passwordConfirm: yup
          .string()
          .required()
          .test(
            'matches-password',
            'Must be the same as new password',
            function(value) {
              return value === this.parent.password;
            },
          ),
      })}
    >
      <Form>
        <div className="row justify-content-center">
          <div className="col-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  <Translate text="confirmPasswordReset.instructionsTitle" />
                </h5>
                <p>
                  <Translate text="confirmPasswordReset.instructionsText" />
                </p>
                <Field
                  name="password"
                  label="Password"
                  component={FormikField}
                  type="password"
                />
                <Field
                  name="passwordConfirm"
                  label="Confirm password"
                  component={FormikField}
                  type="password"
                />
              </div>

              <ErrorBox graphQLError={error} />

              <div className="card-footer text-right">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  <Translate text="save" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
}

ConfirmTenantUserEmail.propTypes = {
  token: PropTypes.string.isRequired,
};

export default ConfirmTenantUserEmail;
