import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';

import { userType } from 'types';
import { useI18n } from 'hooks/i18n';
import Tooltip from 'components/common/Tooltip';
import { NEW_DELETE_GROUP, NEW_SOFT_DELETE_GROUP } from 'apollo/operations/group';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function DeleteGroup({ groupId, refetchQueries, disabled, children }) {
  const { t } = useI18n();
  // const [deleteGroup, { loading }] = useMutation(NEW_DELETE_GROUP, {refetchQueries});
  const [deleteGroup, { loading }] = useMutation(NEW_SOFT_DELETE_GROUP, {refetchQueries});

  async function handleClick(event) {
    event.preventDefault();

    const confirmText = t('group.confirmDeleteGroup');

    if (!window.confirm(confirmText)) {
      return;
    }

    try {
      await deleteGroup({ variables: { id: groupId } });
      toast.error(t('group.deleteGroupSuccessToast'));

    } catch (err) {
      toast.error(t('group.deleteGroupErrorToast'));
    }
  }

  return (
    <>
    <button 
      type="button"
      className="btn btn-sm btn-danger float-right ml-1"
      onClick={handleClick}
      disabled={disabled}
    >
      {t('group.deleteLabel')}
    </button>

    </>
  );
}

// DeleteGroup.propTypes = {
//   user: userType.isRequired,
//   disabled: PropTypes.bool,
//   children: PropTypes.node.isRequired,
// };

// DeleteUserAdmin.defaultProps = {
//   disabled: false,
// };

export default DeleteGroup;
