import gql from 'graphql-tag';

import { 
  UserParts, 
  SubTenantParts, 
  TenantParts, 
  NewUserParts, 
  NewTenantParts, 
  NewSubTenantParts, 
  NewPublicUserParts,
  NewPatientContactParts,
  NewPatientParts
} from 'apollo/fragments';

export const USER_LIST = gql`
  query UserList {
    userList {
      ...UserParts
    }
  }
  ${UserParts}
`;

export const CREATE_USER = gql`
  mutation CreateUser(
    $tenantId: String!
    $subTenantId: ID!
    $user: CreateUserInput!
    $verifyPath: String!
  ) {
    createUser(
      tenantId: $tenantId
      subTenantId: $subTenantId
      user: $user
      verifyPath: $verifyPath
    ) {
      ...UserParts
    }
  }
  ${UserParts}
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: Int!
    $user: UpdateUserInput!
    $verifyPath: String!
  ) {
    updateUser(id: $id, user: $user, verifyPath: $verifyPath) {
      ...UserParts
    }
  }

  ${UserParts}
`;

export const ASSIGN_USER_SUBTENANT = gql`
  mutation UserAssignSubTenant(
    $userId: Int!, 
    $subTenantId: ID!
  ) {
    userAssignSubTenant(userId: $userId, subTenantId: $subTenantId) {
      ...UserParts
    }
  }

  ${UserParts}
`;

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($token: String!) {
    verifyEmail(token: $token) {
      ...UserParts
    }
  }
  ${UserParts}
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword(
    $id: Int!
    $password: String!
    $newPassword: String!
  ) {
    updatePassword(id: $id, password: $password, newPassword: $newPassword) {
      success
      message
    }
  }
`;

export const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($email: String!, $confirmPath: String!) {
    requestPasswordReset(email: $email, confirmPath: $confirmPath) {
      success
      message
    }
  }
`;

export const CONFIRM_PASSWORD_RESET = gql`
  mutation ConfirmPasswordReset($token: String!, $newPassword: String!) {
    confirmPasswordReset(token: $token, newPassword: $newPassword) {
      success
      message
    }
  }
`;

export const GET_TENANT_USERS = gql`
  query GetTenantUsers($tenantId: String!) {
    getTenantUsers(tenantId: $tenantId) {
      ...UserParts
      userPatient {
        id
      }
    }
  }
  ${UserParts}
`;

export const CREATE_TENANT_USER = gql`
  mutation CreateTenantUser(
    $tenantId: String!
    $subTenantId: ID!
    $user: CreateTenantUserInput!
    $verifyPath: String!
  ) {
    createTenantUser(
      tenantId: $tenantId
      subTenantId: $subTenantId
      user: $user
      verifyPath: $verifyPath
    ) {
      ...UserParts
    }
  }
  ${UserParts}
`;

export const RESEND_USER_EMAIL = gql`
  mutation ResendUserConfirmationEmail($userId: Int!, $verifyPath: String!) {
    resendUserConfirmationEmail(userId: $userId, verifyPath: $verifyPath) {
      success
      message
    }
  }
`;

export const CONFIRM_TENANT_USER_EMAIL = gql`
  mutation ConfirmTenantUserEmail($token: String!, $password: String!) {
    confirmTenantUserEmail(token: $token, password: $password) {
      ...UserParts
    }
  }
  ${UserParts}
`;

export const SET_USER_ENABLED = gql`
  mutation SetUserEnabled($userId: Int!, $isEnabled: Boolean!) {
    setUserEnabled(userId: $userId, isEnabled: $isEnabled) {
      ...UserParts
    }
  }
  ${UserParts}
`;

export const SET_USER_ADMIN = gql`
  mutation SetUserAdmin($userId: Int!, $setAsAdmin: Boolean!) {
    setUserAdmin(userId: $userId, setAsAdmin: $setAsAdmin) {
      ...UserParts
    }
  }
  ${UserParts}
`;

export const SET_USER_LOCKED = gql`
  mutation SetUserLocked($userId: Int!, $isLocked: Boolean!) {
    setUserLocked(userId: $userId, isLocked: $isLocked) {
      ...UserParts
    }
  }
  ${UserParts}
`;

export const USER_DETAIL_PAGE = gql`
  query UserDetailPage($id: Int!) {
    user(id: $id) {
      ...UserParts
      subTenantAdmin {
        ...SubTenantParts
      }
      tenant {
        ...TenantParts
        subTenants {
          ...SubTenantParts
          admins {
            ...UserParts
          }
        }
      }
    }
  }

  ${UserParts}
  ${TenantParts}
  ${SubTenantParts}
`;

export const ASSIGN_USER_TO_GROUP = gql`
  mutation AssignUserToGroup($userId: Int!, $groupId: ID!) {
    assignUserToGroup(userId: $userId, groupId: $groupId) {
      ...UserParts
    }
  }

  ${UserParts}
`;

export const REMOVE_USER_FROM_GROUP = gql`
  mutation RemoveUserFromGroup($userId: Int!, $groupId: ID!) {
    removeUserFromGroup(userId: $userId, groupId: $groupId) {
      ...UserParts
    }
  }

  ${UserParts}
`;

// export const NEW_GET_SELF = gql`
//   query User {
//     user(self: true) {
//       ...NewUserParts
//       tenantsAdministrated {
//         ...NewTenantParts
//       }
//       subTenantsAdministrated {
//         ...NewSubTenantParts
//       }
//     }
//   }

//   ${NewUserParts}
//   ${NewTenantParts}
//   ${NewSubTenantParts}
// ` 

// missing tenant and subtenant admins
export const NEW_GET_SELF = gql`
  query User {
    user(self: true) {
      ...NewUserParts
    }
  }

  ${NewUserParts}
` 

export const NEW_CREATE_PARTNER_USER = gql`
  mutation PartnerUserCreate(
    $partnerId: UUID4!
    $user: UserInput!
  ) {
    partnerUserCreate(
      partnerId: $partnerId
      user: $user
    ) {
      success
      message
    }
  }
`;

export const NEW_DELETE_PARTNER_USER = gql`
  mutation PartnerUserDelete(
    $partnerId: UUID4!
    $userId: UUID4!
  ) {
    partnerUserDelete(
      partnerId: $partnerId
      userId: $userId
    ) {
      success
      message
    }
  }
`;

export const NEW_USER_DETAIL_PAGE = gql`
  query NewUserDetailPage($id: UUID4!) {
    user(id: $id) {
      ...NewUserParts
      subTenant {
        ...NewSubTenantParts
        admins {
          ...NewPublicUserParts
        }
      }
    }
  }

  ${NewUserParts}
  ${NewSubTenantParts}
  ${NewPublicUserParts}
`;  

export const NEW_CREATE_TENANT_ADMIN = gql`
  mutation TenantUserAdminCreate(
    $tenantId: UUID4!
    $user: UserInput!
  ) {
    tenantUserAdminCreate(
      tenantId: $tenantId
      user: $user
    ) {
      success
      message
    }
  }
`;

export const NEW_DELETE_TENANT_ADMIN = gql`
  mutation TenantUserAdminDelete(
    $tenantId: UUID4!
    $userId: UUID4!
  ) {
    tenantUserAdminDelete(
      tenantId: $tenantId
      userId: $userId
    ) {
      success
      message
    }
  }
`;


export const NEW_SUB_TENANT_USER_CREATE = gql`
  mutation SubTenantUserCreate(
    $subTenantId: UUID4!
    $user: CreateUserInput!
  ) {
    subTenantUserCreate(
      subTenantId: $subTenantId
      user: $user
    ) {
      success
      message
    }
  }
`;

export const NEW_SUB_TENANT_USER_REMOVE = gql`
  mutation SubTenantUserDelete(
    $subTenantId: UUID4!
    $userId: UUID4!
  ) {
    subTenantUserDelete(
      subTenantId: $subTenantId
      userId: $userId
    ) {
      success
      message
    }
  }
`;

export const NEW_SUB_TENANT_USER_ADMIN_CREATE = gql`
  mutation SubTenantUserAdminCreate(
    $subTenantId: UUID4!
    $user: UserInput!
  ) {
    subTenantUserAdminCreate(
      subTenantId: $subTenantId
      user: $user
    ) {
      success
      message
    }
  }
`;


export const NEW_SUB_TENANT_USER_ADMIN_DELETE = gql`
  mutation SubTenantUserAdminDelete(
    $subTenantId: UUID4!
    $userId: UUID4!
  ) {
    subTenantUserAdminDelete(
      subTenantId: $subTenantId
      userId: $userId
    ) {
      success
      message
    }
  }
`;

export const NEW_USER_CONTACTS = gql`
  query UserContacts($id: UUID4) {
    user(id: $id) {
      ...NewUserParts
      directContacts {
        ...NewPatientContactParts
        user {
          ...NewPublicUserParts
        }
        patient {
          ...NewPatientParts
        }
      }
    }
  }

  ${NewUserParts}
  ${NewPatientParts}
  ${NewPublicUserParts}
  ${NewPatientContactParts}
`;

export const NEW_RESEND_USER_EMAIL = gql`
  mutation UserResendInvitation($userId: UUID4!, $context: UserResendContextInvitationEnum!) {
    userResendInvitation(userId: $userId, context: $context) {
      success
      message
    }
  }
`;

export const NEW_UPDATE_USER = gql`
mutation NewUserUpdate($id: UUID4!, $user: UserInput!) {
  userUpdate(id: $id, user: $user) {
    email
    name
    displayName
    defaultLocale
  }
}
`;