import React from 'react';
import { Field } from 'formik';

import { useI18n } from 'hooks/i18n';
import FormikField from 'components/common/FormikField';

function GroupFormFields() {
  const { t } = useI18n();

  return (
    <>
      <Field
        name="group.name"
        label={t('group.properties.name')}
        component={FormikField}
      />

    </>
  );
}


export default GroupFormFields;
