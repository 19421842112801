import React from 'react';
import PropTypes from 'prop-types';

import Translate from 'components/common/Translate';

function Unauthorized({ message }) {
  return (
    <>
      <h1>
        <Translate text="unauthorized" />
      </h1>
      <p>{message || <Translate text="unauthorizedMessage" />}</p>
    </>
  );
}

Unauthorized.propTypes = {
  message: PropTypes.node,
};

Unauthorized.defaultProps = {
  message: null,
};

export default Unauthorized;
