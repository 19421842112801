import gql from 'graphql-tag';

import {
  DeviceParts,
  TenantParts,
  PatientParts,
  SubTenantParts,
  NewDeviceParts,
  NewTenantParts,
  NewPatientParts,
  NewSubTenantParts,
  NewPartnerParts
} from 'apollo/fragments';

export const GET_DEVICES = gql`
  query GetDevices {
    getDevices {
      ...DeviceParts
      tenant {
        ...TenantParts
      }
      patient {
        ...PatientParts
      }
    }
  }

  ${DeviceParts}
  ${TenantParts}
  ${PatientParts}
`;

export const GET_UNPROVISIONED_DEVICES = gql`
  query GetUnprovisionedDevices {
    getUnprovisionedDevices {
      ...DeviceParts
    }
  }

  ${DeviceParts}
`;

export const GET_TENANT_DEVICES = gql`
  query GetTenantDevices($tenantId: String!, $subTenantId: ID!) {
    getTenantDevices(tenantId: $tenantId, subTenantId: $subTenantId) {
      ...DeviceParts
      patient {
        ...PatientParts
      }
    }
  }

  ${DeviceParts}
  ${PatientParts}
`;

export const CREATE_DEVICE = gql`
  mutation CreateDevice {
    createDevice {
      ...DeviceParts
    }
  }

  ${DeviceParts}
`;

export const GET_DEVICE = gql`
  query GetDevice($id: String!) {
    getDevice(id: $id) {
      ...DeviceParts
    }
  }

  ${DeviceParts}
`;

export const EDIT_DEVICE_PAGE = gql`
  query GetDevice($id: String!) {
    getDevice(id: $id) {
      ...DeviceParts
      tenant {
        ...TenantParts
      }
      subTenant {
        ...SubTenantParts
      }
      patient {
        ...PatientParts
      }
    }
  }

  ${DeviceParts}
  ${PatientParts}
  ${TenantParts}
  ${SubTenantParts}
`;

export const UPDATE_DEVICE = gql`
  mutation UpdateDevice($id: String!, $device: UpdateDeviceInput!) {
    updateDevice(id: $id, device: $device) {
      ...DeviceParts
    }
  }

  ${DeviceParts}
`;

export const RESTART_APP_ON_DEVICE = gql`
  mutation RestartAppOnDevice($deviceId: String!, $deviceKey: String!) {
    restartAppOnDevice(deviceId: $deviceId, deviceKey: $deviceKey) {
      success
    }
  }
`;

export const NEW_CREATE_DEVICE = gql`
  mutation DeviceCreate($device: DeviceInput!) {
    deviceCreate(device: $device) {
      ...NewDeviceParts
    }
  }

  ${NewDeviceParts}
`;

export const NEW_DEVICE_LIST = gql`
  query DeviceList {
    deviceList {
      ...NewDeviceParts
      tenant {
        ...NewTenantParts
      }
      subTenant {
        ...NewSubTenantParts
      }
      patient {
        ...NewPatientParts
      }
    }
  }

  ${NewDeviceParts}
  ${NewTenantParts}
  ${NewSubTenantParts}
  ${NewPatientParts}
`;

export const NEW_GET_DEVICE = gql`
  query Device($id: UUID4!) {
    device(id: $id) {
      ...NewDeviceParts
      tenant {
        ...NewTenantParts
      }
      subTenant {
        ...NewSubTenantParts
      }
      partner {
        ...NewPartnerParts
      }
      patient {
        ...NewPatientParts
      }
    }
  }

  ${NewDeviceParts}
  ${NewTenantParts}
  ${NewSubTenantParts}
  ${NewPartnerParts}
  ${NewPatientParts}
`;

export const NEW_UPDATE_DEVICE = gql`
  mutation DeviceUpdate($id: UUID4!, $device: DeviceInput!) {
    deviceUpdate(id: $id, device: $device) {
      ...NewDeviceParts
    }
  }

  ${NewDeviceParts}
`;

export const NEW_UPDATE_DEVICE_KIOSK = gql`
  mutation DeviceSetKioskStatus($id: UUID4!, $state: DeviceKioskStateEnum!) {
    deviceSetKioskStatus(id: $id, state: $state) {
      ...NewDeviceParts
    }
  }

  ${NewDeviceParts}
`;

export const NEW_GET_TENANT_DEVICES = gql`
  query NewGetTenantDevices($id: UUID4!) {
    tenant(id: $id) {
      ...NewTenantParts
      devices {
        ...NewDeviceParts
        subTenant {
          ...NewSubTenantParts
        }
        patient {
          ...NewPatientParts
        }
      }
    }
  }

  ${NewTenantParts}
  ${NewDeviceParts}
  ${NewSubTenantParts}
  ${NewPatientParts}
`;

export const NEW_GET_SUBTENANT_DEVICES = gql`
  query NewGetSubTenantDevices($subTenantId: UUID4!) {
    deviceList(subTenantId: $subTenantId) {
      ...NewDeviceParts
      patient {
        ...NewPatientParts
      }
    }
  }

  ${NewDeviceParts}
  ${NewPatientParts}
`;

export const NEW_RESTART_APP_ON_DEVICE = gql`
  mutation DeviceSoftRestart($id: UUID4!) {
    deviceSoftRestart(id: $id) {
      success
    }
  }
`;