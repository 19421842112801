import gql from 'graphql-tag';
import { PatientSubTenantLinkParts } from 'apollo/fragments';

export const CREATE_PATIENT_SUB_TENANT_LINK = gql`
  mutation CreatePatientSubTenantLink(
    $patientId: String!
    $subTenantId: String!
  ) {
    createPatientSubTenantLink(
      patientId: $patientId
      subTenantId: $subTenantId
    ) {
      ...PatientSubTenantLinkParts
    }
  }

  ${PatientSubTenantLinkParts}
`;

export const DELETE_PATIENT_SUB_TENANT_LINK = gql`
  mutation DeletePatientSubTenantLink(
    $patientId: String!
    $subTenantId: String!
  ) {
    deletePatientSubTenantLink(
      patientId: $patientId
      subTenantId: $subTenantId
    ) {
      success
      message
    }
  }
`;
