import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';

import { useI18n } from 'hooks/i18n';
import StandardLayout from 'components/layouts/StandardLayout';
import PartnerList from 'components/modules/partner/PartnerList';
import { partnerCreateRoute } from 'routes';
import Translate from 'components/common/Translate';

function PartnerListPage() {
  const match = useRouteMatch();
  const { t } = useI18n();

  return (
    <StandardLayout breadcrumb={[[match.path, t('route.partnerList')]]}>
      <div className="float-right">
        <Link to={partnerCreateRoute()} className="btn btn-primary">
          <Translate text="route.createPartner" />
        </Link>
      </div>

      <h1>
        <Translate text="route.partnerList" />
      </h1>

      <div className="clearfix" />
      <div className="mt-2" />

      <PartnerList />
    </StandardLayout>
  );
}

export default PartnerListPage;
