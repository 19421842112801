import React from 'react';
import * as R from 'ramda';
import { Switch, useRouteMatch, Redirect } from 'react-router-dom';

import * as routes from 'routes';
import StandardLayout from 'components/layouts/StandardLayout';
// import TenantNextOfKinList from 'components/modules/user/TenantNextOfKinList';
import SecuredRoute from 'components/common/SecuredRoute';
import NavLink from 'components/common/NavLink';
import Translate from 'components/common/Translate';
import TenantSetupTab from './TenantSetupTab';
import TenantPatientsTab from './TenantPatientsTab';
import TenantUserListTab from './TenantUsersTab';
import TenantDevicesTab from './TenantDevicesTab';
import RoleSecured from 'components/common/RoleSecured';

function TenantPage() {
  const match = useRouteMatch();
  const tenantId = R.path(['params', 'tenantId'], match);

  return (
    <StandardLayout breadcrumb={[[match.path, 'Tenant']]}>
      <ul className="nav nav-tabs">
        <RoleSecured roles={['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN', 'ROLE_TENANT_ADMIN']}>
          <li className="nav-item">
            <NavLink
              to={routes.tenantSetupRoute(tenantId)}
              className="nav-link"
            >
              <Translate text="tenantNavTabs.setupLink" />
            </NavLink>
          </li>
        </RoleSecured>

        <li className="nav-item">
          <NavLink
            to={routes.tenantPatientsRoute(tenantId)}
            className="nav-link"
          >
            <Translate text="tenantNavTabs.patientsLink" />
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to={routes.tenantUsersRoute(tenantId)} className="nav-link">
            <Translate text="tenantNavTabs.usersLink" />
          </NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink
            to={routes.tenantNextOfKinRoute(tenantId)}
            className="nav-link"
          >
            <Translate text="tenantNavTabs.nextOfKinLink" />
          </NavLink>
        </li> */}
        <li className="nav-item">
          <NavLink
            to={routes.tenantDevicesRoute(tenantId)}
            className="nav-link"
          >
            <Translate text="tenantNavTabs.devicesLink" />
          </NavLink>
        </li>
      </ul>

      <Switch>
        <Redirect from={match.path} exact to={`${match.path}/patient`} />
        <SecuredRoute
          path={`${match.path}/setup`}
          exact
          component={TenantSetupTab}
          roles={['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN', 'ROLE_TENANT_ADMIN']}
        />
        <SecuredRoute
          path={`${match.path}/patient`}
          exact
          component={TenantPatientsTab}
          roles={['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN', 'ROLE_TENANT_ADMIN', 'ROLE_SUB_TENANT_ADMIN']}
        />
        <SecuredRoute
          path={`${match.path}/users`}
          exact
          component={TenantUserListTab}
          roles={['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN', 'ROLE_TENANT_ADMIN', 'ROLE_SUB_TENANT_ADMIN']}
        />
        {/* <SecuredRoute
          path={`${match.path}/next-of-kin`}
          exact
          component={TenantNextOfKinList}
          roles={['ROLE_ADMIN', 'ROLE_TENANT_ADMIN', 'ROLE_SUB_TENANT_ADMIN']}
        /> */}
        <SecuredRoute
          path={`${match.path}/devices`}
          exact
          component={TenantDevicesTab}
          roles={['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN', 'ROLE_TENANT_ADMIN', 'ROLE_SUB_TENANT_ADMIN']}
        />
      </Switch>
    </StandardLayout>
  );
}

export default TenantPage;
