import gql from 'graphql-tag';

import {
  PatientParts,
  NewPatientParts,
  DeviceParts,
  NewDeviceParts,
  PatientContactParts,
  PatientSubTenantLinkParts,
  SubTenantParts,
  NewPatientContactParts,
  NewPublicUserParts,
  NewSubTenantParts,
  NewUserParts,
  NewPatientCallParts,
  NewTenantParts,
  NewGroupParts
} from 'apollo/fragments';

export const PATIENT_DETAIL_PAGE = gql`
  query PatientDetailPage($patientId: UUID4!) {
    patient(patientId: $patientId) {
      ...PatientParts
      subTenant {
        ...SubTenantParts
      }
      device {
        ...DeviceParts
      }
      contacts {
        ...PatientContactParts
      }
      subTenantLinks {
        ...PatientSubTenantLinkParts
        subTenant {
          ...SubTenantParts
        }
      }
    }
  }

  ${PatientParts}
  ${PatientContactParts}
  ${DeviceParts}
  ${PatientSubTenantLinkParts}
  ${SubTenantParts}
`;

export const GET_TENANT_PATIENTS = gql`
  query GetTenantPatients($tenantId: String!, $withDeleted: Boolean) {
    getTenantPatients(tenantId: $tenantId, withDeleted: $withDeleted) {
      ...PatientParts
      device {
        ...DeviceParts
      }
    }
  }

  ${PatientParts}
  ${DeviceParts}
`;

export const DELETE_PATIENT = gql`
  mutation DeletePatient($id: String!) {
    deletePatient(id: $id) {
      success
      message
    }
  }
`;

export const CREATE_PATIENT = gql`
  mutation CreatePatient(
    $tenantId: String!
    $subTenantId: ID!
    $groupId: ID!
    $patient: CreatePatientInput!
  ) {
    createPatient(
      tenantId: $tenantId
      subTenantId: $subTenantId
      groupId: $groupId
      patient: $patient
    ) {
      ...PatientParts
      device {
        ...DeviceParts
      }
    }
  }

  ${PatientParts}
  ${DeviceParts}
`;

export const UPDATE_PATIENT = gql`
  mutation UpdatePatient($id: String!, $patient: UpdatePatientInput!) {
    updatePatient(id: $id, patient: $patient) {
      ...PatientParts
      device {
        ...DeviceParts
      }
    }
  }

  ${PatientParts}
  ${DeviceParts}
`;

export const GET_PATIENT_POSSIBLE_CONTACTS = gql`
  query GetPatientPossibleContacts($patientId: String!) {
    getPatientPossibleContacts(patientId: $patientId) {
      ...PatientContactParts
    }
  }

  ${PatientContactParts}
`;


export const NEW_CREATE_PATIENT = gql`
  mutation PatientCreate(
    $groupId: UUID4!
    $patient: PatientInput!
  ) {
    patientCreate(
      groupId: $groupId
      patient: $patient
    ) {
      ...NewPatientParts
    }
  }

  ${NewPatientParts}
`;

// export const NEW_PATIENT_DETAIL_PAGE = gql`
//   query PatientDetailPage($id: UUID4!) {
//     patient(id: $id) {
//       ...NewPatientParts
//       device {
//         ...NewDeviceParts
//       }
//     }
//   }

//   ${NewPatientParts}
//   ${NewDeviceParts}
// `;

// export const NEW_PATIENT_DETAIL_PAGE = gql`
//   query PatientDetailPage($id: UUID4!) {
//     patient(id: $id) {
//       ...NewPatientParts
//       device {
//         ...NewDeviceParts
//       }
//       calls {
//         ...NewPatientCallParts
//       }
//       contactSubTenants {
//         ...NewSubTenantParts
//         users {
//           ...NewUserParts
//         }
//       }
//       contacts {
//         ...NewPatientContactParts
//         user {
//           ...NewPublicUserParts
//         }
//       }
//     }
//   }

//   ${NewPatientParts}
//   ${NewDeviceParts}
//   ${NewPatientCallParts}
//   ${NewSubTenantParts}
//   ${NewUserParts}
//   ${NewPatientContactParts}
//   ${NewPublicUserParts}
// `;

export const  NEW_PATIENT_DETAIL_PAGE = gql`
  query PatientDetailPage($id: UUID4!) {
    patient(id: $id) {
      ...NewPatientParts
      device {
        ...NewDeviceParts
      }
      contactSubTenants{
        ...NewSubTenantParts
      }
      group {
        ...NewGroupParts
        subTenant {
          ...NewSubTenantParts
        }
      }
      contacts {
        ...NewPatientContactParts
        user {
          ...NewPublicUserParts
        }
      }
    }
  }

  ${NewPatientParts}
  ${NewDeviceParts}
  ${NewPatientContactParts}
  ${NewPublicUserParts}
  ${NewSubTenantParts}
  ${NewGroupParts}
`;

export const NEW_PATIENT_CALL_HISTORY = gql`
  query PatientCallHistory($id: UUID4!) {
    patient(id: $id) {
      ...NewPatientParts
      calls {
        ...NewPatientCallParts
        user {
          ...NewPublicUserParts
        }
      }
    }
  }

  ${NewPatientParts}
  ${NewPatientCallParts}
  ${NewPublicUserParts}
`;

export const NEW_SOFT_DELETE_PATIENT = gql`
  mutation PatientSoftDelete($id: UUID4!) {
    patientSoftDelete(id: $id) {
      id
    }
  }
`;

export const NEW_UPDATE_PATIENT = gql`
  mutation NewPatientUpdate($id: UUID4!, $patient: PatientInput!) {
    patientUpdate(id: $id, patient: $patient) {
      ...NewPatientParts
      device {
        ...NewDeviceParts
      }
    }
  }

  ${NewPatientParts}
  ${NewDeviceParts}
`;

