import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import StandardLayout from 'components/layouts/StandardLayout';
import AdminDeviceList from 'components/modules/device/AdminDeviceList';
import { useI18n } from 'hooks/i18n';

function DeviceListPage() {
  const match = useRouteMatch();
  const { t } = useI18n();

  return (
    <StandardLayout breadcrumb={[[match.path, t('route.deviceList')]]}>
      <AdminDeviceList />
    </StandardLayout>
  );
}

export default DeviceListPage;
