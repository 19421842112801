import React from 'react';
import classnames from 'classnames';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import DateTime from 'react-datetime';

import FormLabel from './FormLabel';

function FormikTextField({
  form,
  field,
  isInvalid,
  label,
  renderInput,
  helpText,
  inputProps,
  mode,
  dateFormat,
  timeFormat,
  required,
  // ...props
}) {
  function handleChange(value) {
    const date = value.format ? value.format('YYYY-MM-DD') : value;
    form.setFieldValue(field.name, date);
  }

  const input = (
    <DateTime
      mode={mode}
      dateFormat={dateFormat}
      timeFormat={timeFormat}
      value={field.value}
      onChange={handleChange}
      inputProps={{ placeholder: 'yyyy-mm-dd', ...inputProps }}
      closeOnSelect
    />
  );

  return (
    <div
      className={classnames('form-group', {
        'has-error': isInvalid,
        'text-danger': isInvalid,
      })}
    >
      <FormLabel
        name={field.name}
        label={label}
        helpText={helpText}
        required={required}
      />
      {renderInput ? renderInput(input) : input}
      <ErrorMessage name={field.name} />
    </div>
  );
}

FormikTextField.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  className: PropTypes.string,
  isInvalid: PropTypes.bool.isRequired,
  label: PropTypes.node,
  renderInput: PropTypes.func,
  helpText: PropTypes.node,
  inputProps: PropTypes.object,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  mode: PropTypes.string,
  required: PropTypes.bool,
};

FormikTextField.defaultProps = {
  className: null,
  label: null,
  renderInput: null,
  helpText: null,
  inputProps: null,
  mode: 'date',
  dateFormat: 'YYYY-MM-DD',
  timeFormat: false, // Hides time by default
  required: false,
};

export default FormikTextField;
