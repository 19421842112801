import React from 'react';

import { tenantCreateRoute } from 'routes';
import StandardLayout from 'components/layouts/StandardLayout';
import CreatePartner from 'components/modules/partner/PartnerCreate';
import { useI18n } from 'hooks/i18n';

function PartnerCreatePage() {
  const { t } = useI18n();

  return (
    <StandardLayout
      breadcrumb={[[tenantCreateRoute(), t('route.createTenant')]]}
    >
      <CreatePartner />
    </StandardLayout>
  );
}

export default PartnerCreatePage;
