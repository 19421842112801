import React from 'react';
import * as R from 'ramda';
import { useQuery } from '@apollo/react-hooks';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { Table as BSTable } from 'react-bootstrap';

import { NEW_GET_PARTNER } from 'apollo/operations/partner';
import PartnerUsers from 'components/modules/partner/PartnerUsers';
import PartnerTenants from 'components/modules/partner/PartnerTenants';
import PartnerDeviceList from 'components/modules/device/PartnerDeviceList';
import CreatePartnerDevice from 'components/modules/device/CreatePartnerDevice';
import PartnerCreateUser from 'components/modules/user/PartnerCreateUser';
import { partnerTenantCreateRoute } from 'routes';

import Translate from 'components/common/Translate';

function PartnerDetail() {
  const match = useRouteMatch();
  const partnerId = match.params.partnerId;
  const history = useHistory();

  const { loading, data } = useQuery(NEW_GET_PARTNER, {
    variables: { id: partnerId }
  });

  const refetchQueries = [
    { query: NEW_GET_PARTNER, variables: { id: partnerId } },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }
  
  const partner = R.prop('partner')(data);
  const partnerUsers = R.pipe(R.path(['users']), R.defaultTo([]))(partner);
  const partnerTenants = R.pipe(R.path(['tenants']), R.defaultTo([]))(partner);
  const partnerDevices = R.pipe(R.path(['devices']), R.defaultTo([]))(partner);


  const routeChange = () =>{ 
    let path = partnerTenantCreateRoute(partner.id) ; 
    history.push(path);
  }

  return (
    <div>
      <div className="card mb-3">
          <div className="card-body">
            <h3 className="card-title"><Translate text="partner.users" /></h3>
          <div className='card-text'>
            <PartnerCreateUser
              partnerId={partnerId}
              refetchQueries={refetchQueries}
            >
              {showModal => (
                <button
                  type="button"
                  className="btn btn-primary float-right"
                  onClick={showModal}
                >
                  <Translate text="user.createUserButtonText" />
                </button>
              )}
            </PartnerCreateUser>
            <PartnerUsers users={partnerUsers} partner={partner} refetchQueries={refetchQueries}/>
          </div>
        </div>
      </div>
      <div className="card mb-3">
          <div className="card-body">
            <h3 className="card-title"><Translate text="partner.tenants" /></h3>
            <button
              type="button"
              className="btn btn-primary float-right"
              onClick={routeChange}
            >
              <Translate text="tenant.createButtonText" />
            </button>
            <PartnerTenants tenants={partnerTenants} partner={partner} refetchQueries={refetchQueries}/>
        </div>
      </div>
      <div className="card mb-3">
          <div className="card-body">
            <h3 className="card-title"><Translate text="partner.devices" /></h3>
            <CreatePartnerDevice partnerId={partnerId} refetchQueries={refetchQueries}/>
            <PartnerDeviceList deviceList={partnerDevices} refetchQueries={refetchQueries}/>
        </div>
      </div>
    </div>

  );
}

export default PartnerDetail;
