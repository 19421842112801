import React from 'react';
import PropTypes from 'prop-types';

import * as types from 'types';
import { useSortFilter } from 'hooks/data';
import SortTrigger from 'components/common/SortTrigger';
import Translate from 'components/common/Translate';
import { Link } from 'react-router-dom';
import { tenantUserRoute } from 'routes';
import DeleteSubTenantAdmin from '../user/DeleteSubTenantAdmin';

function SubTenantAdminList({ tenantId, subTenantId, admins, refetchQueries }) {
  const { items, sortIndicatorProps } = useSortFilter(admins, 'name', 'name');

  return (
    <table className="table table-bordered table-hover mb-0">
      <thead>
        <tr>
          <th>
            <SortTrigger colName="name" sortIndicatorProps={sortIndicatorProps}>
              <Translate text="user.properties.name" />
            </SortTrigger>
          </th>
          <th>
              <Translate text="user.revokeAreaAdminTooltip" />
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map(user => (
          <tr key={user.id}>
            <td>
              <Link to={tenantUserRoute(tenantId, user.id)}>{user.name}</Link>
            </td>
            <td>
              <DeleteSubTenantAdmin user={user} subTenantId={subTenantId} refetchQueries={refetchQueries} >
                ❌
              </DeleteSubTenantAdmin>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

SubTenantAdminList.propTypes = {
  tenantId: types.tenantIdType.isRequired,
  subTenantId: types.subTenantIdType.isRequired,
  admins: PropTypes.arrayOf(types.userType).isRequired,
};

export default SubTenantAdminList;
