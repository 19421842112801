import React from 'react';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import { Redirect, useHistory } from 'react-router-dom';

import { setValidationErrors } from 'utils/forms';
import { UPDATE_TENANT, NEW_UPDATE_TENANT } from 'apollo/operations/tenant';
import { tenantListRoute } from 'routes';
import { useI18n } from 'hooks/i18n';
import { validationSchema as tenantValidationSchema } from 'utils/modules/tenant';
import FormikField from 'components/common/FormikField';
import Translate from 'components/common/Translate';
import ErrorBox from 'components/common/ErrorBox';

function UpdateTenant({tenant}) {
  const { t } = useI18n();
  const history = useHistory();

  const [updateTenant, { loading, data, error }] = useMutation(NEW_UPDATE_TENANT);

  async function handleSubmit(values, formikBag) {
    try {
      console.log('tenant', tenant.id)
      await updateTenant({ variables: { id: tenant.id, tenant: {name: values.name, enforceApplicationSecurity: values.enforceApplicationSecurity, locale: tenant.locale }} });
      toast.success(t('tenant.createSuccessToast'));
      history.goBack()
    } catch (err) {
      console.log('Error creating tenant', err);
      setValidationErrors(err, formikBag);
      toast.error(t('tenant.createErrorToast'));
    }
  }

  const validationSchema = tenantValidationSchema({
    name: t('tenant.properties.name'),
    enforceApplicationSecurity: t('tenant.properties.enforceApplicationSecurity'),
  });

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={tenant}
      validationSchema={validationSchema}
    >
      <Form>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">
              <Translate text="tenant.createHeadingText" />
            </h5>
            <Field
              name="name"
              label={t('tenant.properties.name')}
              component={FormikField}
              required
            />
            <Field
              name="enforceApplicationSecurity"
              label={t('tenant.properties.enforceApplicationSecurity')}
              component={FormikField}
              type="checkbox"
              required
            />
          </div>

          <ErrorBox graphQLError={error} />

          <div className="card-footer text-right">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              <Translate text="tenant.updateButtonText" />
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}

export default UpdateTenant;
