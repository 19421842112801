import React, { useState } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

export const AuthContext = React.createContext();

export function AuthContextProvider({ defaultState, children }) {
  const [localAuthority, setLocalAuthority] = useState(defaultState);
  const isAuthenticated = !R.isNil(localAuthority);

  function hasAnyRole(roles) {
    if (!isAuthenticated) return false;
    
    return R.pipe(
      R.intersection(localAuthority.roles),
      R.complement(R.isEmpty),
    )(roles);
  }

  return (
    <AuthContext.Provider
      value={{
        localAuthority,
        setLocalAuthority,
        isAuthenticated,
        hasAnyRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthContextProvider.propTypes = {
  defaultState: PropTypes.object,
  children: PropTypes.node.isRequired,
};

AuthContextProvider.defaultProps = {
  defaultState: null,
};
