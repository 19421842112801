import React from 'react';
import * as R from 'ramda';
import classnames from 'classnames';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

import FormLabel from './FormLabel';

const InputField = ({ value, ...props }) => (
  <input {...props} value={R.defaultTo('', value)} />
);
const TextareaField = ({ value, ...props }) => (
  <textarea {...props} value={R.defaultTo('', value)} />
);

function FormikTextField({
  type,
  name,
  className,
  isInvalid,
  label,
  renderInput,
  helpText,
  required,
  ...props
}) {
  let FieldComponent = InputField;
  if (type === 'textarea') FieldComponent = TextareaField;

  const input = (
    <FieldComponent
      {...props}
      id={name}
      type={type}
      className={classnames(className, 'form-control')}
    />
  );

  return (
    <div
      className={classnames('form-group', {
        'has-error': isInvalid,
        'text-danger': isInvalid,
      })}
    >
      <FormLabel label={label} helpText={helpText} required={required} />
      {renderInput ? renderInput(input) : input}
      <ErrorMessage name={name} />
    </div>
  );
}

FormikTextField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'text',
    'number',
    'email',
    'password',
    'file',
    'textarea',
  ]),
  className: PropTypes.string,
  isInvalid: PropTypes.bool.isRequired,
  label: PropTypes.node,
  renderInput: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.any,
  ]),
  helpText: PropTypes.node,
  required: PropTypes.bool,
};

FormikTextField.defaultProps = {
  type: 'text',
  className: null,
  label: null,
  renderInput: null,
  helpText: null,
  required: false,
};

export default FormikTextField;
