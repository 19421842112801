import moment from 'moment-timezone';
import PropTypes from 'prop-types';

function DateTime({ date, format, timezone, render }) {
  const formatted = moment(date)
    .tz(timezone)
    .format(format);

  if (render) render(formatted);

  return formatted;
}

DateTime.propTypes = {
  date: PropTypes.string.isRequired,
  format: PropTypes.string,
  timezone: PropTypes.string,
};

DateTime.defaultProps = {
  format: 'D. MMM, YYYY H:mm:ss z',
  timezone: 'utc',
};

export default DateTime;
