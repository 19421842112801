import gql from 'graphql-tag';

import { NewTenantParts, NewPublicUserParts, NewDeviceParts, NewPatientParts, NewSubTenantParts } from 'apollo/fragments';

// also tenant and users
export const NewPartnerParts = gql`
  fragment NewPartnerParts on Partner {
    id
    name
  }
`;


export const NEW_GET_PARTNERS = gql`
  query PartnerList {
    partnerList {
      ...NewPartnerParts
    }
  }

  ${NewPartnerParts}
`;

export const NEW_GET_PARTNER = gql`
  query Partner($id: UUID4!) {
    partner(id: $id) {
      ...NewPartnerParts
      users {
        ...NewPublicUserParts
      }
      tenants {
        ...NewTenantParts
      }
      devices {
        ...NewDeviceParts
        tenant {
          ...NewTenantParts
        }
        subTenant {
          ...NewSubTenantParts
        }
        patient {
          ...NewPatientParts
        }
      }
    }
  }

  ${NewPartnerParts}
  ${NewPublicUserParts}
  ${NewTenantParts}
  ${NewDeviceParts}
  ${NewPatientParts}
  ${NewSubTenantParts}
`;


export const NEW_CREATE_PARTNER = gql`
  mutation PartnerCreate ($partner: PartnerInput!) {
    partnerCreate (partner: $partner) {
      ...NewPartnerParts
    }
  }

  ${NewPartnerParts}
`;