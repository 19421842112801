import React from 'react';
import { Redirect } from 'react-router-dom';
import { tenantListRoute, tenantRoute, profileRoute, partnerRoute } from 'routes';

import { useAuth } from 'hooks/auth';

function HomePage() {
  const { localAuthority, hasAnyRole } = useAuth();

  console.log(localAuthority);

  if (hasAnyRole(['ROLE_ADMIN'])) {
    return <Redirect to={tenantListRoute()} />;
  }

  if (hasAnyRole(['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN'])) {
    if (localAuthority.partners.length == 1) {
      return <Redirect to={partnerRoute(localAuthority.partners[0].id)} />;
    } else {
      return <Redirect to={tenantListRoute()} />;
    }
  }

  if (hasAnyRole(['ROLE_ADMIN', 'ROLE_TENANT_ADMIN'])) {
    if (localAuthority.tenants.length == 1) {
      return <Redirect to={tenantRoute(localAuthority.tenants[0].id)} />;
    } else {
      return <Redirect to={tenantListRoute()} />;
    }
  }

  if (hasAnyRole(['ROLE_ADMIN', 'ROLE_SUB_TENANT_ADMIN'])) {
    if (localAuthority.tenants.length == 1) {
      return <Redirect to={tenantRoute(localAuthority.tenants[0].id)} />;
    } else {
      console.log("more than 1 tenant")
      return <Redirect to={tenantListRoute()} />;
    }
  }

  return <Redirect to={profileRoute(localAuthority.id)} />;
}

export default HomePage;
