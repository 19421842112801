import React from 'react';
import * as R from 'ramda';
import { useQuery } from '@apollo/react-hooks';
import { Table as BSTable } from 'react-bootstrap';
import { useRouteMatch } from 'react-router-dom';

// import { useI18n } from 'hooks/i18n';
import { GET_NEXT_OF_KIN_PATIENTS } from 'apollo/operations/tenant';

function TenantNextOfKinList() {
  const { params } = useRouteMatch();
  const { userId } = params;
  const { loading, data } = useQuery(GET_NEXT_OF_KIN_PATIENTS, {
    variables: { userId: parseInt(userId) },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const tenantPatientList = R.pipe(
    R.pathOr(null, ['getNextOfKinPatients']),
    R.defaultTo([]),
    R.sortBy(R.prop('name')),
    R.groupBy(R.path(['tenant', 'name'])),
  )(data);

  function renderPatients(patients) {
    return patients.map(patient => {
      return (
        <tr key={patient.id}>
          <td>{patient.id}</td>
          <td>{patient.name}</td>
        </tr>
      );
    });
  }

  return (
    <BSTable striped bordered hover>
      <thead>
        <tr>
          <th>id</th>
          <th>name</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(tenantPatientList).map(([tenantName, patients]) => {
          return (
            <React.Fragment key={tenantName}>
              <tr>
                <td colSpan="2" className="text-center">
                  <b>Tenant: {tenantName}</b>
                </td>
              </tr>
              {renderPatients(patients)}
            </React.Fragment>
          );
        })}
      </tbody>
    </BSTable>
  );
}

export default TenantNextOfKinList;
