import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';

import { userType } from 'types';
import { useI18n } from 'hooks/i18n';
import Tooltip from 'components/common/Tooltip';
import { NEW_DELETE_TENANT_ADMIN } from 'apollo/operations/user';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function DeleteUserAdmin({ user, tenantId, refetchQueries, disabled, children }) {
  const { t } = useI18n();
  const [deleteUserAdmin, { loading }] = useMutation(NEW_DELETE_TENANT_ADMIN, {refetchQueries});

  async function handleClick(event) {
    event.preventDefault();

    const userId = user.id;
    const setAsAdmin = !user.isTenantAdmin;

    const confirmText = t('user.confirmRemoveAdmin');

    if (!window.confirm(confirmText)) {
      return;
    }

    try {
      await deleteUserAdmin({ variables: { userId, tenantId } });
    } catch (err) {
      toast.error(t('user.updateTenantUserErrorToast'));
    }
  }

  return (
    <>
      {!disabled ? (
        <Tooltip
          overlay={t(
            `user.toggleAdminDisabledTooltip`,
          )}
        >
          <a href="#delete" onClick={handleClick}>
            {loading ? <FontAwesomeIcon icon="spinner" /> : children}
          </a>
        </Tooltip>
      ) : (
        children
      )}
    </>
  );
}

DeleteUserAdmin.propTypes = {
  user: userType.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

DeleteUserAdmin.defaultProps = {
  disabled: false,
};

export default DeleteUserAdmin;
