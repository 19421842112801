import React from 'react';
import { Redirect } from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import { homeRoute, registerRoute } from 'routes';
import StandardLayout from 'components/layouts/StandardLayout';
import RegistrationForm from 'components/modules/auth/RegistrationForm';

function RegisterPage() {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Redirect to={homeRoute()} />;
  }

  return (
    <StandardLayout breadcrumb={[[registerRoute(), 'Registration']]}>
      <div className="row justify-content-center">
        <div className="col-8">
          <RegistrationForm />
        </div>
      </div>
    </StandardLayout>
  );
}

export default RegisterPage;
