import React from 'react';

import StandardLayout from 'components/layouts/StandardLayout';
import NotFound from 'components/errors/NotFound';

function NotFoundPage() {
  return (
    <StandardLayout>
      <NotFound />
    </StandardLayout>
  );
}

export default NotFoundPage;
